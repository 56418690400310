import IVk from "@/components/iconsVk/IVk";
import IVkLike from "@/components/iconsVk/IVkLike";
import IVkRepost from "@/components/iconsVk/IVkRepost";
import IVkComment from "@/components/iconsVk/IVkComment";

import IcoBig from "@/components/IcoBig";
import IconBack from "@/components/icons/IconBack";
import IconCalendar from "@/components/icons/IconCalendar";
import IconClick from "@/components/icons/IconClick";
import IconClock from "@/components/icons/IconClock";
import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconDown from "@/components/icons/IconDown";
import IconClose from "@/components/icons/IconClose";
import IconClone from "@/components/icons/IconClone";
import IconGood from "@/components/icons/IconGood";
import IconBad from "@/components/icons/IconBad";
import IconLink from "@/components/icons/IconLink";
import IconList from "@/components/icons/IconList";
import IconLoader from "@/components/icons/IconLoader";
import IconRe from "@/components/icons/IconRe";
import IconRocket from "@/components/icons/IconRocket";
import IconTelegram from "@/components/icons/IconTelegram";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";
import IconOk from "@/components/icons/IconOk";
import IconSetting from "@/components/icons/IconSetting";
import IconStop from "@/components/icons/IconStop";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconUp from "@/components/icons/IconUp";
import IconWarning from "@/components/icons/IconWarning";
import IconUser from "@/components/icons/IconUser";

import Menu from "@/components/Menu";
import ThemeMenu from "@/components/ThemeMenu";
import ViewTemplate from "@/components/ViewTemplate";
import AddTemplate from "@/components/AddTemplate";
import EditTemplate from "@/components/EditTemplate";
import DeleteTemplate from "@/components/DeleteTemplate";
import SettingTemplate from "@/components/SettingTemplate";

import AddCampaign from "@/components/AddCampaign";

import DayAd from "@/components/Day";

export default [
    IVk,
    IVkLike,
    IVkRepost,
    IVkComment,
    IcoBig,
    IconBack,
    IconCalendar,
    IconClick,
    IconClock,
    IconGood,
    IconBad,
    IconPlus,
    IconEdit,
    IconDelete,
    IconDown,
    IconClose,
    IconClone,
    IconLink,
    IconList,
    IconLoader,
    IconMore,
    IconTelegram,
    IconHome,
    IconOk,
    IconSetting,
    IconStop,
    IconProtfolio,
    IconRe,
    IconRocket,
    IconEye,
    IconEyeClose,
    IconUp,
    IconWarning,
    IconUser,

    Menu,
    ThemeMenu,
    ViewTemplate,
    AddTemplate,
    EditTemplate,
    DeleteTemplate,
    SettingTemplate,

    AddCampaign,

    DayAd,
];
