import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        clients: [],
        clientsF: [],
        client: {},

        campaigns: [],
        campaignsF: [],
        campaign: {},

        templates: [],
        templatesF: [],
        template: {},

        payments: [],

        queue: null,

        files: [],

        ads: [],
        adsF: [],
        ad: {},

        stat: [],
        statAd: [],
    },

    mutations: {
        setClients(state, clients) {
            state.clients = clients;
            state.clientsF = clients;
        },
        setClientsF(state, clients) {
            state.clientsF = clients;
        },
        setClient(state, client) {
            localStorage.setItem("client", JSON.stringify(client));
            state.client = client;
        },

        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
            state.campaignsF = campaigns;
        },
        setCampaignsF(state, campaigns) {
            state.campaignsF = campaigns;
        },
        setCampaign(state, campaign) {
            localStorage.setItem("campaign", JSON.stringify(campaign));
            state.campaign = campaign;
        },

        setTemplates(state, templates) {
            state.templates = templates;
            state.templatesF = templates;
        },
        setTemplatesF(state, templates) {
            state.templatesF = templates;
        },
        setTemplate(state, template) {
            localStorage.setItem("template", JSON.stringify(template));
            state.template = template;
        },

        setPayments(state, payments) {
            state.payments = payments;
        },

        setQueue(state, queue) {
            state.queue = queue;
        },

        setFiles(state, files) {
            state.files = files;
        },

        setAds(state, ads) {
            state.ads = ads;
            state.adsF = ads;
        },
        setAdsF(state, ads) {
            state.adsF = ads;
        },
        setAd(state, ad) {
            localStorage.setItem("ad", JSON.stringify(ad));
            state.ad = ad;
        },
        setStat(state, stat) {
            state.stat = stat;
        },
        setStatAd(state, statAd) {
            state.statAd = statAd;
        },
    },

    actions: {
        marketStorage(ctx) {
            console.log("marketStorage");
            if (!ctx.state.client.id) {
                let client = JSON.parse(localStorage.getItem("client"));
                if (client && client.id > 0) ctx.commit("setClient", client);
            }
            if (!ctx.state.campaign.id) {
                let campaign = JSON.parse(localStorage.getItem("campaign"));
                if (campaign && campaign.id > 0) ctx.commit("setCampaign", campaign);
            }
            if (!ctx.state.template.id) {
                let template = JSON.parse(localStorage.getItem("template"));
                if (template && template.id > 0) ctx.commit("setTemplate", template);
            }
        },

        // получаем данные по клиентам
        async getClients(ctx) {
            // if (!ctx.state.clients || ctx.state.clients.length < 1) {
            const res = await API.GET("market/client/clients");

            if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            ctx.commit("setClients", res.data.clients);
            ctx.commit("setStat", res.data.stat);
            ctx.commit("setUsers", res.data.users);

            // } else {
            //     console.log("clients уже есть");
            // }
        },

        // Кампании выбранного клиента
        async getCampaigns(ctx) {
            ctx.dispatch("marketStorage");
            if (!ctx.state.client.id) return router.push("/clients");

            const res = await API.GET("market/client/campaigns", { id: ctx.state.client.id });
            // console.log("getCampaigns", res.data);
            if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            ctx.commit("setCampaigns", res.data.campaigns);
            ctx.commit("setStat", res.data.stat);
        },

        // Креативы выбранной кампании
        async getTemplates(ctx) {
            ctx.dispatch("marketStorage");
            if (!ctx.state.campaign.id) return router.push("/client");

            const res = await API.GET("market/template/list", { id: ctx.state.campaign.id });
            // console.log("getTemplates", res.data);
            if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            ctx.commit("setTemplates", res.data.templates);
            ctx.commit("setStat", res.data.stat);
        },

        // Один креатив и объявления в нем
        async getOneTemplate(ctx) {
            ctx.dispatch("marketStorage");
            if (!ctx.state.template.id) return router.push("/campaign");

            ctx.dispatch("request", true);

            const res = await API.GET("market/template/one", { id: ctx.state.template.id });
            // console.log("getOneTemplate", res.data);
            ctx.commit("setTemplate", res.data.template);
            ctx.commit("setQueue", res.data.queue);
            ctx.commit("setStatAd", res.data.statAd);
            ctx.dispatch("request", false);
        },

        // при выборе клиента фильтруем кампании
        pickClient(ctx, client) {
            ctx.commit("setClient", client);
            ctx.commit("setCampaigns", null);
            ctx.commit("setTemplates", null);
            ctx.commit("setStat", null);
        },

        pickCampaign(ctx, campaign) {
            ctx.commit("setCampaign", campaign);
            ctx.commit("setTemplates", null);
            ctx.commit("setStat", null);
        },

        pickTemplate(ctx, template) {
            ctx.commit("setTemplate", template);
        },

        createCampaign(ctx, data) {
            let formData = new FormData();
            formData.append("client_id", ctx.state.client.id);
            formData.append("id", data.id);
            formData.append("name", data.name);

            API.POST("market/template/createcampaign", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setCampaigns", res.data.campaigns);
                let campaigns = res.data.campaigns.filter((item) => parseInt(item.client_id) == parseInt(ctx.state.client.id));
                ctx.commit("setCampaignsF", campaigns);
            });
        },

        // async getBooks(ctx) {
        //     ctx.dispatch("request", true);
        //     await API.GET("market/template/books").then((res) => {
        //         console.log("books", res.data);
        //         ctx.commit("setAds", res.data.books);
        //         ctx.dispatch("request", false);
        //     });
        // },

        // async getActives(ctx) {
        //     ctx.dispatch("request", true);
        //     await API.GET("market/template/actives").then((res) => {
        //         console.log("actives", res.data);
        //         ctx.commit("setAds", res.data.actives);
        //         ctx.dispatch("request", false);
        //     });
        // },

        removeTemplate(ctx) {
            router.push("/campaign");
            ctx.commit("setTemplate", null);
        },

        createTemplate(ctx) {
            let formData = new FormData();
            formData.append("client_id", ctx.state.client.id);
            formData.append("campaign_id", ctx.state.campaign.id);

            API.POST("market/template/create", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.commit("setTemplate", res.data.template);
                router.push("/template");
            });
        },

        cloneTemplate(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/clone", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        archiveTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/archive", formData).then((res) => {
                // console.log("archiveTemplate", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.dispatch("pickCampaign", ctx.state.campaign);
            });
        },

        unarchiveTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/un-archive", formData).then((res) => {
                // console.log("unarchiveTemplate", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.dispatch("pickCampaign", ctx.state.campaign);
            });
        },

        saveTemplate(ctx, data) {
            if (!data.id) return null;

            console.log("saveTemplate", data);

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);
            formData.append("client_id", data.client_id);
            formData.append("campaign_id", data.campaign_id);
            formData.append("type", data.type); // ???
            // formData.append("title", data.title);
            formData.append("text", data.text);
            formData.append("file_ids", data.file_ids);
            formData.append("picture", data.picture);
            formData.append("group_ids", data.group_ids);
            formData.append("counter_type", data.counter_type);
            formData.append("counter_id", data.counter_id);
            formData.append("goal_id", data.goal_id);

            // formData.append("date_from", data.date_from);
            // formData.append("date_to", data.date_to);
            // formData.append("time_from", data.time_from);
            // formData.append("time_to", data.time_to);

            API.POST("market/template/update", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        delTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/del", formData).then((res) => {
                console.log("delTemplate", res.data);

                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // let templates = ctx.state.templates.filter((item) => item.id != id);
                ctx.commit("setTemplates", res.data.templates);
                let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(ctx.state.campaign.id));
                ctx.commit("setTemplatesF", templates);
                ctx.commit("setTemplate", null);
                router.push("/campaign");
            });
        },

        pickAd(ctx, ad) {
            ctx.commit("setAd", ad);
        },

        // создать много и отправить на создание и модерацию в вк
        generateAds(ctx, count) {
            console.log("generateAds");

            if (parseInt(count) < 1) return;

            let formData = new FormData();
            formData.append("template_id", ctx.state.template.id);
            formData.append("count", count);

            API.POST("market/template/generate-ads", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        attachGroups(ctx, group_ids) {
            console.log("attachGroups", group_ids);

            let formData = new FormData();
            formData.append("template_id", ctx.state.template.id);
            formData.append("group_ids", group_ids);

            API.POST("market/template/attach-groups", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        // отправить на создание и модерацию в вк
        createAd(ctx, data) {
            console.log("createAd", data);

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/create-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        publishAd(ctx, data) {
            console.log("publishAd", data);

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/publish-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        publishAll(ctx, data) {
            console.log("publishAll", data);

            let formData = new FormData();
            formData.append("ids", data.ids);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/publish-all", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        delAd(ctx, ad) {
            let formData = new FormData();
            formData.append("id", ad.id);

            API.POST("market/template/del-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
                // ctx.commit(
                //     "setAds",
                //     ctx.state.ads.filter((item) => item.id != ad.id)
                // );
                ctx.commit("setAd", null);
            });
        },

        stopAd(ctx, id) {
            console.log("stopAd", id);

            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/stop-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        // Оплаты
        addPayments(ctx, data) {
            console.log("addPayments", data);

            let formData = new FormData();
            data.forEach(function (item) {
                formData.append(item.email, JSON.stringify(item));
            });

            API.POST("market/payment/create", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setPayments", res.data.payments);
            });
        },

        getFiles(ctx) {
            API.GET("market/file/list").then((res) => {
                if (res.data.files) ctx.commit("setFiles", res.data.files);
            });
        },

        // NEW
        uploadFile(ctx, e) {
            ctx.dispatch("setUploader", true);

            let formData = new FormData();
            for (var i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];

                let ext = "не определилось";
                let parts = file.name.split(".");
                if (parts.length > 1) {
                    ext = parts.pop();
                }
                if (!["jpeg", "jpg", "png", "bmp", "gif", "mp4"].includes(ext)) {
                    ctx.commit("setResult", "Неправильный формат");
                    ctx.state.loadForm = true;
                    return;
                }

                formData.append("file[" + i + "]", file);
            }

            API.POST("market/file/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then((res) => {
                    ctx.commit("setFiles", res.data.files);
                    ctx.dispatch("setUploader", 0);
                })
                .catch((error) => console.log(error));
        },

        attachFile(ctx, file) {
            if (!ctx.state.template.id) return;

            let file_ids = [];
            if (ctx.state.template.file_ids) file_ids = JSON.parse(ctx.state.template.file_ids);

            file_ids.push(file.id);
            ctx.state.template.file_ids = JSON.stringify(file_ids);

            if (!ctx.state.template.files) ctx.state.template.files = [];
            ctx.state.template.files.push(file);

            // let picture = [];
            // if (ctx.state.template.picture) picture = JSON.parse(ctx.state.template.picture);

            // picture.push(file.name);
            // ctx.state.template.picture = JSON.stringify(picture);

            // let attachments = [];
            // if (ctx.state.template.attachment_id) attachments = JSON.parse(ctx.state.template.attachment_id);

            // attachments.push(file.vk);
            // ctx.state.template.attachment_id = JSON.stringify(attachments);

            console.log("t", ctx.state.template);
        },

        deattachFile(ctx, file) {
            if (!ctx.state.template.id || !ctx.state.template.file_ids) return;

            let file_ids = JSON.parse(ctx.state.template.file_ids);

            let filtered = file_ids.filter((item) => item != file.id);
            ctx.state.template.file_ids = JSON.stringify(filtered);

            ctx.state.template.files = ctx.state.template.files.filter((item) => item != file);

            // let attachments = JSON.parse(ctx.state.template.attachment_id);

            // let filteredA = attachments.filter((item) => item != file.vk);
            // ctx.state.template.attachment_id = JSON.stringify(filteredA);

            console.log("t", ctx.state.template);
        },

        // uploadImg(ctx, data) {
        //     console.log("uploadImg", data);

        //     ctx.dispatch("setUploader", 1);

        //     let formData = new FormData();
        //     for (var i = 0; i < data.e.target.files.length; i++) {
        //         let file = data.e.target.files[i];

        //         let ext = "не определилось";
        //         let parts = file.name.split(".");
        //         if (parts.length > 1) {
        //             ext = parts.pop();
        //         }
        //         if (!["jpeg", "jpg", "png", "bmp"].includes(ext)) {
        //             ctx.commit("setResult", "Неправильный формат. Загрузите .jpg .png");
        //             ctx.state.loadForm = true;
        //             return;
        //         }

        //         formData.append("file[" + i + "]", file);
        //     }

        //     formData.append("template_id", ctx.state.template.id);
        //     API.POST("market/template/upload", formData, {
        //         headers: { "Content-Type": "multipart/form-data" },
        //         onUploadProgress: function (progressEvent) {
        //             ctx.state.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //         }.bind(ctx),
        //     })
        //         .then((res) => {
        //             console.log(res.data);
        //             ctx.commit("setTemplates", res.data.templates);
        //             ctx.commit("setTemplate", res.data.template);
        //             ctx.dispatch("setUploader", 0);
        //         })
        //         .catch((error) => console.log(error));
        // },

        // delPicture(ctx, data) {
        //     let formData = new FormData();
        //     formData.append("template_id", data.template_id);
        //     formData.append("picture", data.picture);
        //     API.POST("market/template/del-picture", formData).then((res) => {
        //         if (res.data.alert) ctx.commit("setAlert", res.data.alert);
        //         ctx.commit("setTemplates", res.data.templates);
        //         ctx.commit("setTemplate", res.data.template);
        //     });
        // },
    },

    getters: {
        clients(state) {
            return state.clientsF;
        },
        client(state) {
            return state.client;
        },
        campaigns(state) {
            return state.campaignsF;
        },
        campaign(state) {
            return state.campaign;
        },
        templates(state) {
            return state.templatesF;
        },
        template(state) {
            return state.template;
        },

        payments(state) {
            return state.payments;
        },

        queue(state) {
            return state.queue;
        },

        files(state) {
            return state.files;
        },

        ads(state) {
            return state.adsF;
        },
        ad(state) {
            return state.ad;
        },
        statCamp(state) {
            return state.statCamp;
        },
        statAd(state) {
            return state.statAd;
        },

        stat(state) {
            return state.stat;
        },
    },
};
