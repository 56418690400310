import API from "../../services/api";

export default {
    state: {
        regs: [],

        utms: { source: [], medium: [], campaign: [], content: [], term: [] },
    },

    mutations: {
        setRegs(state, regs) {
            state.regs = regs;
        },

        setUtms(state, utms) {
            state.utms = utms;
        },
    },

    actions: {
        AnalyticsStorage(ctx) {
            console.log("AnalyticsStorage");

            if (!ctx.state.utms) {
                let utms = JSON.parse(localStorage.getItem("utms"));
                if (utms) ctx.commit("setUtms", utms);
            }
        },

        // получаем все данные
        async getAnalytics(ctx) {
            await API.GET("analytics/regs").then((res) => {
                // console.log("regs", res.data.regs);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setRegs", res.data.regs);
            });
        },

        setUtmSource(ctx, utm) {
            const index = ctx.state.utms.source.indexOf(utm);

            if (index === -1) {
                ctx.state.utms.source.push(utm);
            } else {
                ctx.state.utms.source.splice(index, 1);
            }
        },

        setUtmMedium(ctx, utm) {
            const index = ctx.state.utms.medium.indexOf(utm);

            if (index === -1) {
                ctx.state.utms.medium.push(utm);
            } else {
                ctx.state.utms.medium.splice(index, 1);
            }
        },

        setUtmCampaign(ctx, utm) {
            const index = ctx.state.utms.campaign.indexOf(utm);

            if (index === -1) {
                ctx.state.utms.campaign.push(utm);
            } else {
                ctx.state.utms.campaign.splice(index, 1);
            }
        },

        setUtmContent(ctx, utm) {
            const index = ctx.state.utms.content.indexOf(utm);

            if (index === -1) {
                ctx.state.utms.content.push(utm);
            } else {
                ctx.state.utms.content.splice(index, 1);
            }
        },

        setUtmTerm(ctx, utm) {
            const index = ctx.state.utms.term.indexOf(utm);

            if (index === -1) {
                ctx.state.utms.term.push(utm);
            } else {
                ctx.state.utms.term.splice(index, 1);
            }
        },
    },

    getters: {
        regs(state) {
            return state.regs;
        },
        utms(state) {
            return state.utms;
        },
    },
};
