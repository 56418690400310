<template>
    <div class="text-center text-2xl text-slate-500">КАБИНЕТЫ</div>
    <div class="flex justify-end items-center gap-2">
        <div
            @click="popup = 'add'"
            class="flex justify-center items-center gap-2 bg-white text-teal-500 hover:bg-teal-500 hover:text-white h-8 aspect-square rounded cursor-pointer"
        >
            <IconPlus :size="5" />
        </div>
    </div>

    <div class="w-full">
        <div class="w-full px-4" v-if="cabinets">
            <div v-for="item in cabinets" :key="item.id" class="w-full rounded-xl relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 hover:bg-white">
                    <div class="text-slate-500">{{ item.id }}</div>
                    <div class="col-span-7">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>

    <template v-if="popup == 'add'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ДОБАВИТЬ КАБИНЕТ</h3>

            <div class="p-8">
                <p class="text-sm text-center">Проверь, что он есть в аккануте Глеба!</p>

                <div class="grid grid-cols-2 gap-2 my-4 items-center">
                    <p class="text-base mr-4">Id</p>
                    <input type="text" class="input" v-model="cabinet.id" />

                    <p class="text-base mr-4">Название</p>
                    <input type="text" class="input" v-model="cabinet.name" />
                </div>

                <div class="flex items-center gap-2 btn btn-green w-fit py-2 px-3 mx-auto mt-4" @click="createCabinet(cabinet), (popup = null)">
                    <IcoBig name="plus" :size="5" />Создать
                </div>
            </div>
        </div>
    </template>

    <div v-if="popup" @click="popup = null" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AdminCabinets",

    data() {
        return {
            cabinet: {},
            popup: null,
        };
    },

    computed: {
        ...mapGetters(["cabinets"]),
    },

    methods: {
        ...mapActions(["getCabinets", "createCabinet", "delCabinet"]),
    },

    mounted() {
        this.getCabinets();
    },
};
</script>
