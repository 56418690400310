<template>
    <div
        v-if="template && template?.name"
        class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg"
    >
        <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">СОЗДАТЬ КРЕАТИВ</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col items-center mb-4 relative">
                <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Название</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.name" />
            </div>

            <div class="flex flex-col items-center mb-4 relative">
                <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Используй {link}</label>
                <textarea class="w-full h-72 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
            </div>

            <div class="flex flex-col items-center mb-4">
                <!-- <label class="w-full text-sm text-left text-slate-500">Картинки</label> -->

                <div v-if="uploader == 1" class="w-full relative">
                    <img src="https://api.timekraken.ru/images/loader.svg" />
                </div>

                <div v-else class="w-full relative">
                    <input type="file" multiple id="image" class="absolute invisible opacity-0" @change="uploadImg({ e: $event, id: template.id })" />

                    <div class="flex justify-start items-center gap-2">
                        <template v-if="template.picture">
                            <div v-for="pic in JSON.parse(template.picture)" :key="pic" class="relative rounded overflow-hidden bg-slate-100">
                                <img :src="'https://api.timekraken.ru/uploads/templates/' + pic" class="w-40 aspect-square object-cover" />
                                <div
                                    @click="delPicture({ template_id: template.id, picture: pic })"
                                    class="w-full h-full flex items-center justify-center bg-black/10 text-white/80 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                                >
                                    <IconDelete :size="10" />
                                </div>
                            </div>
                        </template>

                        <label
                            for="image"
                            class="w-40 h-40 flex items-center justify-center aspect-square bg-black/10 text-teal-400/80 rounded hover:bg-black/80 cursor-pointer transition duration-300"
                        >
                            <IconPlus :size="10" />
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="create(template)">СОЗДАТЬ</div>
        </div>
    </div>

    <div v-else>нету</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddTemplate",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["createTemplate", "closePopup"]),

        create(temp) {
            this.closePopup();
            this.createTemplate(temp);
        },
    },
};
</script>
