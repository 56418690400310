<template>
    <!-- <div class="flex justify-center items-center gap-8 h-20 bg-white p-4 mb-4 rounded uppercase">
        <template v-for="user in dataTargetolog" :key="user.id">
            <div
                class="flex flex-col items-center cursor-pointer text-xs text-slate-400 shrink-0"
                :class="{ '!text-cyan-500': s?.targetolog?.id == user.id }"
                @click="setTargetolog(user)"
            >
                <img v-if="user.picture" :src="'https://api.timekraken.ru/uploads/users/mini/' + user.picture" alt="" class="rounded-full w-8 h-8" />
                <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                <span>{{ user.name }}</span>
            </div>
        </template>
    </div> -->

    <div class="flex justify-between items-center mb-4 gap-2">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕЙЧАС
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            7 ДНЕЙ
        </div>
        <template v-for="(value, key) in month" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>

        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div class="grid grid-cols-4 gap-4 mt-4">
        <div class="bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title">ЛИДЫ</span>
            <span class="num text-4xl">{{ parseInt(dataStat.regs).toLocaleString() }}</span>
        </div>

        <div class="col-span-2 flex flex-col justify-center items-center bg-white rounded p-4">
            <span class="title">БЮДЖЕТ ОТКРУЧЕН</span>

            <span class="num text-6xl">{{ parseInt(dataStat.spent).toLocaleString() }} <sup>₽</sup></span>

            <div v-if="dataStat?.graf" class="w-full"><LineGraf :graf="dataStat?.graf" /></div>
        </div>

        <div class="flex flex-col justify-center items-center bg-white rounded p-4">
            <span class="title">СТОИМОСТЬ ЛИДА</span>

            <div class="flex justify-center items-center flex-col">
                <span v-if="dataStat.cpa > 0" class="num text-pink-600 text-4xl">{{ dataStat.cpa.toLocaleString() }} <sup>₽</sup></span>
                <span v-else class="num text-pink-600 text-4xl">0 <sup>₽</sup></span>
                <span v-if="dataStat.cpa > 300" class="title text-pink-600 flex items-center gap-1">
                    <IconWarning :size="3" />
                    ВЫШЕ KPI НА {{ dataStat.cpa - 300 }}
                    <sup>₽</sup>
                </span>
                <span v-if="dataStat.cpa <= 300" class="title text-teal-600">KPI ВЫПОЛНЕН</span>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-4 gap-4 mt-4">
        <div class="bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title">КРЕАТИВЫ</span>
            <span v-if="statistics?.templates" class="num text-3xl">{{ dataStat.templates }}</span>
        </div>

        <div class="bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title">РАЗМЕЩЕНИЯ</span>
            <span class="num text-3xl">{{ dataStat.publishes }}</span>
        </div>

        <div class="bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title">КЛИЕНТЫ</span>
            <span class="num text-3xl">{{ parseInt(statistics?.clients?.length) }}</span>
        </div>

        <div class="bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title mb-2">ТАРГЕТОЛОГИ</span>

            <div class="flex justify-between w-full">
                <template v-for="item in dataTargetolog" :key="item">
                    <div class="flex flex-col items-center cursor-pointer text-xs text-slate-400">
                        <img
                            v-if="item.picture"
                            :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                            alt=""
                            class="rounded w-8 h-8"
                        />
                        <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded w-8 h-8" />
                        <span class="text-[7px] mt-1">{{ item.name }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <div class="flex flex-row gap-4 mt-4">
        <div class="w-full bg-white flex justify-center items-center rounded p-4">
            <div class="w-1/5 flex flex-col justify-center items-center">
                <span class="title">ОХВАТ</span>
                <span class="num text-xl">{{ parseInt(dataStat.views).toLocaleString() }}</span>
            </div>
            <div class="w-1/5 flex flex-col justify-center items-center">
                <span class="title">ЛАЙКИ</span>
                <span class="num text-xl">{{ parseInt(dataStat.likes).toLocaleString() }}</span>
            </div>
            <div class="w-1/5 flex flex-col justify-center items-center">
                <span class="title">РЕПОСТЫ</span>
                <span class="num text-xl">{{ parseInt(dataStat.reposts).toLocaleString() }}</span>
            </div>
            <div class="w-1/5 flex flex-col justify-center items-center">
                <span class="title">КОММЕНТЫ</span>
                <span class="num text-xl">{{ parseInt(dataStat.comments).toLocaleString() }}</span>
            </div>
            <div class="w-1/5 flex flex-col justify-center items-center">
                <span class="title">КЛИКИ</span>
                <span class="num text-xl">{{ parseInt(dataStat.clicks).toLocaleString() }}</span>
            </div>
        </div>
    </div>

    <template v-if="profile.role == 'Or'">
        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-3/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 border-b border-slate-200 text-[8px] text-slate-400">
                    <div class="flex items-center gap-2">ТАРГЕТОЛОГ</div>
                    <span class="text-right">БЮДЖЕТ ОТКРУЧЕН</span>
                    <!-- <span class="text-right">ОХВАТ</span> -->
                    <span class="text-right">КЛИКИ</span>
                    <span class="text-right">ЛИДЫ</span>
                    <span class="text-right pr-4">CPM</span>
                    <span class="text-right pr-4">CPC</span>
                    <span class="text-right pr-4">CPA</span>
                </div>

                <template v-for="item in dataTargetolog" :key="item">
                    <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                        <div class="flex items-center gap-2">
                            <img
                                v-if="item.picture"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                                class="w-10 h-10 rounded object-cover"
                            />
                            <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" class="w-10 h-10 rounded object-cover" />
                            <span>{{ item.name }}</span>
                        </div>

                        <div class="text-lg text-right leading-4 pt-2">{{ parseInt(item.spent).toLocaleString() }} <sup>₽</sup></div>

                        <!-- <div class="text-lg text-right leading-4 pt-2">{{ item.views }}</div> -->

                        <div class="text-lg text-right leading-4 pt-2">{{ item.clicks }}</div>

                        <div class="text-lg text-right leading-4 pt-2">{{ item.regs }}</div>

                        <div class="text-lg text-right leading-4 pt-2">{{ item.cpm }} <sup>₽</sup></div>

                        <div class="text-lg text-right leading-4 pt-2">{{ item.cpc }} <sup>₽</sup></div>

                        <div class="text-lg text-right leading-4 pt-2">{{ item.cpa }} <sup>₽</sup></div>
                    </div>
                </template>
            </div>

            <div class="w-1/4 bg-white flex flex-col justify-center items-center rounded p-4">
                <span class="title">ГРАФИК</span>
                <div class="w-full h-full" v-if="pieTargetolog && pieTargetolog.length > 0"><PieChart :pie="pieTargetolog" /></div>
            </div>
        </div>
    </template>

    <!-- <template v-if="statistics?.cost">
        <div class="flex flex-row gap-4">
            <div class="basis-2/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">БЮДЖЕТ ИЗРАСХОДОВАН</span>
                <span class="num text-6xl">{{ parseInt(dataCost.cost).toLocaleString() }} <sup>₽</sup></span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">ЛИДЫ</span>
                <span class="num text-4xl">{{ parseInt(statistics.regs).toLocaleString() }}</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">СТОИМОСТЬ ЛИДА</span>
                <span v-if="statistics.regs > 0" class="num text-4xl">{{ parseInt(statistics.cost / statistics.regs).toLocaleString() }} <sup>₽</sup></span>
                <span class="num text-4xl">-</span>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">КРЕАТИВОВ</span>
                <span class="num text-3xl">{{ parseInt(statistics.templates) }}</span>
            </div>

            <div class="basis-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">РАЗМЕЩЕНИЙ</span>
                <span class="num text-3xl">{{ parseInt(statistics.publishes).toLocaleString() }}</span>
                <span class="text-xs">+5 (10%)</span>
                <span class="text-xs">график</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">АКТИВНЫЕ КЛИЕНТЫ</span>
                <span class="num text-3xl">{{ parseInt(statistics.clients).toLocaleString() }}</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-4">
                <span class="title">АКТИВНЫЕ ТАРГЕТОЛОГИ</span>
                <span class="num text-3xl">{{ parseInt(statistics.targetologs?.count).toLocaleString() }}</span>

                <div class="flex justify-between w-full">
                    <template v-for="item in statistics.targetologs?.items" :key="item">
                        <div class="flex flex-col items-center cursor-pointer text-xs text-slate-400">
                            <img
                                v-if="item.picture"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                                alt=""
                                class="rounded-full w-8 h-8"
                            />
                            <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="w-full bg-white flex justify-center items-center rounded p-8">
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">ОХВАТ</span>
                    <span class="num text-xl">{{ parseInt(statistics.reach).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">ЛАЙКИ</span>
                    <span class="num text-xl">{{ parseInt(statistics.likes).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">РЕПОСТЫ</span>
                    <span class="num text-xl">{{ parseInt(statistics.reposts).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">КОММЕНТЫ</span>
                    <span class="num text-xl">{{ parseInt(statistics.comments).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">КЛИКИ</span>
                    <span class="num text-xl">{{ parseInt(statistics.clicks).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">РЕГИСТРАЦИИ</span>
                    <span class="num text-xl">{{ parseInt(statistics.regs).toLocaleString() }}</span>
                </div>
            </div>
        </div>
    </template> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "StatPage",

    data() {
        return {
            currentUser: [],
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
            view: "targetolog",
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "statistics"]),

        dataStat() {
            if (!this.statistics || !this.statistics.campaigns) return [];

            let data = { likes: 0, reposts: 0, comments: 0, spent: 0, views: 0, clicks: 0, regs: 0, publishes: 0, templates: 0, graf: [] };

            this.statistics.campaigns.forEach((cost) => {
                if (cost.date >= this.s.start && cost.date <= this.s.end) {
                    if (cost.likes > 0) data.likes += cost.likes;
                    if (cost.reposts > 0) data.reposts += cost.reposts;
                    if (cost.comments > 0) data.comments += cost.comments;
                    if (cost.spent > 0) data.spent += cost.spent;
                    if (cost.views > 0) data.views += cost.views;
                    if (cost.clicks > 0) data.clicks += cost.clicks;
                    if (cost.regs > 0) data.regs += cost.regs;
                }
            });

            if (data.clicks > 0) data.cpm = parseInt((data.spent / data.views) * 1000);
            if (data.clicks > 0) data.cpc = parseInt(data.spent / data.clicks);
            if (data.regs > 0) data.cpa = parseInt(data.spent / data.regs);

            this.statistics.publishes.forEach((p) => {
                if (p.date >= this.s.start && p.date <= this.s.end) {
                    if (p.count > 0) data.publishes += p.count;
                }
            });

            this.statistics.templates.forEach((t) => {
                if (t.date >= this.s.start && t.date <= this.s.end) {
                    if (t.count > 0) data.templates += t.count;
                }
            });

            // график расходов за последние 30 дней
            // let str = moment(this.s.end).subtract(1, "year").format("YYYY-MM-DD");
            let str = moment("2023-07-01").format("YYYY-MM-DD");
            let end = moment(this.s.end).format("YYYY-MM-DD");
            if (end > moment().format("YYYY-MM-DD")) end = moment().format("YYYY-MM-DD");

            while (str <= end) {
                let sum = 0;
                this.statistics.campaigns.forEach((item) => {
                    if (moment(item.date).format("YYYY-MM-DD") == str) sum += parseFloat(item.spent);
                });
                data.graf.push({ time: str, value: sum });
                str = moment(str).add(1, "days").format("YYYY-MM-DD");
            }

            return data;
        },

        dataCost() {
            if (!this.costs || !this.statistics) return [];

            let data = { total: 0, graf: [], clicks: 0, regs: 0, reach: 0, likes: 0, reposts: 0, comments: 0, publishes: 0 };

            this.costs.forEach((cost) => {
                if (cost.date >= this.s.start && cost.date <= this.s.end) {
                    if (cost.work > 0) data.total += cost.work;
                    if (cost.spent > 0) data.total += cost.spent;
                    if (cost.clicks > 0) data.clicks += cost.clicks;
                    if (cost.regs > 0) data.regs += cost.regs;
                }
            });

            if (data.clicks > 0) data.cpc = parseInt(data.total / data.clicks);
            if (data.regs > 0) data.cpa = parseInt(data.total / data.regs);

            // график расходов за последние 30 дней
            let str = moment(this.s.end).subtract(30, "days").format("YYYY-MM-DD");
            let end = moment(this.s.end).format("YYYY-MM-DD");
            if (end > moment().format("YYYY-MM-DD")) end = moment().format("YYYY-MM-DD");

            console.log(end);
            while (str <= end) {
                let sum = 0;
                this.costs.forEach((item) => {
                    if (moment(item.date).format("YYYY-MM-DD") == str) {
                        sum += parseFloat(item.spent);
                        sum += parseFloat(item.work);
                    }
                });
                data.graf.push({ time: str, value: sum });
                str = moment(str).add(1, "days").format("YYYY-MM-DD");
            }

            // размещения
            this.statistics.publishes.forEach((pub) => {
                if (pub.date >= this.s.start && pub.date <= this.s.end) {
                    if (pub.reach > 0) data.reach += parseInt(pub.reach);
                    if (pub.likes > 0) data.likes += parseInt(pub.likes);
                    if (pub.reposts > 0) data.reposts += parseInt(pub.reposts);
                    if (pub.comments > 0) data.comments += parseInt(pub.comments);
                    if (pub.count > 0) data.publishes += parseInt(pub.count);
                }
            });

            // this.clients.forEach((client) => {
            //     let data = { book: 0, work: 0, spent: 0 };
            //     this.costs.forEach((cost) => {
            //         if (cost.client_id == client.id && cost.date >= this.s.start && cost.date <= this.s.end) {
            //             if (cost.book > 0) data.book += cost.book;
            //             if (cost.work > 0) data.work += cost.work;
            //             if (cost.spent > 0) data.spent += cost.spent;
            //         }
            //     });
            //     client["book"] = data.book;
            //     client["work"] = data.work;
            //     client["spent"] = data.spent;

            //     client["users"] = [];
            //     let users = new Set();
            //     this.campaigns?.forEach((campaign) => {
            //         if (campaign.client_id == client.id) users.add(campaign.user_id);
            //     });

            //     for (let user_id of users) {
            //         this.users?.forEach((u) => {
            //             if (user_id == u.id) client["users"].push(u.picture);
            //         });
            //     }

            //     client["templates"] = 0;
            //     this.templates?.forEach((template) => {
            //         if (template.client_id == client.id) client["templates"]++;
            //     });
            // });

            return data;
        },

        dataTargetolog() {
            if (!this.statistics || !this.statistics.targetologs) return [];

            let data = [];
            this.statistics.targetologs.forEach((targetolog) => {
                let one = {
                    id: targetolog.id,
                    name: targetolog.name,
                    secondname: targetolog.secondname,
                    picture: targetolog.picture,
                    spent: 0,
                    views: 0,
                    clicks: 0,
                    regs: 0,
                    cpc: 0,
                    cpa: 0,
                    cpm: 0,
                };

                this.statistics.campaigns.forEach((statistics) => {
                    if (targetolog.id == statistics.user_id && statistics.date >= this.s.start && statistics.date <= this.s.end) {
                        if (statistics.spent > 0) one.spent += parseInt(statistics.spent);
                        if (statistics.views > 0) one.views += parseInt(statistics.views);
                        if (statistics.clicks > 0) one.clicks += parseInt(statistics.clicks);
                        if (statistics.regs > 0) one.regs += parseInt(statistics.regs);
                    }
                });

                if (one.views > 0) one.cpm = parseInt((one.spent / one.views) * 1000);
                if (one.clicks > 0) one.cpc = parseInt(one.spent / one.clicks);
                if (one.regs > 0) one.cpa = parseInt(one.spent / one.regs);

                if (one.spent > 0) data.push(one);
            });

            return data;
        },

        pieTargetolog() {
            if (!this.dataTargetolog || this.dataTargetolog.length < 1) return null;

            let pie = [];

            this.dataTargetolog.forEach((targetolog) => {
                if (targetolog.spent > 0) pie.push({ value: targetolog.spent, category: targetolog.name });
            });

            return pie;
        },
    },

    methods: {
        ...mapActions(["getStatistics", "setSettings", "setPeriod", "setMonth", "setTargetolog"]),
    },

    mounted() {
        this.getStatistics();
    },
};
</script>
