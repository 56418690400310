<template>
    <div class="mx-auto px-10 pt-20">
        <h2 class="uppercase text-center text-2xl text-slate-500 font-bold mb-4">Маркет Платформа: Объявления</h2>

        <div class="flex gap-2">
            <div class="basis-1/6 bg-white h-fit scroll-auto overflow-scroll sticky top-12 rounded">
                <div class="bg-slate-400 p-4 text-center text-white border-b border-slate-200 rounded">ТАРГЕТОЛОГИ</div>
                <template v-for="user in market.users" :key="user.id">
                    <div
                        class="flex items-center gap-4 p-4 border-b border-slate-200 cursor-pointer"
                        :class="{ 'bg-green-300': currentUser.id == user.id }"
                        @click="currentUser = user"
                    >
                        <img
                            :src="'https://vk.studiobaraban.ru/uploads/users/mini/' + user?.profile?.picture"
                            alt=""
                            class="rounded-full w-10 h-10"
                        />
                        <h2 class="text-slate-500">{{ user.profile?.name }} {{ user.profile?.secondname }}</h2>
                    </div>
                </template>
            </div>
            <div class="basis-5/6">
                <div v-for="date in historyDates" :key="date">
                    <div
                        class="flex justify-between gap-4 items-center bg-slate-400/80 backdrop-blur-sm text-white p-2 mb-px shadow-lg rounded sticky top-12"
                    >
                        <div>
                            <div class="text-xs text-slate-100">Дата</div>
                            <div class="text-lg font-bold">
                                {{ moment(date.date).format("DD") }} {{ month[parseInt(moment(date.date).format("MM"))] }}
                            </div>
                        </div>
                        <div v-if="currentUser?.id" class="flex items-center gap-4">
                            <img
                                :src="'https://vk.studiobaraban.ru/uploads/users/mini/' + currentUser?.profile?.picture"
                                alt=""
                                class="rounded-full w-10 h-10"
                            />

                            <div class="text-lg font-bold">{{ currentUser?.profile?.name }} {{ currentUser?.profile?.secondname }}</div>
                        </div>
                        <div v-else class="text-white/50 font-bold">Все таргетологи</div>
                        <div class="flex gap-10">
                            <div>
                                <div class="text-xs text-slate-100">Количество</div>
                                <div class="text-lg font-bold">{{ date.items.length }}</div>
                            </div>
                            <div>
                                <div class="text-xs text-slate-100">Сумма</div>
                                <div class="text-lg font-bold">{{ date.value }} ₽</div>
                            </div>
                        </div>
                    </div>

                    <div v-for="h in date.items" :key="h.id" class="grid grid-cols-7 items-center bg-white p-2 mb-px">
                        <img :src="h.group.photo_100" alt="" class="rounded w-10 h-10" />

                        <div class="text-xs">{{ h.group.id }}</div>
                        <div class="col-span-3">{{ h.group.name }}</div>
                        <div class="text-right">{{ h.group.members }}</div>
                        <div class="text-right">{{ h.group.cost }} ₽</div>

                        <!-- <h2 class="text-xs text-slate-400">{{ h }}</h2> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MarketAds",

    data() {
        return {
            currentUser: [],
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["market"]),

        historyDates() {
            console.log("historyDates");
            if (!this.market?.history) {
                return [];
            }
            let result = [];
            let data = this.market?.history;

            if (this.currentUser?.id > 0) {
                console.log("currentUser", this.currentUser);
                data = this.market.history.filter((item) => item.user_id == this.currentUser.id);
            }

            let str = moment("2023-05-10").format("YYYY-MM-DD");
            let today = moment().format("YYYY-MM-DD");
            while (today >= str) {
                let sum = 0;
                let items = [];

                data.forEach((item) => {
                    if (moment(item.date).format("YYYY-MM-DD") == today) {
                        sum += parseFloat(item.cost);
                        items.push(item);
                    }
                });

                if (sum > 0) {
                    result.push({ date: today, value: sum, items: items });
                }

                today = moment(today).subtract(1, "days").format("YYYY-MM-DD");
            }

            return result;
        },
    },

    methods: {
        ...mapActions(["getMarket"]),
    },

    mounted() {
        this.getMarket();
    },
};
</script>
