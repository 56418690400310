<template>
    <div class="flex justify-between items-center h-16 mb-4 gap-4">
        <div
            class="flex justify-center items-center aspect-square h-16 bg-white text-cyan-500 hover:bg-cyan-500 hover:text-white rounded cursor-pointer transition"
            @click="$router.push('/clients')"
        >
            <IconBack :size="5" />
        </div>

        <div v-if="dataCampaigns.total" class="flex justify-between items-center w-full text-center h-16 bg-white px-4 rounded uppercase">
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.templates.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
            </div>

            <div>
                <div class="text-xl text-yellow-500 leading-4 pt-2">{{ dataCampaigns.total.book.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">БРОНЬ</span>
            </div>
            <div>
                <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataCampaigns.total.work.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataCampaigns.total.spent.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
            </div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.views.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.clicks.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">КЛИКИ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.regs.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ЛИДЫ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.cpm.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPM</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.cpc.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPC</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total.cpa.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPA</span>
            </div>
            <!-- <div>
                <div class="text-xl leading-4 pt-2">{{ dataCampaigns.total?.cac?.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CAC</span>
            </div> -->
        </div>

        <div class="flex justify-center items-center aspect-square h-16 bg-sky-500 text-white rounded cursor-pointer" @click="setPopup('addBuyers')">
            <IconUser :size="5" />
        </div>

        <div
            class="flex justify-center items-center aspect-square h-16 bg-teal-500 text-white rounded cursor-pointer"
            @click="setPopup('addCampaign')"
        >
            <IconPlus :size="5" />
        </div>
    </div>

    <div class="flex justify-between items-center h-10 mb-4 gap-4">
        <div class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase">
            {{ client?.name }}
        </div>

        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().add(1, 'days').format('YYYY-MM-DD') && s.end == moment().add(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().add(1, 'days').format('YYYY-MM-DD'), end: moment().add(1, 'days').format('YYYY-MM-DD') })"
        >
            ЗАВТРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕГОДНЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            НЕДЕЛЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
        >
            МЕСЯЦ
        </div>

        <!-- <input type="text" class="input" /> -->

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.start" />

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div v-if="graf && graf?.length > 0 && graf[0].time" class="w-full"><LineGraf :graf="graf" h="h-32" /></div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 px-4 border-b border-slate-200">
            <div class="col-span-3 cursor-pointer" @click="setOrder('name')">КАМПАНИЯ &#8597;</div>

            <div class="text-center cursor-pointer p-2 border-r border-slate-200" @click="setOrder('templates')">КРЕАТИВЫ&nbsp;&#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('book')">БРОНЬ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('work')">В&nbsp;РАБОТЕ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cac')">CAC &#8597;</div>
        </div>

        <template v-for="item in dataCampaigns.campaigns" :key="item.id">
            <div
                class="grid grid-cols-14 items-center gap-4 px-4 border-b border-slate-200 text-sm cursor-pointer hover:bg-cyan-50/50 hover:!text-cyan-500"
            >
                <div class="flex items-center gap-2 col-span-3">
                    <a class="text-cyan-500 cursor-pointer" target="_blank" :href="'https://vk.com/adsmarket?act=campaign&campaign_id=' + item.id">
                        <IVk />
                    </a>
                    <div class="flex flex-col">
                        <div class="text-[8px] text-slate-500">{{ item.id }}</div>
                        <div class="text-md cursor-pointer" @click="pickCampaign(item), $router.push('/campaign')">{{ item.name }}</div>
                    </div>
                </div>

                <div
                    class="text-md text-slate-300 text-center p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.templates != 0 }"
                >
                    {{ item.templates }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-yellow-500 tabular-nums': item.book != 0 }">
                    {{ parseInt(item.book).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-teal-500 tabular-nums': item.work != 0 }"
                >
                    {{ parseInt(item.work).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-cyan-500 tabular-nums': item.spent != 0 }">
                    {{ parseInt(item.spent).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.views != 0 }">
                    {{ parseInt(item.views).toLocaleString() }}
                </div>
                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.clicks != 0 }">
                    {{ parseInt(item.clicks).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.regs != 0 }"
                >
                    {{ parseInt(item.regs).toLocaleString() }}
                </div>

                <div v-if="item.spent && item.views" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / (item.views / 1000)).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.clicks" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.clicks).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.regs" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.regs).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <!-- 
                <div class="text-xl text-slate-300 font-bold text-right" :class="{ '!text-cyan-500 tabular-nums': item.book != 0 }">
                    {{ item.book?.toLocaleString() }}
                </div>
                <div class="text-xl text-slate-300 font-bold text-right" :class="{ '!text-cyan-500 tabular-nums': item.work != 0 }">
                    {{ item.work?.toLocaleString() }}
                </div>
                <div class="text-xl text-slate-300 font-bold text-right" :class="{ '!text-cyan-500 tabular-nums': item.spent != 0 }">
                    {{ item.spent?.toLocaleString() }}
                </div>

                <div class="text-xl text-slate-300 font-bold text-center" :class="{ '!text-cyan-500 tabular-nums': item?.templates != 0 }">
                    {{ item?.templates }}
                </div>

                <div class="flex justify-center">
                    <a class="text-cyan-500 cursor-pointer" target="_blank" :href="'https://vk.com/adsmarket?act=campaign&campaign_id=' + item.id">
                        <IVk />
                    </a>
                </div> -->
            </div>
        </template>
    </div>

    <template v-if="s.popup == 'addBuyers'"><AddPayments /></template>
    <template v-if="s.popup == 'addCampaign'"><AddCampaign /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import LineGraf from "../components/Graf/LineGraf.vue";
import AddPayments from "../components/AddPayments.vue";

export default {
    name: "ClientPage",

    components: { LineGraf, AddPayments },

    data() {
        return {
            moment: moment,
            radiobtn: "spent",
        };
    },

    computed: {
        ...mapGetters(["s", "client", "stat", "campaigns"]),

        dataCampaigns() {
            if (!this.campaigns) return { campaigns: null, total: null };

            let total = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            let data = [];

            this.campaigns.forEach((campaign) => {
                campaign.cpm = campaign.cpc = campaign.cpa = 0;
                if (campaign.views) campaign.cpm = parseInt(campaign.spent / (campaign.views / 1000));
                if (campaign.clicks) campaign.cpc = parseInt(campaign.spent / campaign.clicks);
                if (campaign.regs) campaign.cpa = parseInt(campaign.spent / campaign.regs);

                total["templates"] += parseInt(campaign.templates);
                total["book"] += parseInt(campaign.book);
                total["work"] += parseInt(campaign.work);
                total["spent"] += parseInt(campaign.spent);
                total["views"] += parseInt(campaign.views);
                total["clicks"] += parseInt(campaign.clicks);
                total["regs"] += parseInt(campaign.regs);
            });

            data = this.campaigns;

            // let total = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            // let data = [];

            // this.campaigns.forEach((campaign) => {
            //     let data = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            //     this.stat.forEach((stat) => {
            //         if (stat.campaign_id == campaign.id && stat.date >= this.s.start && stat.date <= this.s.end) {
            //             if (stat.spent > 0) data.spent += parseInt(stat.spent);
            //             if (stat.views > 0) data.views += parseInt(stat.views);
            //             if (stat.clicks > 0) data.clicks += parseInt(stat.clicks);
            //             if (stat.regs > 0) data.regs += parseInt(stat.regs);
            //         }
            //     });

            //     this.templates.forEach((temp) => {
            //         if (temp.campaign_id == campaign.id) {
            //             data.templates++;
            //             data.book += parseInt(temp.book);
            //             data.work += parseInt(temp.work);
            //         }
            //     });

            //     campaign["templates"] = data.templates;
            //     campaign["book"] = data.book;
            //     campaign["work"] = data.work;
            //     campaign["spent"] = data.spent;
            //     campaign["views"] = data.views;
            //     campaign["clicks"] = data.clicks;
            //     campaign["regs"] = data.regs;
            //     campaign["cpm"] = data.cpm;
            //     campaign["cpc"] = data.cpc;
            //     campaign["cpa"] = data.cpa;

            //     if (campaign.views) campaign["cpm"] = parseInt(campaign.spent / (campaign.views / 1000));
            //     if (campaign.clicks) campaign["cpc"] = parseInt(campaign.spent / campaign.clicks);
            //     if (campaign.regs) campaign["cpa"] = parseInt(campaign.spent / campaign.regs);

            //     total["templates"] += data.templates;
            //     total["book"] += data.book;
            //     total["work"] += data.work;
            //     total["spent"] += data.spent;
            //     total["views"] += data.views;
            //     total["clicks"] += data.clicks;
            //     total["regs"] += data.regs;
            // });

            // data = this.campaigns;

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "templates") {
                if (this.s.order) {
                    data.sort((a, b) => (a.templates > b.templates ? 1 : b.templates > a.templates ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.templates < b.templates ? 1 : b.templates < a.templates ? -1 : 0));
                }
            }

            if (this.s.order_name == "book") {
                if (this.s.order) {
                    data.sort((a, b) => (a.book > b.book ? 1 : b.book > a.book ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.book < b.book ? 1 : b.book < a.book ? -1 : 0));
                }
            }

            if (this.s.order_name == "work") {
                if (this.s.order) {
                    data.sort((a, b) => (a.work > b.work ? 1 : b.work > a.work ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.work < b.work ? 1 : b.work < a.work ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
            if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

            return { campaigns: data, total: total };
        },

        // график расхода в клиенте
        graf() {
            if (!this.stat) return null;

            let result = [];

            let srt = moment(this.stat[0]?.date).format("YYYY-MM-DD");
            if (this.s.start != srt) srt = moment(this.s.start).format("YYYY-MM-DD");
            let end = moment().format("YYYY-MM-DD");
            if (this.s.end != end) end = moment(this.s.end).format("YYYY-MM-DD");

            while (srt <= end) {
                let d = { time: moment(srt).format("YYYY-MM-DD"), views: 0, clicks: 0, regs: 0, spent: 0 };
                this.stat.forEach((stat) => {
                    if (stat.date == srt) {
                        d.spent += parseInt(stat.spent);
                        d.views += parseInt(stat.views);
                        d.clicks += parseInt(stat.clicks);
                        d.regs += parseInt(stat.regs);
                    }
                });

                d.cpm = 0;
                d.cpc = 0;
                d.cpa = 0;
                if (d.views > 0) d.cpm = parseInt(d.spent / (d.views / 1000));
                if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks);
                if (d.regs > 0) d.cpa = parseInt(d.spent / d.regs);

                // для графика
                if (this.radiobtn == "spent") d.value = d.spent;
                if (this.radiobtn == "views") d.value = d.views;
                if (this.radiobtn == "clicks") d.value = d.clicks;
                if (this.radiobtn == "regs") d.value = d.regs;
                if (this.radiobtn == "cpc") d.value = d.cpc;
                if (this.radiobtn == "cpm") d.value = d.cpm;
                if (this.radiobtn == "cpa") d.value = d.cpa;

                result.push(d);
                srt = moment(srt).add(1, "day").format("YYYY-MM-DD");
            }

            return result;
        },
    },

    methods: {
        ...mapActions(["getCampaigns", "setSettings", "setPeriod", "setOrder", "pickCampaign", "setPopup", "closePopup"]),
    },

    mounted() {
        this.getCampaigns();
    },
};
</script>
