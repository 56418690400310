<template>
    <!-- <div class="flex justify-between items-center mb-4 gap-2">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕЙЧАС
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            7 ДНЕЙ
        </div>
        <template v-for="(value, key) in month" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>

        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.end" />
    </div> -->

    <div class="w-full p-4 pl-10 border border-slate-200 mb-4 rounded relative">
        <div class="w-36 transform -rotate-90 absolute -left-14 -translate-y-1/2 top-1/2 bg-white p-1 text-slate-400 text-sm text-center">
            РЕКЛАМА
        </div>
        <!-- <div>БЮДЖЕТ</div>
        <div>КРЕАТИВЫ</div>
        <div>ПРОСМОТРЫ</div>
        <div>КЛИКИ</div> -->

        <div class="grid grid-cols-5 gap-4">
            <div class="flex flex-wrap h-32 overflow-y-scroll gap-2 mb-4">
                <span class="w-full text-slate-400 text-xs">SOURCE</span>
                <div
                    v-for="item in utmFilter.source"
                    :key="item"
                    class="w-fit py-1 px-3 text-sm bg-white rounded cursor-pointer"
                    :class="{ '!bg-teal-300': utms.source.includes(item) }"
                    @click="setUtmSource(item)"
                >
                    {{ item }}
                </div>
            </div>

            <div class="flex flex-wrap h-32 overflow-y-scroll gap-2 mb-4">
                <span class="w-full text-slate-400 text-xs">MEDIUM</span>
                <div
                    v-for="item in utmFilter.medium"
                    :key="item"
                    class="w-fit py-1 px-3 text-sm bg-white rounded cursor-pointer"
                    :class="{ '!bg-teal-300': utms.medium.includes(item) }"
                    @click="setUtmMedium(item)"
                >
                    {{ item }}
                </div>
            </div>

            <div class="flex flex-wrap h-32 overflow-y-scroll gap-2 mb-4">
                <span class="w-full text-slate-400 text-xs">CAMPAIGN</span>
                <div
                    v-for="item in utmFilter.campaign"
                    :key="item"
                    class="w-fit py-1 px-3 text-sm bg-white rounded cursor-pointer"
                    :class="{ '!bg-teal-300': utms.campaign.includes(item) }"
                    @click="setUtmCampaign(item)"
                >
                    {{ item }}
                </div>
            </div>

            <div class="flex flex-wrap h-32 overflow-y-scroll gap-2 mb-4">
                <span class="w-full text-slate-400 text-xs">CONTENT</span>
                <div
                    v-for="item in utmFilter.content"
                    :key="item"
                    class="w-fit py-1 px-3 text-sm bg-white rounded cursor-pointer"
                    :class="{ '!bg-teal-300': utms.content.includes(item) }"
                    @click="setUtmContent(item)"
                >
                    {{ item }}
                </div>
            </div>

            <div class="flex flex-wrap h-32 overflow-y-scroll gap-2 mb-4">
                <span class="w-full text-slate-400 text-xs">TERM</span>
                <div
                    v-for="item in utmFilter.term"
                    :key="item"
                    class="w-fit py-1 px-3 text-sm bg-white rounded cursor-pointer"
                    :class="{ '!bg-teal-300': utms.term.includes(item) }"
                    @click="setUtmTerm(item)"
                >
                    {{ item }}
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-center items-center bg-white rounded p-4">
            <span class="title">РЕГИСТРАЦИИ</span>

            <span class="num text-6xl">{{ parseInt(regsF?.regs.length).toLocaleString() }} </span>
        </div>

        <div v-if="regsF?.graf" class="w-full"><LineGraf :graf="regsF?.graf" /></div>
    </div>

    <div class="w-full p-4 pl-10 h-40 border border-slate-200 mb-4 rounded relative">
        <div class="w-36 transform -rotate-90 absolute -left-14 -translate-y-1/2 top-1/2 bg-white p-1 text-slate-400 text-sm text-center">
            РЕГИСТРАЦИИ
        </div>
        <div>РЕГИСТРАЦИИ</div>
        <div>на 19:00 / на 11:00</div>
        <div>СТОИМОСТЬ РЕГИСТРАЦИИ</div>
        <div>СОЦСЕТИ</div>
        <div>TELEGRAM + VK</div>
    </div>

    <div class="w-full p-4 pl-10 h-40 border border-slate-200 mb-4 rounded relative">
        <div class="w-36 transform -rotate-90 absolute -left-14 -translate-y-1/2 top-1/2 bg-white p-1 text-slate-400 text-sm text-center">
            ВЕБИНАРЫ
        </div>
    </div>

    <div class="w-full p-4 pl-10 h-40 border border-slate-200 mb-4 rounded relative">
        <div class="w-36 transform -rotate-90 absolute -left-14 -translate-y-1/2 top-1/2 bg-white p-1 text-slate-400 text-sm text-center">
            ПРОДАЖИ
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import LineGraf from "../components/LineGraf.vue";

export default {
    name: "AnalyticsPage",

    components: { LineGraf },

    data() {
        return {
            currentUser: [],
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
            view: "targetolog",
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "regs", "utms"]),

        utmFilter() {
            if (!this.regs) return [];
            let regs = this.regs.filter((reg) => reg.date >= this.s.start && reg.date <= this.s.end);

            let data = { source: [], medium: [], campaign: [], content: [], term: [] };

            data.source = Array.from(new Set(regs.map((reg) => reg.s)));
            data.medium = Array.from(new Set(regs.map((reg) => reg.m)));
            data.campaign = Array.from(new Set(regs.map((reg) => reg.k)));
            data.content = Array.from(new Set(regs.map((reg) => reg.c)));
            data.term = Array.from(new Set(regs.map((reg) => reg.t)));

            return data;
        },

        regsF() {
            let regs = this.regs.filter((reg) => reg.date >= this.s.start && reg.date <= this.s.end);

            // console.log("regs", regs.length);

            if (this.utms.source && this.utms.source.length > 0) regs = regs.filter((reg) => this.utms.source.includes(reg.s));
            if (this.utms.medium && this.utms.medium.length > 0) regs = regs.filter((reg) => this.utms.medium.includes(reg.m));
            if (this.utms.campaign && this.utms.campaign.length > 0) regs = regs.filter((reg) => this.utms.campaign.includes(reg.k));
            if (this.utms.content && this.utms.content.length > 0) regs = regs.filter((reg) => this.utms.content.includes(reg.c));
            if (this.utms.term && this.utms.term.length > 0) regs = regs.filter((reg) => this.utms.term.includes(reg.t));

            const registrationCounts = regs.reduce((acc, { date }) => {
                acc[date] = (acc[date] || 0) + 1;
                return acc;
            }, {});

            // console.log("regs", registrationCounts);

            // график
            let graf = [];
            // let str = moment(this.s.end).subtract(1, "year").format("YYYY-MM-DD");
            let str = moment("2023-11-01").format("YYYY-MM-DD");
            let end = moment().format("YYYY-MM-DD");

            while (str <= end) {
                const count = registrationCounts[str] || 0;
                graf.push({ time: str, value: count });
                str = moment(str).add(1, "days").format("YYYY-MM-DD");
            }

            return { regs: regs, graf: graf };
        },
    },

    methods: {
        ...mapActions([
            "getAnalytics",
            "setUtmSource",
            "setUtmMedium",
            "setUtmCampaign",
            "setUtmContent",
            "setUtmTerm",
            "setSettings",
            "setPeriod",
            "setMonth",
        ]),
    },

    mounted() {
        this.getAnalytics();
    },
};
</script>
