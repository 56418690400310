<template>
    <div class="border-r border-slate-300 pr-4">
        <template v-for="t in course.themes" :key="t.id">
            <template v-for="sTheme in studentThemes" :key="sTheme.id">
                <template v-if="sTheme.wid == t.id">
                    <div class="pb-2 font-bold text-md cursor-pointer">
                        <h2
                            class="uppercase cursor-pointer text-slate-400"
                            @click="pickTheme({ theme: t, status: sTheme.status })"
                            :class="{
                                '!text-yellow-500': sTheme.status == 4,
                                '!text-teal-500': sTheme.status == 2,
                            }"
                        >
                            {{ t?.title }}
                        </h2>
                        <!-- <div v-for="l in t.lessons" :key="l.id">
                            <template v-if="l.wid == theme.id">
                                <h2 class="pl-4 py-1 text-sm cursor-pointer" @click="pickLesson(l)">{{ l.title }}</h2>
                            </template>
                        </div> -->
                    </div>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ThemeMenu",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["course", "theme", "lesson", "student"]),

        studentThemes() {
            return this.student.themes.filter((item) => item.cid == this.course.id);
        },

        studentLessons() {
            return this.student.lessons.filter((item) => item.course_id == this.course.id && item.wid == this.theme.id);
        },
    },

    methods: {
        ...mapActions(["pickTheme", "pickLesson"]),
    },
};
</script>
