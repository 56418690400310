<template>
    <div class="flex justify-between items-center h-20 mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase cursor-pointer">ПРОЕКТЫ</div>
        <input type="text" class="input" />
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ID</div>

            <div class="col-span-2">НАЗВАНИЕ КРЕАТИВА</div>

            <div class="text-center"></div>
        </div>

        <template v-for="item in projects" :key="item.id">
            <div
                v-if="item.status == 1"
                class="grid grid-cols-12 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-600"
            >
                <div @click="pickProject(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                </div>

                <div @click="pickProject(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>

                <div class="flex justify-center gap-4 relative group">
                    <span class="tip">{{ tip }}</span>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'проверен'"
                        @mouseleave="tip = ''"
                        @click="archiveTemplate(item.id)"
                    >
                        <IconOk :size="4" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "CampaignPage",

    data() {
        return {
            moment: moment,
            tip: "",
        };
    },

    computed: {
        ...mapGetters(["s", "projects"]),
    },

    methods: {
        ...mapActions(["getProjects", "pickProject"]),
    },

    mounted() {
        console.log("DocPage");
        this.getProjects();
    },
};
</script>
