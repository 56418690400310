import store from "@/store/index";

const authService = {
    async isLogged() {
        // console.log("auth isLogged", store.state.profile?.profile);

        if (store.state.profile?.profile?.id && store.state.profile?.menu && !!localStorage.getItem("AUTH")) return true;

        await store.dispatch("getProfile");

        if (store.state.profile?.profile?.id && store.state.profile?.menu && !!localStorage.getItem("AUTH")) return true;

        return false;
    },
};

export default authService;
