<template>
    <h2 class="w-full text-3xl text-slate-500 text-center font-bold">РЕЙТИНГ</h2>

    <div class="w-1/2 m-auto">
        <div v-for="item in statistics.raiting" :key="item" class="w-full rounded-xl h-24 overflow-hidden relative pt-2 text-slate-500 group">
            <div class="absolute bottom-0 z-50">
                <img
                    v-if="item.picture"
                    :src="'https://api.timekraken.ru/uploads/users/u' + item.user_id + '.png'"
                    :alt="item.name"
                    class="w-full h-[88px] object-contain group-hover:h-24 duration-300"
                />
                <img
                    v-else
                    :src="'https://api.timekraken.ru/uploads/users/u0.png'"
                    :alt="item.name"
                    class="w-full h-[88px] object-contain group-hover:h-24 duration-300 opacity-30"
                />
            </div>

            <div class="bg-white rounded-xl h-20 grid grid-cols-7 justify-center items-center gap-2 mt-4">
                <div></div>
                <div class="text-sm font-light text-center">{{ item.name }}</div>

                <div class="flex items-center gap-1 text-cyan-500 col-span-2">
                    <span class="text-3xl group-hover:text-4xl duration-300 font-bold">{{ parseInt(item.spent).toLocaleString() }}</span>
                    <sup>₽</sup>
                </div>

                <div class="text-center">
                    <div class="text-xl font-light leading-3 mt-2">{{ item.templates }}</div>
                    <span class="text-[8px] text-slate-400 uppercase">Креативы</span>
                </div>
                <div class="text-center">
                    <div class="text-xl font-light leading-3 mt-2">{{ item.publishes }}</div>
                    <span class="text-[8px] text-slate-400 uppercase">Публикации</span>
                </div>
                <div class="text-center">
                    <div class="flex justify-center items-center mt-2 gap-1">
                        <span class="text-xl font-light leading-3">{{ item.cpa }}</span>
                        <sup>₽</sup>
                    </div>
                    <span class="text-[8px] text-slate-400 uppercase">CPA</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "TopPage",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["s", "logs", "users", "statistics"]),

        total() {
            if (!this.logs || !this.statistics || !this.statistics.campaigns) return null;

            let total = [];
            this.users.forEach((user) => {
                let item = {
                    id: user.id,
                    name: user.name,
                    picture: user.picture,
                    templates: 0,
                    publishes: 0,
                    doing: 0,
                    spent: 0,
                    views: 0,
                    clicks: 0,
                    regs: 0,
                    cpm: 0,
                    cpc: 0,
                    cpa: 0,
                };

                this.logs.templates.forEach((template) => {
                    if (template.user_id == user.id && template.date >= this.s.start && template.date <= this.s.end) item.templates += template.count;
                });
                this.logs.publishes.forEach((publish) => {
                    if (publish.user_id == user.id && publish.date >= this.s.start && publish.date <= this.s.end) item.publishes += publish.count;
                });
                this.logs.log.forEach((lo) => {
                    if (lo.user_id == user.id && lo.date >= this.s.start && lo.date <= this.s.end) item.doing += lo.count;
                });

                //&& statistics.date >= this.s.start && statistics.date <= this.s.end
                this.statistics.raiting.forEach((statistics) => {
                    if (user.id == statistics.user_id) {
                        item.spent += parseInt(statistics.spent);
                        item.views += parseInt(statistics.views);
                        item.clicks += parseInt(statistics.clicks);
                        item.regs += parseInt(statistics.regs);
                    }
                });

                if (item.views > 0) item.cpm = parseInt((item.spent / item.views) * 1000);
                if (item.clicks > 0) item.cpc = parseInt(item.spent / item.clicks);
                if (item.regs > 0) item.cpa = parseInt(item.spent / item.regs);

                if (item.spent > 0) total.push(item);
            });

            total.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));

            return total;
        },
    },

    methods: {
        ...mapActions(["getStatistics", "getLogs"]),
    },

    mounted() {
        this.getStatistics();
        this.getLogs();
    },
};
</script>
