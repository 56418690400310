<template>
    <div class="w-5/6 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 text-slate-400">НАСТРОЙКИ: {{ template.name }}</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex justify-center items-center gap-4 mb-4">
                <div
                    class="h-8 flex items-center rounded bg-slate-400 hover:bg-yellow-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                    :class="{ '!bg-yellow-200 !text-yellow-700': template.counter_type == 1 }"
                    @click="template.counter_type = 1"
                >
                    Яндекс
                </div>
                <div
                    class="h-8 flex items-center rounded bg-slate-400 hover:bg-sky-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                    :class="{ '!bg-sky-200 !text-sky-700': template.counter_type == 2 }"
                    @click="template.counter_type = 2"
                >
                    Сенлер
                </div>
            </div>

            <div class="flex flex-col items-center mb-4">
                <input
                    type="text"
                    class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded"
                    placeholder="Номер счетчика"
                    v-model="template.counter_id"
                />
            </div>

            <div class="flex flex-col items-center mb-4">
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" placeholder="Номер цели" v-model="template.goal_id" />
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="saveTemplate(template), closePopup()">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "SettingTemplate",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["saveTemplate", "closePopup"]),
    },
};
</script>
