<template>
    <div class="flex justify-between items-center h-16 mb-4 gap-4">
        <div v-if="dataTemplates.total" class="flex justify-between items-center w-full text-center h-16 bg-white px-4 rounded uppercase">
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.ads.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
            </div>

            <div>
                <div class="text-xl text-yellow-500 leading-4 pt-2">{{ dataTemplates.total?.book.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">БРОНЬ</span>
            </div>
            <div>
                <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataTemplates.total?.work.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataTemplates.total?.spent.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
            </div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.views.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.clicks.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">КЛИКИ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.regs.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ЛИДЫ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpm.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPM</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpc.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPC</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpa.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPA</span>
            </div>
        </div>
    </div>

    <div class="flex justify-between items-center h-10 mb-4 gap-4">
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().add(1, 'days').format('YYYY-MM-DD') && s.end == moment().add(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().add(1, 'days').format('YYYY-MM-DD'), end: moment().add(1, 'days').format('YYYY-MM-DD') })"
        >
            ЗАВТРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕГОДНЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            НЕДЕЛЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
        >
            МЕСЯЦ
        </div>

        <!-- <input type="text" class="input" /> -->

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.start" />

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 px-4 border-b border-slate-200">
            <div class="cursor-pointer" @click="setOrder('id')">ID &#8597;</div>
            <div class="col-span-2 cursor-pointer" @click="setOrder('name')">КРЕАТИВ &#8597;</div>

            <div class="text-center cursor-pointer p-2 border-r border-slate-200" @click="setOrder('ads')">ОБЪ&nbsp;&#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('book')">БРОНЬ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('work')">В&nbsp;РАБОТЕ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

            <div class="text-right"></div>
        </div>

        <template v-for="item in dataTemplates.templates" :key="item.id">
            <div
                v-if="item.status == 1"
                class="grid grid-cols-14 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-600"
            >
                <div @click="pickTemplate(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                    <img
                        v-if="item.picture && JSON.parse(item.picture)[0]"
                        :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(item.picture)[0]"
                        class="rounded w-10 h-10"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-10 h-10" />
                </div>

                <div @click="pickTemplate(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>

                <div
                    class="text-md text-slate-300 text-center p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.ads_count != 0 }"
                >
                    {{ item.ads_count }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-yellow-600 tabular-nums': item.book != 0 }">
                    {{ parseInt(item.book).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-teal-500 tabular-nums': item.work != 0 }"
                >
                    {{ parseInt(item.work).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-cyan-600 tabular-nums': item.spent != 0 }">
                    {{ parseInt(item.spent).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.views != 0 }">
                    {{ parseInt(item.views).toLocaleString() }}
                </div>
                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.clicks != 0 }">
                    {{ parseInt(item.clicks).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.regs != 0 }"
                >
                    {{ parseInt(item.regs).toLocaleString() }}
                </div>

                <div v-if="item.spent && item.views" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / (item.views / 1000)).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.clicks" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.clicks).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.regs" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.regs).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "TemplatesAdmin",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "statAd", "templates"]),

        dataTemplates() {
            if (!this.templates || !this.statAd) return { templates: null, total: null };

            let total = { ads: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            let data = [];

            this.templates.forEach((template) => {
                let data = { ads: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
                this.statAd.forEach((stat) => {
                    if (stat.template_id == template.id && stat.date >= this.s.start && stat.date <= this.s.end) {
                        if (stat.spent > 0) data.spent += stat.spent;
                        if (stat.views > 0) data.views += stat.views;
                        if (stat.clicks > 0) data.clicks += stat.clicks;
                        if (stat.regs > 0) data.regs += stat.regs;
                    }
                });

                template["spent"] = data.spent;
                template["views"] = data.views;
                template["clicks"] = data.clicks;
                template["regs"] = data.regs;
                template["cpm"] = data.cpm;
                template["cpc"] = data.cpc;
                template["cpa"] = data.cpa;

                if (template.views) template["cpm"] = parseInt(template.spent / (template.views / 1000));
                if (template.clicks) template["cpc"] = parseInt(template.spent / template.clicks);
                if (template.regs) template["cpa"] = parseInt(template.spent / template.regs);

                total["ads"] += template["ads_count"];
                total["book"] += template["book"];
                total["work"] += template["work"];
                total["spent"] += data.spent;
                total["views"] += data.views;
                total["clicks"] += data.clicks;
                total["regs"] += data.regs;
            });

            data = this.templates;

            if (this.s.order_name == "id") {
                if (this.s.order) {
                    data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
                }
            }

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "ads") {
                if (this.s.order) {
                    data.sort((a, b) => (a.ads_count > b.ads_count ? 1 : b.ads_count > a.ads_count ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.ads_count < b.ads_count ? 1 : b.ads_count < a.ads_count ? -1 : 0));
                }
            }

            if (this.s.order_name == "book") {
                if (this.s.order) {
                    data.sort((a, b) => (a.book > b.book ? 1 : b.book > a.book ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.book < b.book ? 1 : b.book < a.book ? -1 : 0));
                }
            }

            if (this.s.order_name == "work") {
                if (this.s.order) {
                    data.sort((a, b) => (a.work > b.work ? 1 : b.work > a.work ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.work < b.work ? 1 : b.work < a.work ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
            if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

            return { templates: data, total: total };
        },
    },

    methods: {
        ...mapActions(["getAdminTemplates", "setSettings", "setPeriod", "setOrder", "setPopup", "closePopup", "pickTemplate"]),
    },

    mounted() {
        console.log("CampaignPage");

        this.getAdminTemplates();
    },
};
</script>
