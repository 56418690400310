import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import AdminMonitor from "@/Admin/Monitor";
import AdminCabinets from "@/Admin/Cabinets";
import AdminAccess from "@/Admin/Access";
import TemplatesAdmin from "@/Admin/Templates";

import MainPage from "@/Main/Main";
import ProfilePage from "@/Main/Profile";

import ClientsPage from "@/Market/Clients";
import ClientPage from "@/Market/Client";
import CampaignPage from "@/Market/Campaign";
import TemplatePage from "@/Market/Template";
import MarketAds from "@/Market/MarketAds";

import TopPage from "@/Stat/Top";
import LogPage from "@/Stat/Log";
import StatPage from "@/Stat/Stat";
import GroupsPage from "@/Stat/Groups";

import AnalyticsPage from "@/Stat/Analytics";

import PrototypePage from "@/Market/Prototype";
import DocsPage from "@/Doc/Docs";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/clients",
        name: "Clients",
        component: ClientsPage,
        meta: { title: "Clients" },
    },
    {
        path: "/client",
        name: "Client",
        component: ClientPage,
        meta: { title: "Client" },
    },
    {
        path: "/stat",
        name: "Stat",
        component: StatPage,
        meta: { title: "Stat" },
    },
    {
        path: "/top",
        name: "Top",
        component: TopPage,
        meta: { title: "Рейтинг" },
    },
    {
        path: "/analytics",
        name: "Analytics",
        component: AnalyticsPage,
        meta: { title: "Analytics" },
    },
    {
        path: "/log",
        name: "Log",
        component: LogPage,
        meta: { title: "Log" },
    },
    {
        path: "/groups",
        name: "Groups",
        component: GroupsPage,
        meta: { title: "Группы" },
    },
    {
        path: "/admin/monitor",
        name: "Monitor",
        component: AdminMonitor,
        meta: { title: "Monitor" },
    },
    {
        path: "/admin/cabinets",
        name: "Cabinets",
        component: AdminCabinets,
        meta: { title: "Cabinets" },
    },
    {
        path: "/admin/access",
        name: "Access",
        component: AdminAccess,
        meta: { title: "Access" },
    },
    {
        path: "/admin/templates",
        name: "Templates",
        component: TemplatesAdmin,
        meta: { title: "Templates" },
    },
    {
        path: "/ads",
        name: "MarketAds",
        component: MarketAds,
        meta: { title: "Market Ads" },
    },
    {
        path: "/campaign",
        name: "CampaignPage",
        component: CampaignPage,
        meta: { title: "Market Campaign" },
    },
    {
        path: "/template",
        name: "TemplatePage",
        component: TemplatePage,
        meta: { title: "One Template" },
    },
    {
        path: "/prototype",
        name: "PrototypePage",
        component: PrototypePage,
        meta: { title: "Prototype" },
    },
    {
        path: "/docs",
        name: "DocsPage",
        component: DocsPage,
        meta: { title: "Юридические данные" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "МП";

    if (
        // Avoid an infinite redirect
        to.name !== "Main" &&
        // make sure the user is authenticated
        !authService.isLogged()
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Main" };
    }
    // window.scrollTo(0, 0);
});

export default router;
