<template>
    <div class="w-5/6 fixed flex justify-between gap-4 left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] z-50">
        <div class="flex flex-col w-1/2 relative">
            <div class="bg-white shadow-lg p-8 pb-20 text-center h-full overflow-auto rounded">
                <!-- <h3 class="text-center text-md pb-4 text-slate-400">РЕДАКТИРОВАТЬ КРЕАТИВ : {{ template.name }}</h3> -->
                <div class="flex flex-col items-center mb-4 relative">
                    <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Название</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.name" />
                </div>

                <div class="flex flex-col items-center mb-4 relative">
                    <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Используй {link}</label>
                    <textarea class="w-full h-72 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
                </div>

                <div class="grid grid-cols-4 items-center gap-2 w-full relative">
                    <template v-if="template.files">
                        <div v-for="file in template.files" :key="file" class="relative rounded overflow-hidden bg-slate-100">
                            <img :src="'https://api.timekraken.ru/uploads/templates/' + file.name" class="w-40 aspect-square object-cover" />
                            <div
                                @click="deattachFile(file)"
                                class="w-full h-full flex items-center justify-center bg-black/0 text-white/0 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                            >
                                <IconDelete :size="8" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="flex justify-between gap-8 bg-white w-full px-8 py-4 absolute left-0 z-50 bottom-0">
                <div class="btn btn-empty-red text-sm py-2 px-4" @click="setPopup('delete')">Удалить</div>

                <div class="btn btn-green text-sm py-2 px-4" @click="save(template)">Сохранить</div>
            </div>
        </div>

        <div class="flex flex-col w-1/2 bg-white shadow-lg p-8 pb-32 text-center h-full overflow-auto rounded"><FilesPopup /></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import FilesPopup from "../components/Files.vue";

export default {
    name: "EditTemplate",

    components: { FilesPopup },

    data() {
        return {
            moment: moment,
            popup: "files",
        };
    },

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["getFiles", "saveTemplate", "deattachFile", "closePopup", "setPopup"]),

        save(template) {
            this.closePopup();
            this.saveTemplate(template);
        },
    },

    mounted() {
        this.getFiles();
    },
};
</script>
