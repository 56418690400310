import API from "../../services/api";
import axios from "axios";
import router from "@/services/router";

export default {
    state: {
        menu: [],
        profile: [],
        news: [
            {
                type: "new",
                picture: "graf.jpg",
                name: "График расхода на странице клиента",
                desc: "Теперь наглядно видно в какие дни и на какую сумму была закуплена реклама",
            },
            {
                type: "new",
                picture: "raiting.jpg",
                name: "Рейтинг",
                desc: "Встречайте TOP лучших",
            },
            { type: "new", name: "Список всех броней во всех клиентах на одном экране" },
            { type: "new", name: "Список всех активных размещений во всех клиентах на одном экране" },
            {
                type: "new",
                picture: "bad.jpg",
                name: "Значок 'неэффективно'",
                desc: "Группы, в которых слишком высокий CPA помечены специальным значком 'неэффективно'",
            },
            { type: "bug", name: "Время размещения" },
            {
                type: "new",
                picture: "empty.jpg",
                name: "Обновлена основная информациюнная панель",
                desc: "Пустышки перенесены наверх, панель закреплена и в ней отображаются группы для закупа при массовом выборе галочками",
            },
            {
                type: "new",
                picture: "avatar.jpg",
                name: "Крутые аватары",
            },
        ],
    },

    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
            localStorage.setItem("profile", JSON.stringify(profile));
        },
        setMenu(state, menu) {
            state.menu = menu;
            localStorage.setItem("menu", JSON.stringify(menu));
        },
    },

    actions: {
        login(ctx, data) {
            console.log("Login");
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            axios.post("https://api.timekraken.ru/site/login", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    ctx.commit("setMenu", res.data.menu);
                    router.push("/clients");
                }
            });
        },

        // registration(ctx, data) {
        //     let formData = new FormData();
        //     formData.append("username", data.username);
        //     formData.append("email", data.email);
        //     formData.append("password", data.password);
        //     axios.post("https://api.timekraken.ru/site/registration", formData).then((res) => {
        //         if (res.data.alert) ctx.commit("setAlert", res.data.alert);
        //         if (res.data && res.data.token) {
        //             localStorage.setItem("AUTH", res.data.token);
        //             ctx.commit("setProfile", res.data.profile);
        //             ctx.commit("setMenu", res.data.menu);
        //             router.push("/clients");
        //         }
        //     });
        // },

        logout(ctx) {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            localStorage.removeItem("menu");
            ctx.commit("setProfile", null);
            ctx.commit("setMenu", null);
            router.push({ name: "Main" });
        },

        async getProfile(ctx) {
            if (ctx.state.profile.id && ctx.state.menu) return;

            await API.GET("site/profile").then((res) => {
                ctx.commit("setProfile", res.data.profile);
                ctx.commit("setMenu", res.data.menu);
                if (res.data.profile && res.data.menu && router.currentRoute._value.name == "Main") router.push("/clients");
            });
        },
    },

    getters: {
        menu(state) {
            return state.menu;
        },
        profile(state) {
            return state.profile;
        },
        news(state) {
            return state.news;
        },
    },
};
