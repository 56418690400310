<template>
    <div class="flex justify-center items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <div class="text-center text-2xl text-slate-500">
            <span class="">МОНИТОР</span>
        </div>
    </div>

    <div class="grid grid-cols-2 md:grid-cols-8 gap-4 mt-4">
        <div @click="newTask(1)" class="bg-white flex justify-center items-center text-sm flex-col rounded p-4 cursor-pointer">
            <span class="title">СОЗДАНИЕ</span>
            <span class="num text-3xl">{{ monitor?.create?.length }}</span>
            <span v-if="monitor?.update[1]">{{ moment(monitor?.update[1]).format("DD.MM H:mm:ss") }}</span>
            <!-- <div class="btn btn-teal-400 mt-2 text-xs py-1 px-2" @click="newTask(1)">+</div> -->
        </div>

        <div @click="newTask(2)" class="bg-white flex justify-center items-center text-sm flex-col rounded p-4 cursor-pointer">
            <span class="title">МОДЕРАЦИЯ</span>
            <span class="num text-3xl">{{ monitor?.moderate?.length }}</span>
            <span v-if="monitor?.update[2]">{{ moment(monitor?.update[2]).format("DD.MM H:m:ss") }}</span>

            <!-- <div class="btn btn-teal-400 mt-2 text-xs py-1 px-2" @click="newTask(2)">+</div> -->
        </div>

        <div @click="newTask(3)" class="bg-white flex justify-center items-center text-sm flex-col rounded p-4 cursor-pointer">
            <span class="title">ПУБЛИКАЦИЯ</span>
            <span class="num text-3xl">{{ monitor?.publish?.length }}</span>
            <span v-if="monitor?.update[3]">{{ moment(monitor?.update[3]).format("DD.MM H:mm:ss") }}</span>
            <!-- <div class="btn btn-teal-400 mt-2 text-xs py-1 px-2" @click="newTask(3)">+</div> -->
        </div>

        <div @click="newTask(4)" class="bg-white flex justify-center items-center text-sm flex-col rounded p-4 cursor-pointer">
            <span class="title">СТАТУС</span>
            <span class="num text-3xl">{{ monitor?.check?.length }}</span>
            <span v-if="monitor?.update[4]">{{ moment(monitor?.update[4]).format("DD.MM H:m:ss") }}</span>

            <!-- <div class="btn btn-teal-400 mt-2 text-xs py-1 px-2" @click="newTask(4)">+</div> -->
        </div>

        <div @click="newTask(5)" class="bg-white flex justify-center items-center text-sm flex-col rounded p-4 cursor-pointer">
            <span class="title">СТАТИСТИКА</span>
            <span class="num text-3xl">{{ monitor?.stats?.length }}</span>
            <span v-if="monitor?.update[5]">{{ moment(monitor?.update[5]).format("DD.MM H:m:ss") }}</span>

            <!-- <div class="btn btn-teal-400 mt-2 text-xs py-1 px-2" @click="newTask(5)">+</div> -->
        </div>

        <div class="bg-white flex justify-center items-center text-sm flex-col rounded p-4">
            <span class="title">ОТМЕНА</span>
            <span class="num text-3xl">{{ monitor?.cancel?.length }}</span>
            <span v-if="monitor?.update[6]">{{ moment(monitor?.update[6]).format("DD.MM H:mm:ss") }}</span>
        </div>

        <div class="bg-white flex justify-center items-center text-sm flex-col rounded p-4">
            <span class="title">КАМПАНИИ</span>
            <span class="num text-3xl">{{ monitor?.campaigns }}</span>
            <span v-if="monitor?.update[7]">{{ moment(monitor?.update[7]).format("DD.MM H:mm:ss") }}</span>
        </div>

        <div class="bg-white flex justify-center items-center text-sm flex-col rounded p-4">
            <span class="title">ГРУППЫ МП</span>
            <span class="num text-3xl">{{ monitor?.groups }}</span>
            <span v-if="monitor?.update[8]">{{ moment(monitor?.update[8]).format("DD.MM") }}</span>
        </div>
    </div>

    <div class="bg-white rounded mt-4">
        <div class="grid grid-cols-8 items-center text-xs text-slate-400 gap-4 p-2 px-4 border-b border-slate-200">
            <div>ID</div>
            <div class="col-span-2">НАЗВАНИЕ</div>
            <div>СОЗДАНА</div>
            <div>ВЫПОЛНЕНА</div>
            <div>ПРИОРИТЕТ</div>
            <div>СТАТУС</div>
            <div></div>
        </div>

        <template v-for="item in monitor?.tasks" :key="item.id">
            <div class="grid grid-cols-8 items-center gap-4 p-2 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-600">
                <div>{{ item.id }}</div>

                <div class="col-span-2">{{ types[item.type] }}</div>

                <div class="tabular-nums">{{ moment(item.create_at).format("DD.MM HH:mm:ss") }}</div>

                <div v-if="item.done_at" class="tabular-nums">{{ moment(item.done_at).format("HH:mm:ss") }}</div>
                <div v-else class="tabular-nums"></div>

                <div>{{ item.priority }}</div>

                <div>{{ item.status }}</div>

                <div @click="delTask(item)" class="flex justify-end cursor-pointer hover:text-red-500">
                    <IconDelete :size="4" />
                </div>
            </div>
        </template>
    </div>

    <!-- <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>СОЗДАЕТСЯ В VK</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.create" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div class="col-span-2">{{ item.text }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПРОВЕРЯЕТСЯ МОДЕРАЦИЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.moderate" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПУБЛИКУЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.publish" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПЕРЕПРОВЕРЯЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.check" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>СОБИРАЕТСЯ СТАТИСТИКА</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.stats" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ОТМЕНЯЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.cancel" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "AdminMonitor",

    data() {
        return {
            moment: moment,
            types: {
                1: "создать",
                2: "проверить модерацию",
                3: "опубликовать",
                4: "проверить статус объявления",
                5: "собрать статистику",
                6: "отменить публикацию",
                7: "собрать клиентов и кампании",
                8: "спарсить группы из Маркет платформы",
                9: "проверить erid токен",
            },
        };
    },

    computed: {
        ...mapGetters(["monitor"]),
    },

    methods: {
        ...mapActions(["getMonitor", "newTask", "newTasks", "delTask"]),
    },

    mounted() {
        this.getMonitor();

        setInterval(() => {
            this.getMonitor();
        }, 10000);
    },
};
</script>
