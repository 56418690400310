<template>
    <!-- <div class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded">
        <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer uppercase" @click="pickClient(client)">
                <span class="text-slate-400">ЛОГ</span>
            </div>

            <template v-for="user in users" :key="user.id">
                <div
                    class="flex flex-col items-center cursor-pointer text-xs text-slate-400"
                    :class="{ '!text-cyan-500': currentUser.id == user.id }"
                    @click="currentUser = user"
                >
                    <img
                        v-if="user.picture"
                        :src="'https://api.timekraken.ru/uploads/users/mini/' + user.picture"
                        alt=""
                        class="rounded-full w-8 h-8"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                    <span>{{ user.name }}</span>
                </div>
            </template>
        </div>
    </div> -->

    <!-- <div class="flex justify-between items-center mb-4">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕЙЧАС
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            7 ДНЕЙ
        </div>
        <template v-for="(value, key) in month" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>

        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.end" />
    </div> -->

    <h2 class="w-full text-3xl text-center font-bold">РЕЙТИНГ</h2>

    <div class="w-1/2 m-auto">
        <div v-for="item in total" :key="item" class="w-full rounded-xl h-24 overflow-hidden relative pt-2 text-slate-500 group">
            <div class="absolute bottom-0 z-50">
                <img
                    v-if="item.picture"
                    :src="'https://api.timekraken.ru/uploads/users/u' + item.id + '.png'"
                    :alt="item.name"
                    class="w-full h-[88px] object-contain group-hover:h-24 duration-300"
                />
                <img
                    v-else
                    :src="'https://api.timekraken.ru/uploads/users/u0.png'"
                    :alt="item.name"
                    class="w-full h-[88px] object-contain group-hover:h-24 duration-300 opacity-30"
                />
            </div>

            <div class="bg-white rounded-xl h-20 grid grid-cols-7 justify-center items-center gap-2 mt-4">
                <div></div>
                <div class="text-sm font-light text-center">{{ item.name }}</div>

                <div class="flex items-center gap-1 text-cyan-500 col-span-2">
                    <span class="text-3xl group-hover:text-4xl duration-300 font-bold">{{ parseInt(item.spent).toLocaleString() }}</span>
                    <sup>₽</sup>
                </div>

                <div class="text-center">
                    <div class="text-xl font-light leading-3 mt-2">{{ item.templates }}</div>
                    <span class="text-[8px] text-slate-400 uppercase">Креативы</span>
                </div>
                <div class="text-center">
                    <div class="text-xl font-light leading-3 mt-2">{{ item.publishes }}</div>
                    <span class="text-[8px] text-slate-400 uppercase">Публикации</span>
                </div>
                <div class="text-center">
                    <div class="flex justify-center items-center mt-2">
                        <span class="text-xl font-light leading-3">{{ item.cpa }}</span>
                        <sup>₽</sup>
                    </div>
                    <span class="text-[8px] text-slate-400 uppercase">CPA</span>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="grid grid-cols-4 justify-center items-center gap-8 mt-8">
        <div v-for="item in total" :key="item" class="w-72 h-[500px] bg-white rounded overflow-hidden relative">
            <img
                v-if="item.picture"
                :src="'https://api.timekraken.ru/uploads/users/' + item.picture"
                :alt="item.name"
                class="w-full h-full object-cover"
            />
            <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" :alt="item.name" class="w-full h-full object-cover" />

            <div class="absolute bottom-0 z-50 w-[90%] bg-white rounded m-[5%] p-4">
                <div class="text-2xl font-light text-center mb-4">{{ item.name }}</div>
                <div class="flex justify-between items-center text-center">
                    <div>
                        <div class="text-2xl font-light">{{ item.templates }}</div>
                        <span class="text-xs text-slate-400 uppercase"> Креативы</span>
                    </div>
                    <div>
                        <div class="text-2xl font-light">{{ item.publishes }}</div>
                        <span class="text-xs text-slate-400 uppercase"> Публикации</span>
                    </div>
                    <div>
                        <div class="text-2xl font-light">{{ item.doing }}</div>
                        <span class="text-xs text-slate-400 uppercase"> Действия</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "LogPage",

    data() {
        return {
            types: {
                101: "Список шаблонов",
                102: "Просмотр шаблона",
                103: "Создание шаблона",
                104: "Клонирование шаблона",
                105: "Обновление шаблона",
                107: "Загрузка картинок",
                108: "Удаление картинки",
                109: "Удаление шаблона",
                111: "Создание объявления",
                112: "Публикация объявления",
                119: "Удаление объявления",
            },
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "logs", "users", "statistics"]),

        total() {
            if (!this.logs || !this.statistics || !this.statistics.campaigns) return null;

            let total = [];
            this.users.forEach((user) => {
                let item = {
                    id: user.id,
                    name: user.name,
                    picture: user.picture,
                    templates: 0,
                    publishes: 0,
                    doing: 0,
                    spent: 0,
                    views: 0,
                    clicks: 0,
                    regs: 0,
                    cpm: 0,
                    cpc: 0,
                    cpa: 0,
                };

                this.logs.templates.forEach((template) => {
                    if (template.user_id == user.id && template.date >= this.s.start && template.date <= this.s.end) item.templates += template.count;
                });
                this.logs.publishes.forEach((publish) => {
                    if (publish.user_id == user.id && publish.date >= this.s.start && publish.date <= this.s.end) item.publishes += publish.count;
                });
                this.logs.log.forEach((lo) => {
                    if (lo.user_id == user.id && lo.date >= this.s.start && lo.date <= this.s.end) item.doing += lo.count;
                });

                //&& statistics.date >= this.s.start && statistics.date <= this.s.end
                this.statistics.campaigns.forEach((statistics) => {
                    if (user.id == statistics.user_id) {
                        if (statistics.spent > 0) item.spent += parseInt(statistics.spent);
                        if (statistics.views > 0) item.views += parseInt(statistics.views);
                        if (statistics.clicks > 0) item.clicks += parseInt(statistics.clicks);
                        if (statistics.regs > 0) item.regs += parseInt(statistics.regs);
                    }
                });

                if (item.views > 0) item.cpm = parseInt((item.spent / item.views) * 1000);
                if (item.clicks > 0) item.cpc = parseInt(item.spent / item.clicks);
                if (item.regs > 0) item.cpa = parseInt(item.spent / item.regs);

                if (item.spent > 0) total.push(item);
            });

            total.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));

            return total;
        },
    },

    methods: {
        ...mapActions(["getStatistics", "getLogs", "setMonth", "setPeriod", "setSettings"]),
    },

    mounted() {
        this.getStatistics();
        this.getLogs();
    },
};
</script>
