import API from "../../services/api";

export default {
    state: {
        logs: [],
        logsF: [],

        statistics: [],

        groups: [],
    },

    mutations: {
        setLogs(state, logs) {
            state.logs = logs;
            state.logsF = logs;
        },
        setStatistics(state, statistics) {
            state.statistics = statistics;
        },
        setGroups(state, groups) {
            state.groups = groups;
        },
    },

    actions: {
        getLogs(ctx) {
            API.GET("log/list").then((res) => {
                console.log("getLogs", res.data);
                ctx.commit("setLogs", res.data.logs);
                ctx.commit("setUsers", res.data.users);
            });
        },

        // МОНИТОР TASKS
        getGroups(ctx) {
            API.GET("log/groups").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setGroups", res.data.groups);
            });
        },

        // STAT
        getStatistics(ctx) {
            // ctx.dispatch("loadSettings");

            API.GET("market/stat/list").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setStatistics", res.data.statistics);
            });
        },
    },

    getters: {
        logs(state) {
            return state.logsF;
        },
        statistics(state) {
            return state.statistics;
        },
        groups(state) {
            return state.groups;
        },
    },
};
