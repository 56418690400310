<template>
    <template v-if="template && template?.id > 0">
        <div class="flex justify-between items-center h-24 py-4 gap-4 sticky top-12 -mt-4 bg-slate-100 z-30">
            <div
                class="flex justify-center items-center aspect-square h-16 bg-white text-cyan-500 hover:bg-cyan-500 hover:text-white rounded cursor-pointer transition"
                @click="pickCampaign(campaign), $router.push('/campaign')"
            >
                <IconBack :size="5" />
            </div>

            <div v-if="checkedIds?.length > 0" class="flex justify-center items-center gap-4 px-4 text-sm w-full text-center h-16 bg-white">
                ВЫБРАНО: <span class="font-bold text-sky-500">{{ checkedIds?.length }}&nbsp;шт</span>&nbsp;на
                <span class="font-bold text-sky-500">{{ sum }}&nbsp;₽</span>
                <div
                    class="btn px-2 py-1 w-full"
                    @click="publishAll({ ids: checkedIds, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                >
                    Разместить все
                </div>

                <input type="date" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="date_from" />
                <input type="date" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="date_to" />
                <input type="time" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="time_from" />
                <input type="time" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="time_to" />
            </div>

            <div v-else-if="dataAds.total" class="flex justify-between items-center w-full text-center h-16 bg-white px-4 rounded uppercase">
                <div>
                    <div class="text-xl leading-4 pt-2 text-violet-500 flex justify-center items-start pl-4">
                        {{ empty }} <sup class="text-[5px]">new</sup>
                    </div>
                    <span class="text-[8px] text-violet-400">ПУСТЫШКИ</span>
                </div>
                <div>
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.publishes.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">РАЗМЕЩЕНИЯ</span>
                </div>

                <div>
                    <div class="text-xl text-yellow-600 leading-4 pt-2">{{ dataAds.total.book.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">БРОНЬ</span>
                </div>
                <div>
                    <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataAds.total.work.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
                </div>

                <div class="w-px h-full bg-slate-200"></div>

                <div>
                    <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataAds.total.spent.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                </div>

                <div v-if="!s.compact">
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.views.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
                </div>
                <div>
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.clicks.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">КЛИКИ</span>
                </div>
                <div>
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.regs.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                </div>

                <div class="w-px h-full bg-slate-200"></div>

                <div v-if="!s.compact">
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.cpm.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">CPM</span>
                </div>
                <div v-if="!s.compact">
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.cpc.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">CPC</span>
                </div>
                <div>
                    <div class="text-xl leading-4 pt-2">{{ dataAds.total.cpa.toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">CPA</span>
                </div>
            </div>

            <div
                class="flex justify-center items-center aspect-square h-16 bg-teal-500 text-white rounded cursor-pointer"
                @click="setPopup('editTemplate')"
            >
                <IconEdit :size="5" />
            </div>
            <div
                class="flex justify-center items-center aspect-square h-16 bg-sky-500 text-white rounded cursor-pointer"
                @click="setPopup('settingTemplate')"
            >
                <IconSetting :size="5" />
            </div>
            <div
                class="flex justify-center items-center aspect-square h-16 bg-violet-500 text-white rounded cursor-pointer"
                @click="setPopup('generate')"
            >
                <IconPlus :size="6" />
            </div>
        </div>

        <div class="flex justify-between items-center h-10 mb-4 gap-4">
            <div
                class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase cursor-pointer"
                @click="pickClient(client), $router.push('/client')"
            >
                {{ client?.name }}
            </div>
            <div
                class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase cursor-pointer"
                @click="pickCampaign(campaign), $router.push('/campaign')"
            >
                {{ campaign?.name }}
            </div>
            <div class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase">
                {{ template?.name }}
            </div>

            <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
                @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
            >
                ВСЕГО
            </div>
            <!-- <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().add(1, 'days').format('YYYY-MM-DD') && s.end == moment().add(1, 'days').format('YYYY-MM-DD'),
                }"
                @click="setPeriod({ start: moment().add(1, 'days').format('YYYY-MM-DD'), end: moment().add(1, 'days').format('YYYY-MM-DD') })"
            >
                ЗАВТРА
            </div> -->
            <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
                @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
            >
                СЕГОДНЯ
            </div>
            <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }"
                @click="
                    setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })
                "
            >
                ВЧЕРА
            </div>
            <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
            >
                НЕДЕЛЯ
            </div>
            <div
                class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
                :class="{
                    '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
            >
                МЕСЯЦ
            </div>

            <!-- <input type="text" class="input" /> -->

            <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.start" />

            <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.end" />

            <div class="relative flex justify-end items-center group p-1 text-xs text-slate-400 cursor-pointer" @click="setCompact()">
                <input
                    type="checkbox"
                    :checked="s.compact"
                    class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                />
                <span
                    class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                ></span>
                Компактно
            </div>
        </div>

        <template v-if="!s.request">
            <div class="border border-yellow-500 mt-8 rounded">
                <div
                    class="w-full bg-yellow-500 text-white flex justify-center items-center gap-2 p-2 rounded-t cursor-pointer"
                    @click="hide.book = !hide.book"
                >
                    <span class="letter bg-white text-yellow-500">{{ dataAds.book?.length }}</span>
                    <span>БРОНЬ</span>
                </div>
                <template v-if="!hide.book">
                    <div
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 py-2 text-xs text-slate-400 border-b border-slate-200"
                        :class="{ '!grid-cols-14': !s.compact }"
                    >
                        <div class="cursor-pointer col-span-4" @click="setOrder('name')">ГРУППА &#8597;</div>

                        <div class="cursor-pointer" @click="setOrder('price')">ЦЕНА &#8597;</div>

                        <div class="text-center cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

                        <div class="col-span-2 cursor-pointer text-yellow-600 text-center" @click="setOrder('last_publish_at')">
                            БУДЕТ РАЗМЕЩЕНО &#8597;
                        </div>
                    </div>

                    <div
                        v-for="item in dataAds.book"
                        :key="item.id"
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 text-sm border-b border-slate-200 last:rounded-b"
                        :class="{ '!grid-cols-14': !s.compact }"
                    >
                        <div class="flex items-center gap-4 col-span-4">
                            <div v-if="item.group?.photo_100" class="flex justify-center items-center gap-4 shrink-0">
                                <img
                                    :src="item.group?.photo_100"
                                    class="shrink rounded w-10 h-10 cursor-pointer"
                                    @click="pickAd(item), setPopup('viewAd')"
                                />
                            </div>

                            <img
                                v-else
                                src="https://api.timekraken.ru/uploads/nophoto.jpg"
                                @click="pickAd(item), setPopup('viewAd')"
                                class="shrink-0 rounded w-10 h-10"
                            />
                            <div class="flex flex-col">
                                <!-- <div class="text-xs text-slate-400">{{ item.id }} {{ item.link }}</div> -->
                                <div class="flex flex-col cursor-pointer leading-4" @click="pickAd(item), setPopup('viewAd')">
                                    <span class="text-[9px] text-slate-400">{{ item.link }}</span>
                                    {{ item.group?.name }}
                                </div>
                            </div>
                        </div>

                        <div class="text-lg text-cyan-500 font-bold tabular-nums">{{ item.group.cost }}</div>

                        <div class="text-center font-bold p-4 border-l border-slate-200">{{ item.spent.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.views.toLocaleString() }}</div>
                        <div class="text-center">{{ item.clicks.toLocaleString() }}</div>
                        <div class="text-center p-4 border-r border-slate-200">{{ item.regs.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.cpm.toLocaleString() }}</div>
                        <div v-if="!s.compact" class="text-center">{{ item.cpc.toLocaleString() }}</div>
                        <!-- <div class="text-center">{{ item.cpa.toLocaleString() }}</div> -->

                        <div class="flex justify-center items-center py-2 gap-2 relative" :class="{ '!text-pink-600': item.cpa > kpi }">
                            <IconWarning :size="3" />
                            {{ item.cpa.toLocaleString() }}
                            <div class="text-[7px] absolute bottom-0" v-if="item.cpa > kpi">неэффективно</div>
                        </div>

                        <div
                            class="grid grid-cols-6 col-span-2 gap-2 justify-center items-center text-center text-yellow-600 h-full border-l border-slate-200"
                        >
                            <template v-if="item.status == 4">
                                <div class="col-span-5 w-full flex justify-center items-center text-[12px]">Размещается</div>
                                <div><IconRocket /></div>
                            </template>

                            <template v-if="item.status == 5">
                                <div class="col-span-5 w-full flex justify-center items-center text-[12px]">
                                    <template v-if="item.last_publish_at">
                                        <DayAd :date="item.last_publish_at" /> в {{ moment(item.last_publish_at).format("H:mm") }}
                                    </template>
                                </div>
                                <div><IconClock /></div>
                            </template>

                            <!-- <div class="text-yellow-600 hover:text-red-600 cursor-pointer" @click="stopAd(item.id)"><IconStop /></div> -->
                        </div>
                    </div>
                </template>
            </div>

            <div class="border border-teal-500 mt-8 rounded">
                <div
                    class="w-full bg-teal-500 text-white flex justify-center items-center gap-2 p-2 rounded-t cursor-pointer"
                    @click="hide.work = !hide.work"
                >
                    <span class="letter bg-white text-teal-500">{{ dataAds.work?.length }}</span>
                    <span>В РАБОТЕ</span>
                </div>
                <template v-if="!hide.work">
                    <div
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 py-2 text-xs text-slate-400 border-b border-slate-200"
                        :class="{ '!grid-cols-14': !s.compact }"
                    >
                        <div class="cursor-pointer col-span-4" @click="setOrder('name')">ГРУППА &#8597;</div>

                        <div class="cursor-pointer" @click="setOrder('price')">ЦЕНА &#8597;</div>

                        <div class="text-center cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

                        <div class="col-span-2 cursor-pointer text-teal-500 text-center" @click="setOrder('last_publish_at')">АКТИВНО &#8597;</div>
                    </div>

                    <div
                        v-for="item in dataAds.work"
                        :key="item.id"
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 text-sm border-b border-slate-200 last:rounded-b"
                        :class="{ '!grid-cols-14': !s.compact }"
                    >
                        <div class="flex items-center gap-4 col-span-4">
                            <div v-if="item.group?.photo_100" class="flex justify-center items-center gap-4 shrink-0">
                                <img
                                    :src="item.group?.photo_100"
                                    class="shrink rounded w-10 h-10 cursor-pointer"
                                    @click="pickAd(item), setPopup('viewAd')"
                                />
                            </div>

                            <img
                                v-else
                                src="https://api.timekraken.ru/uploads/nophoto.jpg"
                                @click="pickAd(item), setPopup('viewAd')"
                                class="shrink-0 rounded w-10 h-10"
                            />
                            <div class="flex flex-col">
                                <!-- <div class="text-xs text-slate-400">{{ item.id }} {{ item.link }}</div> -->
                                <div class="flex flex-col cursor-pointer leading-4" @click="pickAd(item), setPopup('viewAd')">
                                    <span class="text-[9px] text-slate-400">{{ item.link }}</span>
                                    {{ item.group?.name }}
                                </div>
                            </div>
                        </div>

                        <div class="text-lg text-cyan-500 font-bold tabular-nums">{{ item.group.cost }}</div>

                        <div class="text-center font-bold p-4 border-l border-slate-200">{{ item.spent.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.views.toLocaleString() }}</div>
                        <div class="text-center">{{ item.clicks.toLocaleString() }}</div>
                        <div class="text-center p-4 border-r border-slate-200">{{ item.regs.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.cpm.toLocaleString() }}</div>
                        <div v-if="!s.compact" class="text-center">{{ item.cpc.toLocaleString() }}</div>
                        <!-- <div class="text-center">{{ item.cpa.toLocaleString() }}</div> -->

                        <div class="flex justify-center items-center py-2 gap-2 relative" :class="{ '!text-pink-600': item.cpa > kpi }">
                            <IconWarning :size="3" />
                            {{ item.cpa.toLocaleString() }}
                            <div class="text-[7px] absolute bottom-0" v-if="item.cpa > kpi">неэффективно</div>
                        </div>

                        <div
                            class="grid grid-cols-6 col-span-2 gap-2 justify-center items-center text-center text-teal-500 h-full border-l border-slate-200"
                        >
                            <template v-if="item.status == 7">
                                <div class="col-span-5 w-full flex justify-center items-center text-[12px]">
                                    <template v-if="item.last_publish_at">
                                        <DayAd :date="item.last_publish_at" /> в {{ moment(item.last_publish_at).format("H:mm") }}
                                    </template>
                                </div>
                                <div><IconOk /></div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <!-- <div class="grid grid-cols-12 items-center bg-white gap-8 p-2 mt-4 text-xs text-slate-400 border-b border-slate-200">
                    <div class="flex gap-2">
                        <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">
                            <input type="checkbox" id="checkAll" class="mychbox" @click="checkAllAds()" />
                            <label for="checkAll"></label>
                        </div>
                        <div class="text-black font-bold">СВОБОДНЫ {{ dataAds.free?.length }}</div>
                    </div>

                    <div v-if="checkedIds?.length > 0" class="flex justify-center items-center gap-2 col-span-3">
                        ВЫБРАНО: <span class="font-bold text-cyan-500">{{ checkedIds?.length }} шт</span> на
                        <span class="font-bold text-cyan-500">{{ sum }} ₽</span>
                        <div
                            class="btn btn-green px-3 py-0"
                            @click="publishAll({ ids: checkedIds, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                        >
                            Разместить все
                        </div>
                    </div>

                    <template v-else>
                        <div class="cursor-pointer col-span-3" @click="setOrder('name')">ГРУППА &#8597;</div>
                    </template>

                    <div>ССЫЛКА ЦЕНА</div>
                    <div class="grid grid-cols-7 text-center col-span-5">
                        <div class="cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
                        <div v-if="!s.compact" class="cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
                        <div class="cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
                        <div class="cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                        <div v-if="!s.compact" class="cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
                        <div v-if="!s.compact" class="cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
                        <div class="cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>
                    </div>
                </div> -->

            <div v-if="template.ads?.length > 0" class="border border-sky-500 mt-8 rounded">
                <div
                    class="w-full bg-sky-500 text-white grid grid-cols-3 justify-center items-center gap-2 p-2 rounded-t cursor-pointer"
                    @click="hide.free = !hide.free"
                >
                    <div></div>
                    <div class="flex justify-center gap-2">
                        <span class="letter bg-white text-sky-500">{{ dataAds.free?.length }}</span>
                        <span>СВОБОДНЫ</span>
                    </div>
                    <div class="flex gap-2 ml-auto text-sm">
                        <div>c {{ moment(date_from).format("DD.MM") }} {{ time_from }}</div>
                        <div>по {{ moment(date_to).format("DD.MM") }} {{ time_to }}</div>
                    </div>
                </div>
                <template v-if="!hide.free">
                    <div
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 py-2 text-xs text-slate-400 border-b border-slate-200"
                        :class="{ '!grid-cols-14': !s.compact }"
                    >
                        <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400">
                            <input type="checkbox" id="checkAll" class="mychbox" @click="checkAllAds()" />
                            <label for="checkAll"></label>
                        </div>

                        <!-- <div v-if="checkedIds?.length > 0" class="flex justify-center items-center gap-4 text-sm col-span-7">
                        ВЫБРАНО: <span class="font-bold text-sky-500">{{ checkedIds?.length }}&nbsp;шт</span>&nbsp;на
                        <span class="font-bold text-sky-500">{{ sum }}&nbsp;₽</span>
                        <div
                            class="btn px-2 py-1 w-full"
                            @click="publishAll({ ids: checkedIds, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                        >
                            Разместить все
                        </div>

                        <input type="date" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="date_from" />
                        <input type="date" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="date_to" />
                        <input type="time" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="time_from" />
                        <input type="time" class="w-full h-7 px-2 py-1 text-sm bg-slate-100 text-slate-500 rounded" v-model="time_to" />
                    </div> -->

                        <div class="cursor-pointer col-span-3" @click="setOrder('name')">ГРУППА &#8597;</div>

                        <div class="cursor-pointer" @click="setOrder('price')">ЦЕНА &#8597;</div>

                        <div class="text-center cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
                        <div v-if="!s.compact" class="text-center cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
                        <div class="text-center cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

                        <div class="col-span-2 cursor-pointer text-sky-500 text-center" @click="setOrder('last_publish_at')">
                            ПОСЛЕДНЕЕ РАЗМЕЩЕНИЕ &#8597;
                        </div>
                    </div>

                    <div
                        v-for="item in dataAds.free"
                        :key="item.id"
                        class="grid grid-cols-11 items-center bg-white gap-4 px-4 text-sm border-b border-slate-200 last:rounded-b"
                        :class="{
                            '!bg-cyan-100 !text-cyan-700': this.checkedIds.includes(item.id),
                            '!text-slate-300': item.status == 10,
                            '!grid-cols-14': !s.compact,
                        }"
                    >
                        <div class="flex items-center gap-4 col-span-4">
                            <div class="flex items-center gap-4 shrink-0" v-if="item.group?.photo_100">
                                <input
                                    type="checkbox"
                                    :id="item.id"
                                    :value="item.id"
                                    :disabled="![3, 6].includes(item.status)"
                                    class="mychbox"
                                    v-model="checkedIds"
                                />

                                <img
                                    :src="item.group?.photo_100"
                                    class="shrink rounded w-10 h-10 cursor-pointer"
                                    @click="pickAd(item), setPopup('viewAd')"
                                />
                            </div>

                            <img
                                v-else
                                src="https://api.timekraken.ru/uploads/nophoto.jpg"
                                @click="pickAd(item), setPopup('viewAd')"
                                class="shrink-0 rounded w-10 h-10"
                            />
                            <div class="flex flex-col">
                                <!-- <div class="text-xs text-slate-400">{{ item.id }} {{ item.link }}</div> -->
                                <div class="flex flex-col cursor-pointer leading-4" @click="pickAd(item), setPopup('viewAd')">
                                    <span class="text-[9px] text-slate-400">{{ item.link }}</span>
                                    {{ item.group?.name }}
                                </div>
                            </div>
                        </div>

                        <div class="text-lg text-cyan-500 font-bold tabular-nums">{{ item.group.cost }}</div>

                        <div class="text-center font-bold p-4 border-l border-slate-200">{{ item.spent.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.views.toLocaleString() }}</div>
                        <div class="text-center">{{ item.clicks.toLocaleString() }}</div>
                        <div class="text-center p-4 border-r border-slate-200">{{ item.regs.toLocaleString() }}</div>

                        <div v-if="!s.compact" class="text-center">{{ item.cpm.toLocaleString() }}</div>
                        <div v-if="!s.compact" class="text-center">{{ item.cpc.toLocaleString() }}</div>
                        <!-- <div class="text-center">{{ item.cpa.toLocaleString() }}</div> -->

                        <div class="flex justify-center items-center py-2 gap-2 relative" :class="{ '!text-pink-600': item.cpa > kpi }">
                            <IconWarning :size="3" />
                            {{ item.cpa.toLocaleString() }}
                            <div class="text-[7px] absolute bottom-0" v-if="item.cpa > kpi">неэффективно</div>
                        </div>

                        <div
                            class="grid grid-cols-4 col-span-2 gap-2 justify-center items-center text-center text-sky-500 h-full border-l border-slate-200"
                        >
                            <template v-if="item.status == 3">
                                <div class="col-span-3 w-full flex justify-center items-center text-xs">
                                    <span v-if="item.last_publish_at"> {{ moment(item.last_publish_at).format("DD.MM.YYYY в H:mm") }}</span>
                                </div>
                                <div
                                    class="btn aspect-square w-10 h-10 p-0 text-white"
                                    @click="
                                        publishAd({ id: item.id, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })
                                    "
                                >
                                    <IconRocket :size="5" />
                                </div>
                            </template>

                            <template v-if="item.status == 6">
                                <div class="col-span-3 w-full flex flex-col justify-center items-center text-xs">
                                    <span class="text-slate-400">Время не найдено</span>
                                    <span v-if="item.last_publish_at"> {{ moment(item.last_publish_at).format("DD.MM.YYYY в H:mm") }}</span>
                                </div>
                                <div
                                    class="btn aspect-square w-10 h-10 p-0 text-white"
                                    @click="
                                        publishAd({ id: item.id, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })
                                    "
                                >
                                    <IconRocket :size="5" />
                                </div>
                            </template>

                            <template v-if="item.status == 10">
                                <div class="col-span-3 w-full flex justify-center items-center text-xs text-red-500">Админ заблокировал</div>
                            </template>

                            <template v-if="item.status == 9">
                                <div class="col-span-3 w-full flex justify-center items-center text-xs text-red-500">Отклонено модерацией</div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <!-- <div
                    class="flex justify-between items-center w-full bg-white gap-4 p-4 mt-8 text-center border-b border-slate-200 text-sm text-slate-500"
                >
                    <div class="w-6"></div>

                    <div class="flex items-center gap-2">
                        ПУСТЫШКИ <span class="count">{{ empty }}</span>
                    </div>

                    <div class="flex gap-4">
                        <div class="text-teal-500 cursor-pointer" @click="setPopup('addGroups')"><IconList :size="5" /></div>
                        <div class="text-teal-500 cursor-pointer" @click="setPopup('generate')"><IconPlus :size="5" /></div>
                    </div>
                </div> -->

            <!-- <template v-for="item in template.ads" :key="item.id">
                    <div v-if="!item.group_id" class="grid grid-cols-12 items-center bg-white gap-8 p-2 text-sm border-b border-slate-200">
                        <img src="https://api.timekraken.ru/uploads/nophoto.jpg" class="shrink-0 rounded w-10 h-10" />

                        <div class="flex flex-col col-span-3 cursor-pointer">
                            <div class="text-xs text-slate-400">{{ item.id }} {{ item.link }}</div>
                        </div>

                        <div class="flex flex-col col-span-6"></div>

                        <div class="flex justify-end gap-4 col-span-2">
                            <div class="text-center" v-if="item.status == 1">
                                <div
                                    class="btn btn-green"
                                    @click="
                                        createAd({
                                            id: item.id,
                                            date_from: date_from,
                                            date_to: date_to,
                                            time_from: time_from,
                                            time_to: time_to,
                                        })
                                    "
                                >
                                    Создать в VK
                                </div>
                            </div>

                            <div v-if="item.approved == 1">
                                <div @click="setPopup('addGroups')" class="text-slate-400 text-xs cursor-pointer">Назначить группу</div>
                            </div>

                            <div class="text-center" v-if="item.approved == 0">
                                <div v-if="item.status == 2" class="text-slate-500 text-xs">Отправляется в VK...</div>
                                <div v-if="item.status == 3" class="text-sky-500 text-xs">На модерации...</div>
                            </div>

                            <div class="text-red-600 text-xs" v-if="item.status == 9">Отклонено модерацией</div>
                        </div>
                    </div>
                </template> -->

            <div v-else class="flex flex-col items-center bg-white p-8 text-center">
                <h2 class="mb-2">СОЗДАТЬ ОБЪЯВЛЕНИЯ</h2>
                <p class="text-slate-400">Создать копии объявлений и отправить на модерацию</p>
                <p class="text-slate-400">Если это новый креатив, сначала создайте 1-2 и пройдите модерацию!</p>
                <p class="text-slate-400">После этого действия редактировать креатив будет нельзя! Только клонировать</p>

                <div class="flex justify-center w-72 gap-2 my-4">
                    <input type="text" class="input h-10" placeholder="Сколько" v-model="count" />

                    <div class="btn btn-green shrink-0 cursor-pointer gap-2" @click="generateAds(count)">
                        <IcoBig name="plus" :size="5" /> Создать
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="flex justify-center items-center h-screen w-full text-sky-400"><IconLoader :size="30" /></div>

        <template v-if="s.popup == 'viewAd' && ad?.id > 0"><ViewAd /></template>

        <template v-if="s.popup == 'settingTemplate' && template?.id"><SettingTemplate /></template>

        <template v-if="s.popup == 'editTemplate' && template?.id"><EditTemplate /></template>

        <template v-if="s.popup == 'delete' && template?.id"><DeleteTemplate /></template>

        <template v-if="s.popup == 'delAd'">
            <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
                <h3 class="text-center text-xl py-4 bg-red-500 text-white">АРХИВИРОВАТЬ ОБЪЯВЛЕНИЕ</h3>

                <div class="flex flex-col p-10 text-center">
                    <p>Вы точно хотите архивировать объявление для группы</p>

                    <p class="my-4 font-bold text-2xl">"{{ currentAd.group?.name }}"</p>

                    <p>Его можно будет восстановить!</p>

                    <div class="flex justify-center mt-8">
                        <div class="btn btn-red py-2 px-6" @click="delAd(currentAd), closePopup()">Архивировать</div>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="s.popup == 'generate' && template?.id">
            <div class="flex gap-4 w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50">
                <div class="flex flex-col w-1/2 relative bg-white shadow-lg">
                    <h3 class="text-center text-xl py-4 bg-violet-500 text-white">СОЗДАТЬ ОБЪЯВЛЕНИЯ</h3>

                    <div class="flex flex-col justify-center items-center w-full p-10 text-center">
                        <p class="text-slate-400 text-center text-sm mb-2">Укажите количество объявлений</p>
                        <p class="text-slate-400 text-center text-sm mb-4">Если это новый креатив, сначала создайте 1-2 и пройдите модерацию!</p>

                        <div class="text-violet-500">СЕЙЧАС В ОЧЕРЕДИ {{ queue }}</div>
                        <div class="text-violet-500">ДЛЯ ЭТОГО КРЕАТИВА СОЗДАЕТСЯ {{ template.creating }}</div>

                        <div class="flex flex-col justify-center w-60">
                            <input type="text" class="input h-10 my-4" placeholder="Сколько" v-model="count" />

                            <div
                                class="btn bg-violet-500 hover:bg-violet-400 shrink-0 cursor-pointer gap-2"
                                @click="generateAds(count), closePopup()"
                            >
                                <IcoBig name="plus" :size="5" /> Создать
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col w-1/2 relative bg-white shadow-lg">
                    <h3 class="text-center text-xl py-4 bg-violet-500 text-white">НАЗНАЧИТЬ ГРУППЫ</h3>

                    <div class="flex flex-col justify-center items-center w-full p-10 text-center">
                        <p class="text-slate-400 text-sm text-center mb-4">Укажите список групп для размещения объявлений, максимум {{ empty }}</p>

                        <textarea class="w-full h-60 py-1 px-3 mb-4 text-sm bg-slate-100 rounded" v-model="group_ids"></textarea>

                        <div
                            class="btn bg-violet-500 hover:bg-violet-400 shrink-0 cursor-pointer gap-2"
                            @click="attachGroups(group_ids), closePopup()"
                        >
                            <IconOk :size="5" /> Сохранить
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="s.popup == 'addGroups' && template?.id">
            <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
                <h3 class="text-center text-xl py-4 bg-violet-500 text-white">НАЗНАЧИТЬ ГРУППЫ</h3>

                <div class="flex flex-col justify-center items-center w-full p-10 text-center">
                    <p class="text-slate-400 text-center mb-4">Укажите список групп для размещения объявлений, максимум {{ empty }}</p>

                    <textarea class="w-full h-60 py-1 px-3 mb-4 text-sm bg-slate-100 rounded" v-model="group_ids"></textarea>

                    <div class="btn btn-green shrink-0 cursor-pointer gap-2" @click="attachGroups(group_ids), closePopup()">
                        <IconOk :size="5" /> Сохранить
                    </div>
                </div>
            </div>
        </template>

        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-40 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import tz from "moment-timezone";

import ViewAd from "../components/ViewAd.vue";

export default {
    name: "TemplatePage",

    components: { ViewAd },

    data() {
        return {
            checkedIds: [],
            checkAll: false,
            currentAd: {},
            hide: { book: false, work: false, free: false },
            date_from: moment().format("YYYY-MM-DD"),
            date_to: moment().format("YYYY-MM-DD"),
            time_from: moment().format("00:00"), //.add(6, "hours")
            time_to: moment().format("23:59"),
            count: 0,
            group_ids: "",
            moment: moment,
            tz: tz,
            kpi: 500,
        };
    },

    computed: {
        ...mapGetters(["s", "client", "campaign", "template", "queue", "ad", "statAd"]),

        // сумма выбранных объявлений
        sum() {
            let sum = 0;

            this.template?.ads?.forEach((item) => {
                if (this.checkedIds.includes(item.id)) sum += item.price;
            });

            return sum;
        },

        empty() {
            let result = 0;

            this.template?.ads?.forEach((item) => {
                if (!item.group_id && item.status == 3) result++;
            });

            return result;
        },

        dataAds() {
            if (!this.template.ads || !this.statAd) return { ads: null, total: null };

            let total = { publishes: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            let activeAds = this.template.ads.filter((ad) => ad.group_id > 0);

            let ads = { book: [], work: [], free: [], block: [] };
            activeAds.forEach((ad) => {
                let data = { likes: 0, reposts: 0, comments: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
                this.statAd.forEach((stat) => {
                    if (stat.ad_id == ad.id && stat.date >= this.s.start && stat.date <= this.s.end) {
                        if (stat.likes > 0) data.likes += stat.likes;
                        if (stat.reposts > 0) data.reposts += stat.reposts;
                        if (stat.comments > 0) data.comments += stat.comments;
                        if (stat.spent > 0) data.spent += stat.spent;
                        if (stat.views > 0) data.views += stat.views;
                        if (stat.clicks > 0) data.clicks += stat.clicks;
                        if (stat.regs > 0) data.regs += stat.regs;
                    }
                });

                ad.publishes_count = ad.publishes?.length;
                ad.likes = data.likes;
                ad.reposts = data.reposts;
                ad.comments = data.comments;
                ad.spent = data.spent;
                ad.views = data.views;
                ad.clicks = data.clicks;
                ad.regs = data.regs;
                ad.cpm = data.cpm;
                ad.cpc = data.cpc;
                ad.cpa = data.cpa;

                if (ad.views) ad.cpm = parseInt(ad.spent / (ad.views / 1000));
                if (ad.clicks) ad.cpc = parseInt(ad.spent / ad.clicks);
                if (ad.regs) ad.cpa = parseInt(ad.spent / ad.regs);

                total.publishes += ad.publishes?.length;
                total.book += ad.book;
                total.work += ad.work;
                total.spent += data.spent;
                total.views += data.views;
                total.clicks += data.clicks;
                total.regs += data.regs;

                if (ad.status == 4) ads.book.push(ad);
                if (ad.status == 5) ads.book.push(ad);
                if (ad.status == 7) ads.work.push(ad);
                if (ad.status == 3) ads.free.push(ad);
                if (ad.status == 6) ads.free.push(ad);
                if (ad.status == 10) ads.block.push(ad);
            });

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.group.name > b.group.name ? 1 : b.group.name > a.group.name ? -1 : 0));
                    ads.work.sort((a, b) => (a.group.name > b.group.name ? 1 : b.group.name > a.group.name ? -1 : 0));
                    ads.free.sort((a, b) => (a.group.name > b.group.name ? 1 : b.group.name > a.group.name ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.group.name < b.group.name ? 1 : b.group.name < a.group.name ? -1 : 0));
                    ads.work.sort((a, b) => (a.group.name < b.group.name ? 1 : b.group.name < a.group.name ? -1 : 0));
                    ads.free.sort((a, b) => (a.group.name < b.group.name ? 1 : b.group.name < a.group.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "price") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.group.cost > b.group.cost ? 1 : b.group.cost > a.group.cost ? -1 : 0));
                    ads.work.sort((a, b) => (a.group.cost > b.group.cost ? 1 : b.group.cost > a.group.cost ? -1 : 0));
                    ads.free.sort((a, b) => (a.group.cost > b.group.cost ? 1 : b.group.cost > a.group.cost ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.group.cost < b.group.cost ? 1 : b.group.cost < a.group.cost ? -1 : 0));
                    ads.work.sort((a, b) => (a.group.cost < b.group.cost ? 1 : b.group.cost < a.group.cost ? -1 : 0));
                    ads.free.sort((a, b) => (a.group.cost < b.group.cost ? 1 : b.group.cost < a.group.cost ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                    ads.work.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                    ads.free.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                    ads.work.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                    ads.free.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                    ads.work.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                    ads.free.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                    ads.work.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                    ads.free.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                    ads.work.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                    ads.free.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                    ads.work.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                    ads.free.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                    ads.work.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                    ads.free.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                    ads.work.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                    ads.free.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    ads.book.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                    ads.work.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                    ads.free.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (this.s.order_name == "last_publish_at") {
                if (this.s.order) {
                    ads.book.sort((a, b) => (a.last_publish_at > b.last_publish_at ? 1 : b.last_publish_at > a.last_publish_at ? -1 : 0));
                    ads.work.sort((a, b) => (a.last_publish_at > b.last_publish_at ? 1 : b.last_publish_at > a.last_publish_at ? -1 : 0));
                    ads.free.sort((a, b) => (a.last_publish_at > b.last_publish_at ? 1 : b.last_publish_at > a.last_publish_at ? -1 : 0));
                    ads.free.sort((a, b) => {
                        const dateA = a.last_publish_at || "2000-01-01 00:00:00";
                        const dateB = b.last_publish_at || "2000-01-01 00:00:00";
                        return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
                    });
                } else {
                    ads.book.sort((a, b) => (a.last_publish_at < b.last_publish_at ? 1 : b.last_publish_at < a.last_publish_at ? -1 : 0));
                    ads.work.sort((a, b) => (a.last_publish_at < b.last_publish_at ? 1 : b.last_publish_at < a.last_publish_at ? -1 : 0));

                    ads.free.sort((a, b) => {
                        const dateA = a.last_publish_at || "2000-01-01 00:00:00";
                        const dateB = b.last_publish_at || "2000-01-01 00:00:00";
                        return dateA < dateB ? 1 : dateB < dateA ? -1 : 0;
                    });
                }
            }

            if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
            if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

            return { ...ads, total: total };
        },
    },

    watch: {
        template() {
            if (this.template) {
                this.template.date_from = moment().format("YYYY-MM-DD");
                this.template.date_to = moment().format("YYYY-MM-DD");
                this.template.time_from = moment().format("00:00"); //.add(6, "hours")
                this.template.time_to = moment().format("23:59");
            }
        },
    },

    methods: {
        ...mapActions([
            "getOneTemplate",
            "setSettings",
            "setPeriod",
            "setOrder",
            "setPopup",
            "setCompact",
            "closePopup",
            "saveTemplate",
            "delTemplate",
            "removeTemplate",
            "uploadImg",
            "delPicture",
            "pickAd",
            "createAd",
            "generateAds",
            "publishAd",
            "publishAll",
            "stopAd",
            "delAd",
            "pickClient",
            "pickCampaign",
            "attachGroups",
        ]),

        checkAllAds() {
            if (this.checkAll) {
                this.checkedIds = [];
                this.checkAll = false;
            } else {
                this.checkedIds = this.template.ads.filter((item) => [3, 6].includes(item.status) && item.group_id > 0).map((item) => item.id);
                this.checkAll = true;
            }
        },
    },

    mounted() {
        this.getOneTemplate();

        if (this.template?.id && !this.template.name) this.setPopup("editTemplate");
    },
};
</script>
