<template>
    <div class="flex justify-between items-center bg-white p-4 mb-4 rounded">
        <div class="cursor-pointer text-sky-500" @click="removeTemplate(), (s.view = 'null')"><IconBack :size="6" /></div>
        <h2 class="uppercase text-center text-slate-500">СТАТИСТИКА</h2>
    </div>

    <div class="flex gap-4">
        <div class="w-1/3 bg-white flex flex-col rounded">
            <div class="sticky top-36">
                <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500">ШАБЛОН: {{ template.name }}</h2>
                <div class="grid gap-2 p-4">
                    <!-- <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Название шаблона</label>
                        <input type="text" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="template.name" />
                    </div> -->

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Текст объявления</label>
                        <textarea class="w-full h-96 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
                    </div>

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Картинка объявления</label>

                        <div class="w-full bg-slate-100 relative">
                            <input type="file" id="image" class="absolute invisible opacity-0" @change="uploadImg({ e: $event, id: template.id })" />

                            <img
                                v-if="template.picture"
                                class="h-48 rounded object-cover overflow-hidden"
                                :src="'https://vk.studiobaraban.ru/uploads/templates/' + template.picture"
                            />
                            <img v-else class="w-full rounded object-cover overflow-hidden" src="https://vk.studiobaraban.ru/uploads/nophoto.jpg" />

                            <div
                                v-if="template.picture"
                                @click="delPicture(template.id)"
                                class="w-full h-full flex items-center justify-center bg-black/10 text-white/80 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                            >
                                <IconDelete :size="12" />
                            </div>

                            <label
                                v-else
                                for="image"
                                class="w-full h-full flex items-center justify-center bg-black/10 text-white/80 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                                ><IconEdit :size="12" />
                                <!-- <svg class="fill-white w-5 h-5">
                                    <use xlink:href="@/assets/i.svg#ico-edit"></use>
                                </svg> -->
                            </label>
                        </div>

                        <!-- <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="template.picture" /> -->
                    </div>

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Группы для размещения</label>
                        <textarea class="w-full h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.group_ids"></textarea>
                    </div>

                    <div class="flex justify-between gap-8 mt-8 mb-8">
                        <div class="btn btn-empty-red text-sm py-2 px-4" @click="setPopup('delete')">Удалить</div>

                        <div class="btn btn-green text-sm py-2 px-4" @click="saveTemplate(template)">Сохранить</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-2/3 bg-white flex flex-col rounded">
            <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500">ГРУППЫ</h2>

            <template v-for="item in template.ads" :key="item.id">
                <div class="grid grid-cols-12 items-center gap-4 p-4 text-sm border-b border-slate-200 cursor-pointer">
                    <img v-if="item.group?.photo_100" :src="item.group?.photo_100" alt="" class="rounded w-10 h-10" />
                    <img v-else src="https://vk.studiobaraban.ru/uploads/nophoto.jpg" alt="" class="rounded w-10 h-10" />
                    <div class="col-span-7">{{ item.group?.name }}</div>
                    <div class="col-span-2">
                        <div v-if="item.status == 1">На модерации</div>
                        <div v-if="item.status == 2">Размещено</div>
                    </div>
                    <div class="flex justify-end col-span-2 gap-4">
                        <div @click="publishMarketAd(item.id)"><IconRocket /></div>

                        <div @click="setPopup('delAd'), (ad = item)"><IconDelete /></div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="s.popup == 'delete'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ ШАБЛОН</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите удалить шаблон</p>

                <p class="my-4 font-bold text-3xl">"{{ template.name }}"</p>

                <p>Его можно будет восстановить!</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="delTemplate(template.id), setPopup('')">Удалить</div>
                </div>
            </div>
        </div>
    </template>

    <template v-if="s.popup == 'delAd'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ ОБЪЯВЛЕНИЕ</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите удалить объявление для группы</p>

                <p class="my-4 font-bold text-3xl">"{{ ad.group?.name }}"</p>

                <p>Его можно будет восстановить!</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="delMarketAd(ad), setPopup('')">Удалить</div>
                </div>
            </div>
        </div>
    </template>
    <div v-if="s.popup" @click="setPopup('')" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewTemplate",

    data() {
        return {
            ad: {},
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["getOneTemplate", "setPopup", "saveTemplate", "delTemplate", "removeTemplate", "uploadImg", "delPicture", "delMarketAd"]),
    },

    mounted() {
        this.getOneTemplate();
    },
};
</script>
