<template>
    <div class="w-5/6 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <div class="flex flex-col p-8 text-center h-full overflow-auto">
            <div class="flex justify-center items-center gap-4 relative pb-8 text-center border-b border-slate-200">
                <div
                    class="absolute top-0 left-0 btn btn-red flex justify-center items-center gap-2 bg-slate-200 hover:bg-red-500 hover:text-white transition duration-300 cursor-pointer"
                    @click="setPopup('delAd'), (currentAd = ad)"
                >
                    В АРХИВ
                </div>

                <div class="flex flex-col text-xs text-slate-400">
                    <span>#{{ ad.id }}</span>
                    <span>{{ ad.link }}</span>
                </div>

                <a
                    class="text-2xl text-cyan-600 font-bold tabular-nums cursor-pointer"
                    target="_blank"
                    :href="'https://vk.com/adsmarket?act=post&ad_id=' + ad.adId"
                >
                    <IVk />
                </a>

                <img v-if="ad.group?.photo_100" :src="ad.group?.photo_100" class="rounded w-20 h-20" />
                <img v-else src="https://api.timekraken.ru/uploads/noimg.jpg" class="rounded w-20 h-20" />

                <div class="flex flex-col justify-center items-center text-xl">
                    <span class="text-xs text-slate-400">ПУБЛИКАЦИИ В ГРУППЕ</span>
                    {{ ad.group?.name }}

                    <div class="flex justify-between items-center gap-4 text-sm mt-2">
                        <div class="flex flex-col">
                            <span class="text-xs text-slate-400">ПОДПИСЧИКИ</span>
                            {{ parseInt(ad.group?.members).toLocaleString() }}
                        </div>
                        <div class="flex flex-col">
                            <span class="text-xs text-slate-400">ОХВАТ</span>
                            {{ parseInt(ad.group?.preach).toLocaleString() }}
                        </div>
                        <div class="flex flex-col">
                            <span class="text-xs text-slate-400">ЦЕНА</span>
                            {{ parseInt(ad.group?.cost).toLocaleString() }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between items-center h-16 mb-4 gap-4 sticky top-16">
                <div
                    v-if="dataAd.total"
                    class="flex justify-between items-center w-full text-center h-16 border-b border-slate-200 bg-white px-4 rounded uppercase"
                >
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.count.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">РАЗМЕЩЕНИЯ</span>
                    </div>

                    <div>
                        <div class="text-xl text-yellow-600 leading-4 pt-2">{{ dataAd.total?.book.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">БРОНЬ</span>
                    </div>
                    <div>
                        <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataAd.total?.work.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
                    </div>

                    <div class="w-px h-full bg-slate-200"></div>

                    <div>
                        <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataAd.total?.spent.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>

                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.likes.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ЛАЙКИ</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.reposts.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">РЕПОСТЫ</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.comments.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">КОММЕНТАРИИ</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.views.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.clicks.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.regs.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>

                    <div class="w-px h-full bg-slate-200"></div>

                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.cpm.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">CPM</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.cpc.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">CPC</span>
                    </div>
                    <div>
                        <div class="text-xl leading-4 pt-2">{{ dataAd.total?.cpa.toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">CPA</span>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-9 items-center gap-4 py-2 text-xs text-slate-400">
                <div class="text-left">ДАТА</div>
                <div class="text-left">ВРЕМЯ</div>
                <div>ОХВАТ</div>
                <div>ЛАЙКИ</div>
                <div>РЕПОСТЫ</div>
                <div>КОММЕНТАРИИ</div>
                <div>КЛИКИ</div>
                <div>ПОТРАЧЕНО</div>
                <div>СТАТУС</div>
            </div>

            <!-- :class="{ '!text-slate-400': publish.status == 9, '!bg-teal-50': publish.status == 7, '!bg-sky-50': publish.status == 8 }" -->
            <div class="overflow-y-scroll">
                <div
                    class="grid grid-cols-9 items-center gap-4 py-2 text-sm hover:bg-slate-50 border-b border-slate-100"
                    v-for="publish in dataAd.publishes"
                    :key="publish.id"
                    :class="{ '!text-slate-400': [9, 10, 11, 12].includes(publish.status) }"
                >
                    <div class="flex items-center gap-2 tabular-nums">{{ moment(publish.publish_at).format("DD.MM.YYYY") }}</div>

                    <div class="flex items-center gap-2 tabular-nums"><IconClock :size="3" /> {{ moment(publish.publish_at).format("HH:mm") }}</div>

                    <div class="">{{ parseInt(publish.reach).toLocaleString() }}</div>
                    <div class="">{{ publish.likes }}</div>
                    <div class="">{{ publish.reposts }}</div>
                    <div class="">{{ publish.comments }}</div>
                    <div class="">{{ publish.clicks }}</div>
                    <div class="text-right">{{ publish.cost }} ₽</div>

                    <div class="text-yellow-600" v-if="publish.status == 4">Ожидает</div>
                    <div class="text-green-600" v-if="publish.status == 7">Активно</div>
                    <div class="text-sky-600" v-if="publish.status == 8">Выполнено</div>
                    <div class="text-slate-400" v-if="publish.status == 9">Не&nbsp;найдено</div>
                    <div class="text-red-600" v-if="publish.status == 10">Отклонено</div>
                    <div class="text-red-600" v-if="publish.status == 11">Просрочено</div>
                    <div class="text-red-600" v-if="publish.status == 12">Не&nbsp;выполнено</div>
                </div>
            </div>
            <div class="absolute top-4 right-4 cursor-pointer text-slate-400" @click="pickAd(null), closePopup()"><IconClose :size="6" /></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewAd",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "template", "ad", "statAd"]),

        dataAd() {
            if (!this.ad.publishes) return { publishes: null, total: null };

            let data = [];
            let total = {
                count: 0,
                book: 0,
                work: 0,
                likes: 0,
                reposts: 0,
                comments: 0,
                spent: 0,
                views: 0,
                clicks: 0,
                regs: 0,
                cpm: 0,
                cpc: 0,
                cpa: 0,
            };

            // лиды из статистики
            total.regs = this.statAd.reduce((acc, stat) => {
                if (stat.ad_id == this.ad.id) return acc + stat.regs;
                return acc;
            }, 0);

            this.ad.publishes.forEach((pub) => {
                if ([4, 5, 7, 8].includes(pub.status)) total.count++;

                if (pub.likes > 0) total.likes += pub.likes;
                if (pub.reposts > 0) total.reposts += pub.reposts;
                if (pub.comments > 0) total.comments += pub.comments;
                if (pub.reach > 0) total.views += pub.reach;
                if (pub.clicks > 0) total.clicks += pub.clicks;

                if (pub.status == 5 && pub.cost > 0) total.book += pub.cost;
                if (pub.status == 7 && pub.cost > 0) total.work += pub.cost;
                if (pub.status == 8 && pub.cost > 0) total.spent += pub.cost;

                if (!pub.publish_at) pub.publish_at = pub.create_at;

                data.push(pub);
            });

            data.sort((a, b) => (a.publish_at < b.publish_at ? 1 : b.publish_at < a.publish_at ? -1 : 0));

            if (total.views) total.cpm = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total.cpc = parseInt(total.spent / total.clicks);
            if (total.regs) total.cpa = parseInt(total.spent / total.regs);

            return { publishes: data, total: total };
        },
    },
    methods: {
        ...mapActions(["pickAd", "closePopup", "setPopup"]),
    },
};
</script>
