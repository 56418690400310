<template>
    <div class="w-5/6 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <div class="rounded">
            <div class="w-full bg-teal-500 text-white flex justify-center items-center gap-2 p-2 rounded-t">
                <span class="letter bg-white text-teal-500">{{ dataAds?.length }}</span>
                <span>В РАБОТЕ ВО ВСЕХ ПРОЕКТАХ</span>
            </div>

            <div class="grid grid-cols-11 items-center bg-white gap-4 px-4 py-2 text-xs text-slate-400 border-b border-slate-200">
                <div class="cursor-pointer col-span-4" @click="setOrder('name')">ГРУППА &#8597;</div>

                <div class="cursor-pointer" @click="setOrder('price')">ЦЕНА &#8597;</div>

                <div class="text-center cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
                <div class="text-center cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
                <div class="text-center cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                <div class="text-center cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

                <div class="col-span-2 cursor-pointer text-teal-600 text-center" @click="setOrder('last_publish_at')">АКТИВНО &#8597;</div>
            </div>

            <div v-for="item in dataAds" :key="item.id" class="grid grid-cols-11 items-center bg-white gap-4 px-4 text-sm border-b border-slate-200">
                <div class="flex items-center gap-4 col-span-4">
                    <div v-if="item.photo_100" class="flex justify-center items-center gap-4 shrink-0">
                        <img :src="item.photo_100" class="shrink rounded w-10 h-10" />
                    </div>

                    <img
                        v-else
                        src="https://api.timekraken.ru/uploads/nophoto.jpg"
                        @click="pickAd(item), setPopup('viewAd')"
                        class="shrink-0 rounded w-10 h-10"
                    />
                    <div class="flex flex-col">
                        <div class="text-xs text-slate-400">{{ item.id }} {{ item.client_name }}</div>
                        <div class="leading-4 h-4 overflow-hidden">{{ item.name }}</div>
                    </div>
                </div>

                <div class="text-lg text-cyan-600 font-bold tabular-nums">{{ item.cost }}</div>

                <div class="text-center font-bold p-4 border-l border-slate-200">{{ item.spent.toLocaleString() }}</div>

                <div class="text-center">{{ item.clicks.toLocaleString() }}</div>
                <div class="text-center p-4 border-r border-slate-200">{{ item.regs.toLocaleString() }}</div>

                <div class="flex justify-center items-center py-2 gap-2 relative" :class="{ '!text-pink-600': item.cpa > kpi }">
                    <IconWarning :size="3" />
                    {{ item.cpa.toLocaleString() }}
                    <div class="text-[7px] absolute bottom-0" v-if="item.cpa > kpi">неэффективно</div>
                </div>

                <div class="grid grid-cols-6 col-span-2 gap-2 justify-center items-center text-center text-teal-600 h-full border-l border-slate-200">
                    <template v-if="item.status == 7">
                        <div class="col-span-5 w-full flex justify-center items-center text-[12px]">
                            <template v-if="item.last_publish_at">
                                <DayAd :date="item.last_publish_at" /> в {{ moment(item.last_publish_at).format("H:mm") }}
                            </template>
                        </div>
                        <div><IconOk /></div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "BookAds",

    data() {
        return {
            moment: moment,
            kpi: 500,
        };
    },

    computed: {
        ...mapGetters(["s", "ads", "statAd"]),

        dataAds() {
            if (!this.ads || !this.statAd) return null;

            let ads = [];
            this.ads.forEach((ad) => {
                let data = { likes: 0, reposts: 0, comments: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
                this.statAd.forEach((stat) => {
                    if (stat.ad_id == ad.id && stat.date >= this.s.start && stat.date <= this.s.end) {
                        if (stat.spent > 0) data.spent += stat.spent;
                        if (stat.clicks > 0) data.clicks += stat.clicks;
                        if (stat.regs > 0) data.regs += stat.regs;
                    }
                });

                ad.spent = data.spent;
                ad.clicks = data.clicks;
                ad.regs = data.regs;
                ad.cpa = data.cpa;
                if (ad.regs) ad.cpa = parseInt(ad.spent / ad.regs);

                ads.push(ad);
            });

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.group.name > b.group.name ? 1 : b.group.name > a.group.name ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.group.name < b.group.name ? 1 : b.group.name < a.group.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "price") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.group.cost > b.group.cost ? 1 : b.group.cost > a.group.cost ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.group.cost < b.group.cost ? 1 : b.group.cost < a.group.cost ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (this.s.order_name == "last_publish_at") {
                if (this.s.order) {
                    ads.sort((a, b) => (a.last_publish_at > b.last_publish_at ? 1 : b.last_publish_at > a.last_publish_at ? -1 : 0));
                } else {
                    ads.sort((a, b) => (a.last_publish_at < b.last_publish_at ? 1 : b.last_publish_at < a.last_publish_at ? -1 : 0));
                }
            }

            return ads;
        },
    },

    methods: {
        ...mapActions(["createCampaign", "setPopup", "closePopup", "setOrder", "pickAd"]),
    },
};
</script>
