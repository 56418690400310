<template>
    <div class="flex justify-between items-center w-full h-12 px-4">
        <a @click="$router.push('/clients')" class="flex justify-between items-center text-xl font-bold text-cyan-500 cursor-pointer">
            МАРКЕТ ПЛАТФОРМА
        </a>

        <ul class="flex justify-between items-center text-center text-sm h-12 gap-10 px-10">
            <li v-for="item in menu" :key="item">
                <a @click="$router.push(item.url)" class="items-center text-md text-slate-500 cursor-pointer">{{ item.name }}</a>
            </li>
        </ul>

        <div class="flex items-center gap-4 h-12 text-sm text-slate-500">
            {{ profile?.name }}
            <a class="bg-white w-8 h-8 my-2 rounded-full overflow-hidden relative cursor-pointer" @click="$router.push('/profile')">
                <img
                    v-if="profile?.picture"
                    class="w-full h-full object-cover"
                    :src="'https://api.timekraken.ru/uploads/users/mini/' + profile?.picture"
                />
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "BotMenu",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["profile", "menu"]),
    },
};
</script>
