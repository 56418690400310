<template>
    <div class="rounded">
        <div class="w-full text-slate-500 flex justify-center items-center gap-2 mb-8">
            <span class="letter text-white bg-slate-400">{{ files?.length }}</span>
            <span>БИБЛИОТЕКА ФАЙЛОВ</span>
        </div>

        <div v-if="uploader" class="w-full relative"><img class="w-1/2 mx-auto" src="https://api.timekraken.ru/images/loader.svg" /></div>

        <div v-else class="w-full relative grid grid-cols-4 items-center gap-4">
            <div v-for="item in files" :key="item.id" class="flex flex-col cursor-pointer opacity-70 hover:opacity-100 transition duration-300">
                <img
                    :src="'https://api.timekraken.ru/uploads/templates/' + item.name"
                    class="shrink-0 rounded w-full h-full aspect-square object-cover"
                    @click="attachFile(item)"
                />
                <span class="h-8 overflow-hidden text-slate-400 text-xs">{{ item.description }}</span>
            </div>
        </div>

        <div class="w-full">
            <input type="file" multiple id="image" class="absolute invisible opacity-0" @change="uploadFile($event)" />

            <label
                for="image"
                class="w-fit h-8 mx-auto mt-8 px-4 flex items-center justify-center gap-2 text-white bg-teal-500 hover:bg-teal-400 rounded cursor-pointer transition duration-300"
            >
                <IconPlus :size="5" />
                ЗАГРУЗИТЬ
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "FilesPopup",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "uploader", "files"]),
    },

    methods: {
        ...mapActions(["uploadFile", "setPopup", "closePopup", "attachFile"]),
    },
};
</script>
