import API from "../../services/api";

export default {
    state: {
        access: [],
        cabinets: [],
    },

    mutations: {
        setMonitor(state, monitor) {
            state.monitor = monitor;
        },

        setCabinets(state, cabinets) {
            state.cabinets = cabinets;
        },

        setAccess(state, access) {
            state.access = access;
        },
    },

    actions: {
        getMonitor(ctx) {
            API.GET("admin/monitor/list").then((res) => {
                if (res.data?.alert) ctx.commit("setAlert", res.data?.alert);
                ctx.commit("setMonitor", res.data?.monitor);
            });
        },

        // клиентские кабинеты
        getCabinets(ctx) {
            API.GET("admin/client/list").then((res) => {
                if (res.data?.alert) ctx.commit("setAlert", res.data?.alert);
                ctx.commit("setCabinets", res.data?.cabinets);
            });
        },

        createCabinet(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);

            API.POST("admin/client/create", formData).then((res) => {
                if (res.data?.alert) ctx.commit("setAlert", res.data?.alert);
                ctx.commit("setCabinets", res.data?.cabinets);
            });
        },

        // назначение клиентов таргетологам
        getAccess(ctx) {
            API.GET("admin/access/list").then((res) => {
                if (res.data?.alert) ctx.commit("setAlert", res.data?.alert);
                ctx.commit("setAccess", res.data?.access);
            });
        },

        setAccessClient(ctx, data) {
            let formData = new FormData();
            formData.append("user_id", data.user_id);
            formData.append("client_id", data.client_id);

            API.POST("admin/access/add", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAccess", res.data?.access);
            });
        },

        delAccessClient(ctx, data) {
            let formData = new FormData();
            formData.append("user_id", data.user_id);
            formData.append("client_id", data.client_id);

            API.POST("admin/access/del", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAccess", res.data?.access);
            });
        },

        // один
        newTask(ctx, type) {
            let formData = new FormData();
            formData.append("type", type);

            API.POST("admin/monitor/new-task", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("getMonitor");
            });
        },

        // много этого типа
        newTasks(ctx, type) {
            let formData = new FormData();
            formData.append("type", type);

            API.POST("admin/monitor/new-tasks", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("getMonitor");
            });
        },

        delTask(ctx, task) {
            let formData = new FormData();
            formData.append("id", task.id);

            API.POST("admin/monitor/del", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.state.monitor.tasks = ctx.state.monitor.tasks.filter((item) => parseInt(item.id) != parseInt(task.id));
                ctx.commit("setMonitor", ctx.state.monitor);
            });
        },

        // ADMIN TEMPLATES

        // получаем все данные по клиентам, кампаниям и креативам
        async getAdminTemplates(ctx) {
            await API.GET("admin/monitor/clients").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);
                ctx.commit("setCampaigns", res.data.campaigns);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setStatCamp", res.data.statCamp);
                ctx.commit("setStatAd", res.data.statAd);
                ctx.commit("setUsers", res.data.users);
            });
        },
    },

    getters: {
        monitor(state) {
            return state.monitor;
        },

        cabinets(state) {
            return state.cabinets;
        },

        access(state) {
            return state.access;
        },
    },
};
