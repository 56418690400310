<template>
    <div class="flex justify-between items-center h-16 mb-4 gap-4">
        <div
            class="flex justify-center items-center aspect-square h-16 bg-white text-cyan-500 hover:bg-cyan-500 hover:text-white rounded cursor-pointer transition"
            @click="pickClient(client), $router.push('/client')"
        >
            <IconBack :size="5" />
        </div>

        <div v-if="dataTemplates.total" class="flex justify-between items-center w-full text-center h-16 bg-white px-4 rounded uppercase">
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.ads.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
            </div>

            <div>
                <div class="text-xl text-yellow-500 leading-4 pt-2">{{ dataTemplates.total?.book.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">БРОНЬ</span>
            </div>
            <div>
                <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataTemplates.total?.work.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataTemplates.total?.spent.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
            </div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.views.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.clicks.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">КЛИКИ</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.regs.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">ЛИДЫ</span>
            </div>

            <div class="w-px h-full bg-slate-200"></div>

            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpm.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPM</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpc.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPC</span>
            </div>
            <div>
                <div class="text-xl leading-4 pt-2">{{ dataTemplates.total?.cpa.toLocaleString() }}</div>
                <span class="text-[8px] text-slate-400">CPA</span>
            </div>
        </div>

        <div class="flex justify-center items-center aspect-square h-16 bg-teal-500 text-white rounded cursor-pointer">
            <div v-if="disable == false" class="flex justify-center items-center h-16 w-16" @click="createTemplate(), (disable = true)">
                <IconPlus :size="5" />
            </div>
            <div v-else><IconLoader :size="5" /> Создаем ...</div>
        </div>
    </div>

    <div class="flex justify-between items-center h-10 mb-4 gap-4">
        <div
            class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase cursor-pointer"
            @click="pickClient(client), $router.push('/client')"
        >
            {{ client?.name }}
        </div>
        <div class="shrink-0 text-sm text-white bg-slate-400 h-8 flex items-center px-2 rounded uppercase">
            {{ campaign?.name }}
        </div>

        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().add(1, 'days').format('YYYY-MM-DD') && s.end == moment().add(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().add(1, 'days').format('YYYY-MM-DD'), end: moment().add(1, 'days').format('YYYY-MM-DD') })"
        >
            ЗАВТРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕГОДНЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            НЕДЕЛЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
        >
            МЕСЯЦ
        </div>

        <!-- <input type="text" class="input" /> -->

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.start" />

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.end" />
    </div>

    <!-- <div class="flex justify-between items-center h-20 mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase cursor-pointer" @click="pickClient(client), $router.push('/client')">
            {{ client?.name }} : {{ campaign?.name }}
        </div>
        <input type="text" class="input" />

        <div v-if="disable == false" class="btn btn-green shrink-0 w-48 cursor-pointer gap-2" @click="createTemplate(), (disable = true)">
            <IcoBig name="plus" :size="5" /> Создать креатив
        </div>
        <div v-else class="btn btn-green shrink-0 w-48 cursor-pointer gap-2"><IconLoader :size="5" /> Создаем ...</div>
    </div> -->

    <!-- <div class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer" @click="pickClient(client)">
                <span class="text-slate-400">КЛИЕНТ</span>
                {{ client?.name }}
            </div>
            <div class="flex flex-col text-xs">
                <span class="text-slate-400">КАМПАНИЯ</span>
                {{ campaign?.name }}
            </div>
        </div>

        <div class="btn btn-green cursor-pointer gap-2" @click="setPopup('addTemplate')"><IcoBig name="plus" :size="5" /> Создать</div>
    </div> -->

    <div v-if="graf && graf?.length > 0 && graf[0].time" class="w-full"><LineGraf :graf="graf" h="h-32" /></div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 px-4 border-b border-slate-200">
            <div class="cursor-pointer" @click="setOrder('id')">ID &#8597;</div>
            <div class="col-span-2 cursor-pointer" @click="setOrder('name')">КРЕАТИВ &#8597;</div>

            <div class="text-center cursor-pointer p-2 border-r border-slate-200" @click="setOrder('ads')">ОБЪ&nbsp;&#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('book')">БРОНЬ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('work')">В&nbsp;РАБОТЕ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>

            <!-- <div class="grid grid-cols-7 gap-4 col-span-8">
                <div class="text-right">БРОНЬ</div>
                <div class="text-right">В РАБОТЕ</div>
                <div class="text-right">ПОТРАЧЕНО</div>

                <div class="text-right">ОБЪЯВЛЕНИЯ</div>

                <div class="text-right">КЛИКИ</div>
                <div class="text-right">ЛИДЫ</div>
                <div class="text-right">ПОКУПКИ</div>
            </div> -->

            <div class="text-right"></div>
        </div>

        <template v-for="item in dataTemplates.templates" :key="item.id">
            <div
                v-if="item.status == 1"
                class="grid grid-cols-14 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-500"
            >
                <div @click="pickTemplate(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                    <img
                        v-if="item.picture && JSON.parse(item.picture)[0]"
                        :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(item.picture)[0]"
                        class="rounded w-10 h-10"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-10 h-10" />
                </div>

                <div @click="pickTemplate(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>

                <div
                    class="text-md text-slate-300 text-center p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.ads_count != 0 }"
                >
                    {{ item.ads_count }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-yellow-500 tabular-nums': item.book != 0 }">
                    {{ parseInt(item.book).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-teal-500 tabular-nums': item.work != 0 }"
                >
                    {{ parseInt(item.work).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-cyan-500 tabular-nums': item.spent != 0 }">
                    {{ parseInt(item.spent).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.views != 0 }">
                    {{ parseInt(item.views).toLocaleString() }}
                </div>
                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.clicks != 0 }">
                    {{ parseInt(item.clicks).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.regs != 0 }"
                >
                    {{ parseInt(item.regs).toLocaleString() }}
                </div>

                <div v-if="item.spent && item.views" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / (item.views / 1000)).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.clicks" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.clicks).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.regs" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.regs).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div class="flex justify-center gap-4 relative group">
                    <span class="tip">{{ tip }}</span>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'клонировать'"
                        @mouseleave="tip = ''"
                        @click="cloneTemplate(item)"
                    >
                        <IconClone :size="4" />
                    </div>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'скрыть'"
                        @mouseleave="tip = ''"
                        @click="archiveTemplate(item.id)"
                    >
                        <IconEyeClose :size="4" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <h2 v-if="isArchive == 1" class="w-full mt-10 mb-4 text-center text-slate-400">АРХИВ</h2>

    <div class="bg-white/50 rounded">
        <template v-for="item in templates" :key="item.id">
            <div
                v-if="item.status == 8"
                class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm text-slate-400 hover:bg-slate-50 hover:!text-slate-500"
            >
                <div @click="pickTemplate(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                    <img
                        v-if="item.picture && JSON.parse(item.picture)[0]"
                        :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(item.picture)[0]"
                        class="rounded w-10 h-10"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-10 h-10" />
                </div>

                <div @click="pickTemplate(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>
                <div class="text-center font-bold">{{ item.ads?.length }}</div>

                <div class="text-center col-span-6"></div>

                <div class="flex justify-center gap-4 relative group">
                    <span class="tip">{{ tip }}</span>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'клонировать'"
                        @mouseleave="tip = ''"
                        @click="cloneTemplate(item)"
                    >
                        <IconClone :size="4" />
                    </div>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'вернуть'"
                        @mouseleave="tip = ''"
                        @click="unarchiveTemplate(item.id)"
                    >
                        <IconEye :size="4" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <!-- <template v-if="s.popup == 'addTemplate'"><AddTemplate /></template> -->
    <!-- <template v-if="s.popup == 'editTemplate'"><EditTemplate /></template> -->
    <!-- <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import LineGraf from "../components/Graf/LineGraf.vue";

export default {
    name: "CampaignPage",

    components: { LineGraf },

    data() {
        return {
            moment: moment,
            radiobtn: "spent",
            tip: "",
            disable: false,
        };
    },

    computed: {
        ...mapGetters(["s", "stat", "client", "campaign", "templates", "template"]),

        isArchive() {
            let arch = 0;
            if (this.templates && this.templates.filter((item) => item.status == 8).length > 0) arch = 1;
            return arch;
        },

        dataTemplates() {
            if (!this.templates || !this.stat) return { templates: null, total: null };

            let total = { ads: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            let data = [];

            this.templates.forEach((template) => {
                template.ads_count = template.cpm = template.cpc = template.cpa = 0;

                if (template.ads) template.ads_count = parseInt(template.ads.length);

                if (template.views) template.cpm = parseInt(template.spent / (template.views / 1000));
                if (template.clicks) template.cpc = parseInt(template.spent / template.clicks);
                if (template.regs) template.cpa = parseInt(template.spent / template.regs);

                total["ads"] += parseInt(template.ads_count);
                total["book"] += parseInt(template.book);
                total["work"] += parseInt(template.work);
                total["spent"] += parseInt(template.spent);
                total["views"] += parseInt(template.views);
                total["clicks"] += parseInt(template.clicks);
                total["regs"] += parseInt(template.regs);
            });

            data = this.templates;

            // this.templates.forEach((template) => {
            //     let item = { ...template, ads: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            //     this.stat.forEach((stat) => {
            //         if (stat.template_id == template.id && stat.date >= this.s.start && stat.date <= this.s.end) {
            //             if (stat.spent > 0) item.spent += parseInt(stat.spent);
            //             if (stat.views > 0) item.views += parseInt(stat.views);
            //             if (stat.clicks > 0) item.clicks += parseInt(stat.clicks);
            //             if (stat.regs > 0) item.regs += parseInt(stat.regs);
            //         }
            //     });

            //     // template["spent"] = item.spent;
            //     // template["views"] = item.views;
            //     // template["clicks"] = item.clicks;
            //     // template["regs"] = item.regs;
            //     // template["cpm"] = item.cpm;
            //     // template["cpc"] = item.cpc;
            //     // template["cpa"] = item.cpa;

            //     if (item.views) item.cpm = parseInt(item.spent / (item.views / 1000));
            //     if (item.clicks) item.cpc = parseInt(item.spent / item.clicks);
            //     if (item.regs) item.cpa = parseInt(item.spent / item.regs);

            //     total["ads"] += parseInt(template["ads_count"]);
            //     total["book"] += parseInt(template["book"]);
            //     total["work"] += parseInt(template["work"]);
            //     total["spent"] += parseInt(item.spent);
            //     total["views"] += parseInt(item.views);
            //     total["clicks"] += parseInt(item.clicks);
            //     total["regs"] += parseInt(item.regs);

            //     data.push(item);
            // });

            if (this.s.order_name == "id") {
                if (this.s.order) {
                    data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
                }
            }

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "ads") {
                if (this.s.order) {
                    data.sort((a, b) => (a.ads_count > b.ads_count ? 1 : b.ads_count > a.ads_count ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.ads_count < b.ads_count ? 1 : b.ads_count < a.ads_count ? -1 : 0));
                }
            }

            if (this.s.order_name == "book") {
                if (this.s.order) {
                    data.sort((a, b) => (a.book > b.book ? 1 : b.book > a.book ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.book < b.book ? 1 : b.book < a.book ? -1 : 0));
                }
            }

            if (this.s.order_name == "work") {
                if (this.s.order) {
                    data.sort((a, b) => (a.work > b.work ? 1 : b.work > a.work ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.work < b.work ? 1 : b.work < a.work ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
            if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

            return { templates: data, total: total };
        },

        // график расхода в клиенте
        graf() {
            if (!this.stat) return null;

            let result = [];

            let srt = moment(this.stat[0]?.date).format("YYYY-MM-DD");
            if (this.s.start != srt) srt = moment(this.s.start).format("YYYY-MM-DD");
            let end = moment().format("YYYY-MM-DD");
            if (this.s.end != end) end = moment(this.s.end).format("YYYY-MM-DD");

            while (srt <= end) {
                let d = { time: moment(srt).format("YYYY-MM-DD"), views: 0, clicks: 0, regs: 0, spent: 0 };
                this.stat.forEach((stat) => {
                    if (stat.date == srt) {
                        d.spent += parseInt(stat.spent);
                        d.views += parseInt(stat.views);
                        d.clicks += parseInt(stat.clicks);
                        d.regs += parseInt(stat.regs);
                    }
                });

                d.cpm = 0;
                d.cpc = 0;
                d.cpa = 0;
                if (d.views > 0) d.cpm = parseInt(d.spent / (d.views / 1000));
                if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks);
                if (d.regs > 0) d.cpa = parseInt(d.spent / d.regs);

                // для графика
                if (this.radiobtn == "spent") d.value = d.spent;
                if (this.radiobtn == "views") d.value = d.views;
                if (this.radiobtn == "clicks") d.value = d.clicks;
                if (this.radiobtn == "regs") d.value = d.regs;
                if (this.radiobtn == "cpc") d.value = d.cpc;
                if (this.radiobtn == "cpm") d.value = d.cpm;
                if (this.radiobtn == "cpa") d.value = d.cpa;

                result.push(d);
                srt = moment(srt).add(1, "day").format("YYYY-MM-DD");
            }

            return result;
        },
    },

    methods: {
        ...mapActions([
            "getTemplates",
            "setSettings",
            "setPeriod",
            "setOrder",
            "pickClient",
            "setPopup",
            "closePopup",
            "createTemplate",
            "pickTemplate",
            "cloneTemplate",
            "archiveTemplate",
            "unarchiveTemplate",
        ]),
    },

    mounted() {
        this.getTemplates();
    },
};
</script>
