import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        accounts: [],
        accountsF: [],
        account: {},

        users: [],
        usersF: [],
    },

    mutations: {
        setAccounts(state, accounts) {
            state.accounts = accounts;
            state.accountsF = accounts;
        },
        setAccountsF(state, accounts) {
            state.accountsF = accounts;
        },
        setAccount(state, account) {
            localStorage.setItem("account", JSON.stringify(account));
            state.account = account;
        },
        setUsers(state, users) {
            state.users = users;
            state.usersF = users;
        },
    },

    actions: {
        // getAccounts(ctx) {
        //     ctx.dispatch("loadSettings");

        //     API.GET("api/vk/account/list").then((res) => {
        //         ctx.commit("setAccounts", res.data.accounts);
        //     });
        // },

        pickAccount(ctx, account) {
            console.log("pickAccount", account.id);
            ctx.commit("setAccount", account);
            ctx.commit("setClients", null);
            ctx.commit("setCampaigns", null);
            ctx.commit("setAds", null);
            ctx.commit("setClient", null);
            ctx.commit("setCampaign", null);
            ctx.dispatch("getClients");
            router.push("/account");
        },

        // getCampaigns(ctx) {
        //     console.log("getCampaigns", ctx.state.client.id);
        //     API.GET("api/vk/client/" + ctx.state.client.id + "/campaigns").then((res) => {
        //         ctx.commit("setCampaigns", res.data.campaigns);
        //     });
        // },

        // pickCampaign(ctx, campaign) {
        //     ctx.commit("setCampaign", campaign);
        //     ctx.commit("setAds", null);
        //     ctx.dispatch("getAds");
        //     router.push("/campaign");
        // },

        getAds(ctx) {
            console.log("getAds", ctx.state.campaign.id);
            API.GET("api/vk/campaign/" + ctx.state.campaign.id + "/ads").then((res) => {
                ctx.commit("setAds", res.data.ads);
            });
        },
        // pickAd(ctx, campaign) {
        //     ctx.commit("setAd", campaign);
        //     ctx.commit("setAds", null);
        //     router.push("/ad");
        // },

        load(ctx) {
            let account = JSON.parse(localStorage.getItem("account"));
            ctx.commit("setAccount", account);
            let client = JSON.parse(localStorage.getItem("client"));
            ctx.commit("setClient", client);
            let campaign = JSON.parse(localStorage.getItem("campaign"));
            ctx.commit("setCampaign", campaign);
            let ad = JSON.parse(localStorage.getItem("ad"));
            ctx.commit("setAd", ad);

            if (ad && ad.id > 0) {
                router.push("/ad");
                return;
            }
            if (campaign && campaign.id > 0) {
                router.push("/campaign");
                return;
            }
            if (client && client.id > 0) {
                router.push("/client");
                return;
            }
            if (account) {
                router.push("/account");
                return;
            }
        },
    },

    getters: {
        accounts(state) {
            return state.accountsF;
        },
        account(state) {
            return state.account;
        },
        users(state) {
            return state.usersF;
        },
    },
};
