<template>
    <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ДОБАВИТЬ ПОКУПАТЕЛЕЙ</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col items-center mb-4">
                <label class="w-full text-sm text-left text-slate-400 mb-1">ДАННЫЕ</label>
                <textarea type="text" class="w-full h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="list"></textarea>
            </div>

            <div v-for="item in preview" :key="item" class="grid grid-cols-5 gap-4 text-left">
                <div class="col-span-2">{{ item?.name }}</div>
                <div>{{ item?.email }}</div>
                <div class="text-right">{{ item?.amount }}</div>
                <div class="text-center">{{ item?.link }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="addPayments(preview), closePopup()">СОЗДАТЬ</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddPayments",

    data() {
        return {
            moment: moment,
            list: "",
        };
    },

    computed: {
        ...mapGetters(["s", "client"]),

        preview() {
            if (!this.list || this.list.length < 5 || !this.client) return null;

            let preview = [];

            const lines = this.list.split("\n");

            lines.forEach((line) => {
                const items = line.split("	");
                if (parseFloat(items[2]) > 0) {
                    preview.push({ client_id: this.client.id, name: items[0], email: items[1], amount: items[2], link: items[3] });
                }
            });

            return preview;
        },
    },

    methods: {
        ...mapActions(["addPayments", "closePopup"]),
    },
};
</script>
