import API from "../../services/api";

export default {
    state: {
        projects: [],
        projectsF: [],
        project: {},

        // clietns: [],
        // clietnsF: [],
        // clietn: {},
    },

    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
            state.projectsF = projects;
        },
        setProjectsF(state, projects) {
            state.projectsF = projects;
        },
        setProject(state, project) {
            state.project = project;
        },

        // setClients(state, clients) {
        //     state.clients = clients;
        //     state.clientsF = clients;
        // },
        // setClientsF(state, clients) {
        //     state.clientsF = clients;
        // },
        // setClient(state, client) {
        //     state.client = client;
        // },
    },

    actions: {
        getProjects(ctx) {
            API.GETDOC("doc/list").then((res) => {
                ctx.commit("setProjects", res.data.projects);
            });
        },
    },

    getters: {
        projects(state) {
            return state.projectsF;
        },
        project(state) {
            return state.project;
        },
        // clients(state) {
        //     return state.clientsF;
        // },
        // client(state) {
        //     return state.client;
        // },
    },
};
