<template>
    <div class="h-[calc(100vh-48px)] flex justify-center items-center bg-sky-800 -m-4">
        <div
            class="w-full max-w-xl z-20 p-10 pb-20 mt-4 mx-auto bg-white/30 rounded-xl backdrop-blur shadow-2xl hover:bg-white/60 hover:-mt-4 duration-1000"
        >
            <div class="p-4 text-center">
                <div class="flex justify-center text-lg uppercase font-latobold">
                    <div class="text-xl text-sky-800 font-bold">МАРКЕТ ПЛАТФОРМА VK</div>
                </div>
            </div>

            <form class="p-4">
                <input type="text" class="text-sky-600 w-full outline-none px-5 h-10 mb-4 text-sm rounded" v-model="username" placeholder="Логин" />

                <input
                    type="password"
                    autocomplete="on"
                    class="text-sky-600 w-full outline-none px-5 h-10 mb-4 text-sm rounded"
                    v-model.trim="password"
                    placeholder="Пароль"
                    @keyup.enter="login({ username: username, password: password })"
                />

                <div class="mx-auto block bg-sky-600 hover:bg-sky-500 rounded px-5 py-3 h-10 cursor-pointer">
                    <div
                        class="text-sm font-latobold leading-none uppercase text-white text-center"
                        @click="login({ username: username, password: password })"
                    >
                        Войти
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ul class="circles">
        <li v-for="li in list" :key="li" @click="popIt()"></li>
    </ul>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "MainPage",

    data() {
        return {
            form: "login",
            name: null,
            email: null,
            username: null,
            password: null,
            count: 0,
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        };
    },

    methods: {
        ...mapActions(["login", "reg"]),

        popIt() {
            this.count++;
            event.currentTarget.classList.add("!hidden");
        },
    },
};
</script>

<style>
.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 28px;
    height: 28px;
    color: #fff;
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li::before {
    width: 28px;
    height: 28px;
    content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path d="M19.98 3C22.2002 3 24 4.79982 24 7.02V20.98C24 23.2002 22.2002 25 19.98 25H8.02C5.79982 25 4 23.2002 4 20.98V7.02C4 4.79982 5.79982 3 8.02 3H19.98ZM19.99 5H8.01C6.89991 5 6 5.89991 6 7.01V20.99C6 22.1001 6.89991 23 8.01 23H19.99C21.1001 23 22 22.1001 22 20.99V7.01C22 5.89991 21.1001 5 19.99 5ZM18.995 11C19.55 11 20 11.45 20 12.005V19.995C20 20.55 19.55 21 18.995 21H9.005C8.44995 21 8 20.55 8 19.995V12.005C8 11.45 8.44995 11 9.005 11H18.995ZM15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9C8.44772 9 8 8.55228 8 8C8 7.44772 8.44772 7 9 7H15Z" fill="%23fff" /></svg>');
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 140px;
    height: 140px;
    animation-delay: 5s;
    animation-duration: 11s;
}

.circles li:nth-child(11) {
    left: 55%;
    width: 100px;
    height: 100px;
    animation-delay: 0s;
    animation-duration: 11s;
}

.circles li:nth-child(12) {
    left: 45%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
    animation-duration: 11s;
}

.circles li:nth-child(13) {
    left: 35%;
    width: 120px;
    height: 120px;
    animation-delay: 3s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}
</style>
