<template>
    <div class="flex justify-center items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <div class="text-center text-2xl text-slate-500">
            <span class="">НАЗНАЧИТЬ КАБИНЕТЫ ТАРГЕТОЛОГАМ</span>
        </div>
    </div>

    <div class="w-full">
        <template v-for="item in targetologs" :key="item.id">
            <div class="grid grid-cols-1 md:grid-cols-6 items-center gap-4 p-2 text-sm mb-8">
                <div>{{ item.secondname }} {{ item.name }}</div>

                <div class="col-span-5 flex flex-wrap justify-start items-center gap-2">
                    <div v-for="client in item.clients" :key="client" class="flex justify-start items-center gap-2 bg-white h-8 p-2 rounded text-xs">
                        <div class="whitespace-nowrap">{{ client.name }}</div>
                        <div
                            @click="delAccessClient({ user_id: item.id, client_id: client.id })"
                            class="flex justify-end cursor-pointer hover:text-red-500"
                        >
                            <IconClose :size="4" />
                        </div>
                    </div>
                    <div
                        @click="set(item.id)"
                        class="flex justify-center items-center gap-2 bg-white text-teal-500 hover:bg-teal-500 hover:text-white h-8 aspect-square rounded cursor-pointer"
                    >
                        <IconPlus :size="5" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <template v-if="popup == 'set'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">НАЗНАЧИТЬ</h3>

            <p class="text-xl text-center">{{ user.secondname }} {{ user.name }}</p>

            <div class="flex flex-col p-4 text-center">
                <template v-for="cli in access.clients" :key="cli">
                    <div
                        v-if="!ids.includes(cli.id)"
                        @click="setAccessClient({ user_id: user.id, client_id: cli.id })"
                        class="flex justify-start items-center gap-4 p-1 cursor-pointer text-slate-500 hover:text-cyan-500"
                    >
                        <IconPlus :size="5" /> {{ cli.name }}
                    </div>
                    <div v-if="ids.includes(cli.id)" class="flex justify-start items-center gap-4 p-1 text-teal-500">
                        <IconOk :size="5" /> {{ cli.name }}
                    </div>
                </template>
            </div>
        </div>
    </template>
    <div v-if="popup" @click="popup = null" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "AdminAccess",

    data() {
        return {
            moment: moment,
            popup: null,
            user: null,
        };
    },

    computed: {
        ...mapGetters(["access"]),

        targetologs() {
            if (!this.access || !this.access.tgs) return [];

            let data = this.access.tgs.map((tg) => ({ ...tg, ids: tg.clients.map((client) => client.id) }));

            return data;
        },

        ids() {
            if (!this.user || !this.user.id) return [];

            let tg = this.targetologs.find((tg) => tg.id === this.user.id);
            return tg ? tg.ids : [];
        },
    },

    methods: {
        ...mapActions(["getAccess", "setAccessClient", "delAccessClient"]),

        set(id) {
            this.user = this.targetologs.find((tg) => tg.id === id);
            this.popup = "set";
        },
    },

    mounted() {
        this.getAccess();
    },
};
</script>
