<template>
    <div v-if="dataClients.total" class="flex justify-between items-center text-center h-16 bg-white px-4 mb-4 rounded uppercase">
        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.templates.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
        </div>

        <div class="cursor-pointer">
            <div class="text-xl text-yellow-500 leading-4 pt-2">{{ dataClients.total.book.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">БРОНЬ</span>
        </div>
        <div class="cursor-pointer">
            <div class="text-xl text-teal-500 leading-4 pt-2">{{ dataClients.total.work.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">В РАБОТЕ</span>
        </div>

        <div class="w-px h-full bg-slate-200"></div>

        <div>
            <div class="text-xl text-cyan-500 leading-4 pt-2">{{ dataClients.total.spent.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
        </div>

        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.views.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">ПОКАЗЫ</span>
        </div>
        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.clicks.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">КЛИКИ</span>
        </div>
        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.regs.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">ЛИДЫ</span>
        </div>

        <div class="w-px h-full bg-slate-200"></div>

        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.cpm.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">CPM</span>
        </div>
        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.cpc.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">CPC</span>
        </div>
        <div>
            <div class="text-xl leading-4 pt-2">{{ dataClients.total.cpa.toLocaleString() }}</div>
            <span class="text-[8px] text-slate-400">CPA</span>
        </div>
    </div>

    <div class="flex justify-between items-center h-10 mb-4 gap-4">
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().add(1, 'days').format('YYYY-MM-DD') && s.end == moment().add(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().add(1, 'days').format('YYYY-MM-DD'), end: moment().add(1, 'days').format('YYYY-MM-DD') })"
        >
            ЗАВТРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕГОДНЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            НЕДЕЛЯ
        </div>
        <div
            class="flex justify-center items-center shrink-0 cursor-pointer text-[8px] text-slate-500 bg-white rounded w-12 h-8 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-01') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().format('YYYY-MM-01'), end: moment().format('YYYY-MM-DD') })"
        >
            МЕСЯЦ
        </div>

        <!-- <input type="text" class="input" /> -->

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.start" />

        <input type="date" class="input text-sm" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div v-if="graf && graf?.length > 0 && graf[0].time" class="w-full"><LineGraf :graf="graf" h="h-32" /></div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-13 items-center text-xs text-slate-400 gap-4 px-4 border-b border-slate-200">
            <!-- <div class="cursor-pointer" @click="setOrder('id')">ID &#8597;</div> -->

            <div class="col-span-3 cursor-pointer" @click="setOrder('name')">КЛИЕНТ &#8597;</div>

            <div class="text-center cursor-pointer p-2 border-r border-slate-200" @click="setOrder('templates')">КРЕАТИВЫ&nbsp;&#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('book')">БРОНЬ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('work')">В&nbsp;РАБОТЕ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('spent')">ПОТРАЧЕНО &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div>
            <div class="text-right cursor-pointer p-2 border-r border-slate-200" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

            <div class="text-right cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div>
            <div class="text-right cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>
        </div>

        <template v-for="item in dataClients.clients" :key="item.id">
            <div class="grid grid-cols-13 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-500">
                <div class="flex items-center gap-2 col-span-3">
                    <a class="text-cyan-500 cursor-pointer" target="_blank" :href="'https://vk.com/adsmarket?act=office&union_id=' + item.id">
                        <IVk />
                    </a>
                    <div class="flex flex-col">
                        <div class="text-[8px] text-slate-500">{{ item.id }}</div>
                        <div class="text-md cursor-pointer" @click="pickClient(item), $router.push('/client')">{{ item.name }}</div>
                    </div>
                </div>

                <div
                    class="text-md text-slate-300 text-center p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.templates != 0 }"
                >
                    {{ item.templates }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-yellow-500 tabular-nums': item.book != 0 }">
                    {{ parseInt(item.book).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-teal-500 tabular-nums': item.work != 0 }"
                >
                    {{ parseInt(item.work).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-cyan-500 tabular-nums': item.spent != 0 }">
                    {{ parseInt(item.spent).toLocaleString() }}
                </div>

                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.views != 0 }">
                    {{ parseInt(item.views).toLocaleString() }}
                </div>
                <div class="text-md text-slate-300 text-right" :class="{ '!text-slate-600 tabular-nums': item.clicks != 0 }">
                    {{ parseInt(item.clicks).toLocaleString() }}
                </div>
                <div
                    class="text-md text-slate-300 text-right p-4 border-r border-slate-200"
                    :class="{ '!text-slate-600 tabular-nums': item.regs != 0 }"
                >
                    {{ parseInt(item.regs).toLocaleString() }}
                </div>

                <div v-if="item.spent && item.views" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / (item.views / 1000)).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.clicks" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.clicks).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <div v-if="item.regs" class="text-md text-slate-600 tabular-nums text-right">
                    {{ parseInt(item.spent / item.regs).toLocaleString() }}
                </div>
                <div v-else class="text-md text-slate-300 text-right">0</div>

                <!-- <div class="flex justify-center items-center gap-2">
                    <img
                        v-for="picture in item.users"
                        :key="picture"
                        class="w-6 h-6 object-cover rounded-full"
                        :src="'https://api.timekraken.ru/uploads/users/mini/' + picture"
                    />
                </div> -->

                <!-- ₽ -->
            </div>
        </template>
    </div>

    <template v-if="s.popup == 'books'"><BookAds /></template>
    <template v-if="s.popup == 'actives'"><ActiveAds /></template>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-40 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import LineGraf from "../components/Graf/LineGraf.vue";
import BookAds from "../components/BookAds.vue";
import ActiveAds from "../components/ActiveAds.vue";

export default {
    name: "ClientsPage",

    components: { LineGraf, BookAds, ActiveAds },

    data() {
        return {
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,
            radiobtn: "spent",
            sorter: { name: "name", order: true },
        };
    },

    computed: {
        ...mapGetters(["s", "clients", "campaigns", "templates", "stat", "templates", "users"]),

        dataClients() {
            if (!this.clients) return { clients: null, total: null };

            let total = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
            let data = [];

            this.clients.forEach((client) => {
                client.cpm = client.cpc = client.cpa = 0;
                if (client.views) client.cpm = parseInt(client.spent / (client.views / 1000));
                if (client.clicks) client.cpc = parseInt(client.spent / client.clicks);
                if (client.regs) client.cpa = parseInt(client.spent / client.regs);

                total["templates"] += parseInt(client.templates);
                total["book"] += parseInt(client.book);
                total["work"] += parseInt(client.work);
                total["spent"] += parseInt(client.spent);
                total["views"] += parseInt(client.views);
                total["clicks"] += parseInt(client.clicks);
                total["regs"] += parseInt(client.regs);
            });

            data = this.clients;

            if (this.s.order_name == "id") {
                if (this.s.order) {
                    data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
                }
            }

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "templates") {
                if (this.s.order) {
                    data.sort((a, b) => (a.templates > b.templates ? 1 : b.templates > a.templates ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.templates < b.templates ? 1 : b.templates < a.templates ? -1 : 0));
                }
            }

            if (this.s.order_name == "book") {
                if (this.s.order) {
                    data.sort((a, b) => (a.book > b.book ? 1 : b.book > a.book ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.book < b.book ? 1 : b.book < a.book ? -1 : 0));
                }
            }

            if (this.s.order_name == "work") {
                if (this.s.order) {
                    data.sort((a, b) => (a.work > b.work ? 1 : b.work > a.work ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.work < b.work ? 1 : b.work < a.work ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
            if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
            if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

            return { clients: data, total: total };
        },

        // график расхода в клиенте
        graf() {
            if (!this.stat) return null;

            let result = [];

            let srt = moment(this.stat[0]?.date).format("YYYY-MM-DD");
            if (this.s.start != srt) srt = moment(this.s.start).format("YYYY-MM-DD");
            let end = moment().format("YYYY-MM-DD");
            if (this.s.end != end) end = moment(this.s.end).format("YYYY-MM-DD");

            while (srt <= end) {
                let d = { time: moment(srt).format("YYYY-MM-DD"), views: 0, clicks: 0, regs: 0, spent: 0 };
                this.stat.forEach((stat) => {
                    if (stat.date == srt) {
                        d.spent += parseInt(stat.spent);
                        d.views += parseInt(stat.views);
                        d.clicks += parseInt(stat.clicks);
                        d.regs += parseInt(stat.regs);
                    }
                });

                d.cpm = 0;
                d.cpc = 0;
                d.cpa = 0;
                if (d.views > 0) d.cpm = parseInt(d.spent / (d.views / 1000));
                if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks);
                if (d.regs > 0) d.cpa = parseInt(d.spent / d.regs);

                // для графика
                if (this.radiobtn == "spent") d.value = d.spent;
                if (this.radiobtn == "views") d.value = d.views;
                if (this.radiobtn == "clicks") d.value = d.clicks;
                if (this.radiobtn == "regs") d.value = d.regs;
                if (this.radiobtn == "cpc") d.value = d.cpc;
                if (this.radiobtn == "cpm") d.value = d.cpm;
                if (this.radiobtn == "cpa") d.value = d.cpa;

                result.push(d);
                srt = moment(srt).add(1, "day").format("YYYY-MM-DD");
            }

            return result;
        },

        // dataClientsOLD() {
        //     if (!this.clients || !this.statCamp || !this.templates) return { clients: null, total: null };

        //     let total = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
        //     let data = [];

        //     this.clients.forEach((client) => {
        //         let data = { templates: 0, book: 0, work: 0, spent: 0, views: 0, clicks: 0, regs: 0, cpm: 0, cpc: 0, cpa: 0 };
        //         this.statCamp.forEach((stat) => {
        //             if (stat.client_id == client.id && stat.date >= this.s.start && stat.date <= this.s.end) {
        //                 if (stat.spent > 0) data.spent += stat.spent;
        //                 if (stat.views > 0) data.views += stat.views;
        //                 if (stat.clicks > 0) data.clicks += stat.clicks;
        //                 if (stat.regs > 0) data.regs += stat.regs;
        //             }
        //         });

        //         this.templates.forEach((template) => {
        //             if (template.client_id == client.id) {
        //                 data.templates++;
        //                 if (template.book > 0) data.book += template.book;
        //                 if (template.work > 0) data.work += template.work;
        //             }
        //         });

        //         client["templates"] = data.templates;
        //         client["book"] = data.book;
        //         client["work"] = data.work;
        //         client["spent"] = data.spent;
        //         client["views"] = data.views;
        //         client["clicks"] = data.clicks;
        //         client["regs"] = data.regs;
        //         client["cpm"] = data.cpm;
        //         client["cpc"] = data.cpc;
        //         client["cpa"] = data.cpa;

        //         if (client.views) client["cpm"] = parseInt(client.spent / (client.views / 1000));
        //         if (client.clicks) client["cpc"] = parseInt(client.spent / client.clicks);
        //         if (client.regs) client["cpa"] = parseInt(client.spent / client.regs);

        //         total["templates"] += data.templates;
        //         total["book"] += data.book;
        //         total["work"] += data.work;
        //         total["spent"] += data.spent;
        //         total["views"] += data.views;
        //         total["clicks"] += data.clicks;
        //         total["regs"] += data.regs;

        //         // client["users"] = [];
        //         // let users = new Set();
        //         // this.campaigns?.forEach((campaign) => {
        //         //     if (campaign.client_id == client.id) users.add(campaign.user_id);
        //         // });

        //         // for (let user_id of users) {
        //         //     this.users?.forEach((u) => {
        //         //         if (user_id == u.id) client["users"].push(u.picture);
        //         //     });
        //         // }

        //         // client["templates"] = 0;
        //         // this.templates?.forEach((template) => {
        //         //     if (template.client_id == client.id) client["templates"]++;
        //         // });
        //     });

        //     data = this.clients;

        //     if (this.s.order_name == "id") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "name") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "templates") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.templates > b.templates ? 1 : b.templates > a.templates ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.templates < b.templates ? 1 : b.templates < a.templates ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "book") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.book > b.book ? 1 : b.book > a.book ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.book < b.book ? 1 : b.book < a.book ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "work") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.work > b.work ? 1 : b.work > a.work ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.work < b.work ? 1 : b.work < a.work ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "spent") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "views") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "clicks") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "regs") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "cpm") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "cpc") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
        //         }
        //     }

        //     if (this.s.order_name == "cpa") {
        //         if (this.s.order) {
        //             data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
        //         } else {
        //             data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
        //         }
        //     }

        //     if (total.views) total["cpm"] = parseInt(total.spent / (total.views / 1000));
        //     if (total.clicks) total["cpc"] = parseInt(total.spent / total.clicks);
        //     if (total.regs) total["cpa"] = parseInt(total.spent / total.regs);

        //     return { clients: data, total: total };
        // },
    },

    methods: {
        ...mapActions(["getClients", "setSettings", "setPeriod", "setOrder", "setPopup", "closePopup", "pickClient"]),
    },

    mounted() {
        this.getClients();
    },
};
</script>
