<template>
    <h2 class="w-full text-3xl text-slate-500 text-center font-bold mb-4">ЭФФЕКТИВНОСТЬ ГРУПП</h2>

    <div class="flex justify-between items-center h-10 mb-4 gap-4">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <template v-for="(value, key) in month" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>

        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div class="w-1/2 mx-auto">
        <div class="w-full bg-white rounded mb-4 p-2">
            <div class="grid grid-cols-7 items-center text-xs text-slate-400 gap-4 px-2">
                <div class="col-span-4 cursor-pointer" @click="setOrder('name')">ГРУППА &#8597;</div>

                <div class="text-right cursor-pointer" @click="setOrder('cpa')">CPA &#8597;</div>
                <div class="text-right cursor-pointer" @click="setOrder('spent')">ОТКРУТ &#8597;</div>
                <!-- <div class="text-right cursor-pointer" @click="setOrder('views')">ПОКАЗЫ &#8597;</div> -->
                <!-- <div class="text-right cursor-pointer" @click="setOrder('clicks')">КЛИКИ &#8597;</div> -->
                <div class="text-right cursor-pointer" @click="setOrder('regs')">ЛИДЫ &#8597;</div>

                <!-- <div class="text-right cursor-pointer" @click="setOrder('cpm')">CPM &#8597;</div>
                <div class="text-right cursor-pointer" @click="setOrder('cpc')">CPC &#8597;</div> -->
            </div>
        </div>

        <transition-group appear name="fade" tag="div">
            <div v-for="(item, index) in dataGroups" :key="item.id" class="transition" :style="{ 'transition-delay': `${index * 0.02}s` }">
                <div
                    @click="group_id = item.id"
                    class="grid grid-cols-7 items-center gap-4 bg-white rounded p-2 mb-2 text-sm transition hover:bg-cyan-50/50 hover:!text-cyan-600"
                >
                    <div class="flex items-center gap-2 col-span-4">
                        <a class="text-cyan-600 cursor-pointer" target="_blank" :href="'https://vk.com/adsmarket?act=office&union_id=' + item.id">
                            <img class="w-6 h-6 object-cover rounded" :src="item.photo" />
                        </a>
                        <div class="flex flex-col">
                            <div class="text-[8px] text-slate-500">{{ item.category_name }}</div>
                            <div class="text-md h-5 overflow-hidden">{{ item.name }}</div>
                        </div>
                    </div>

                    <div class="text-2xl text-cyan-500 tabular-nums text-right font-bold">
                        {{ parseInt(item.cpa).toLocaleString() }}
                        <!-- <sup>₽</sup> -->
                    </div>

                    <div class="text-md text-slate-500 text-right">
                        {{ parseInt(item.spent).toLocaleString() }} <span class="text-slate-400 text-xs">₽</span>
                    </div>
                    <!-- <div class="text-md text-slate-500 text-right">{{ parseInt(item.views).toLocaleString() }}</div> -->

                    <!-- <div class="flex justify-end items-center gap-2">
                        <div class="text-md text-slate-500 text-right">{{ parseInt(item.clicks).toLocaleString() }}</div>
                        <span class="text-slate-400"><IconClick :size="3" /></span>
                    </div> -->

                    <div class="flex justify-end items-center gap-2">
                        <div class="text-md text-slate-500 text-right">{{ parseInt(item.regs).toLocaleString() }}</div>
                        <span class="text-slate-400"><IconUser :size="3" /></span>
                    </div>
                </div>
                <!-- <div
                    v-if="group_id && group_id == item.id"
                    class="grid grid-cols-6 items-center gap-4 bg-cyan-50/50 p-2 -mt-2 mb-2 text-sm transition hover:bg-cyan-50/50 hover:!text-cyan-600"
                >
                    <div class="col-span-6">{{ graf }}</div>

                    {{ group_id }}
                    <div class="text-md text-slate-500 tabular-nums text-right">{{ parseInt(item.cpm).toLocaleString() }}</div>
                    <div class="text-md text-slate-500 tabular-nums text-right">{{ parseInt(item.cpc).toLocaleString() }}</div>
                </div> -->
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "GroupsPage",

    data() {
        return {
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
            sorter: { name: "name", order: true },
            group_id: null,
        };
    },

    computed: {
        ...mapGetters(["s", "groups"]),

        dataGroups() {
            if (!this.groups || this.groups.length < 1) return null;

            let data = [];

            this.groups.forEach((group) => {
                let item = {
                    id: group.group_id,
                    name: group.name,
                    photo: group.photo_100,
                    category_name: group.category_name,
                    members: group.members,
                    reach: group.reach,
                    cost: group.cost,
                    likes: 0,
                    reposts: 0,
                    comments: 0,
                    spent: 0,
                    views: 0,
                    clicks: 0,
                    regs: 0,
                    cpm: 0,
                    cpc: 0,
                    cpa: 0,
                };
                group.statAd.forEach((stat) => {
                    if (stat.date >= this.s.start && stat.date <= this.s.end) {
                        if (stat.likes > 0) item.likes += stat.likes;
                        if (stat.reposts > 0) item.reposts += stat.reposts;
                        if (stat.comments > 0) item.comments += stat.comments;
                        if (stat.spent > 0) item.spent += stat.spent;
                        if (stat.views > 0) item.views += stat.views;
                        if (stat.clicks > 0) item.clicks += stat.clicks;
                        if (stat.regs > 0) item.regs += stat.regs;
                    }
                });

                if (item.views) item.cpm = parseInt(Math.round(item.spent / (item.views / 1000)));
                if (item.clicks) item.cpc = parseInt(Math.round(item.spent / item.clicks));
                if (item.regs) item.cpa = parseInt(Math.round(item.spent / item.regs));

                if (item.id > 0 && item.spent > 0 && item.cpa > 0) data.push(item);
            });

            if (this.s.order_name == "name") {
                if (this.s.order) {
                    data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));
                }
            }

            if (this.s.order_name == "likes") {
                if (this.s.order) {
                    data.sort((a, b) => (a.likes > b.likes ? 1 : b.likes > a.likes ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.likes < b.likes ? 1 : b.likes < a.likes ? -1 : 0));
                }
            }

            if (this.s.order_name == "reposts") {
                if (this.s.order) {
                    data.sort((a, b) => (a.reposts > b.reposts ? 1 : b.reposts > a.reposts ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.reposts < b.reposts ? 1 : b.reposts < a.reposts ? -1 : 0));
                }
            }

            if (this.s.order_name == "comments") {
                if (this.s.order) {
                    data.sort((a, b) => (a.comments > b.comments ? 1 : b.comments > a.comments ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.comments < b.comments ? 1 : b.comments < a.comments ? -1 : 0));
                }
            }

            if (this.s.order_name == "spent") {
                if (this.s.order) {
                    data.sort((a, b) => (a.spent > b.spent ? 1 : b.spent > a.spent ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.spent < b.spent ? 1 : b.spent < a.spent ? -1 : 0));
                }
            }

            if (this.s.order_name == "views") {
                if (this.s.order) {
                    data.sort((a, b) => (a.views > b.views ? 1 : b.views > a.views ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.views < b.views ? 1 : b.views < a.views ? -1 : 0));
                }
            }

            if (this.s.order_name == "clicks") {
                if (this.s.order) {
                    data.sort((a, b) => (a.clicks > b.clicks ? 1 : b.clicks > a.clicks ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.clicks < b.clicks ? 1 : b.clicks < a.clicks ? -1 : 0));
                }
            }

            if (this.s.order_name == "regs") {
                if (this.s.order) {
                    data.sort((a, b) => (a.regs > b.regs ? 1 : b.regs > a.regs ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.regs < b.regs ? 1 : b.regs < a.regs ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpm") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpm > b.cpm ? 1 : b.cpm > a.cpm ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpm < b.cpm ? 1 : b.cpm < a.cpm ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpc") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpc > b.cpc ? 1 : b.cpc > a.cpc ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpc < b.cpc ? 1 : b.cpc < a.cpc ? -1 : 0));
                }
            }

            if (this.s.order_name == "cpa") {
                if (this.s.order) {
                    data.sort((a, b) => (a.cpa > b.cpa ? 1 : b.cpa > a.cpa ? -1 : 0));
                } else {
                    data.sort((a, b) => (a.cpa < b.cpa ? 1 : b.cpa < a.cpa ? -1 : 0));
                }
            }

            return data;
        },

        graf() {
            if (!this.groups || this.groups.length < 1 || !this.group_id) return null;

            let graf = [];

            let gs = this.groups.filter((g) => g.group_id == this.group_id);

            gs[0].statAd.forEach((stat) => {
                if (stat.spent > 0) graf.spent += stat.spent;
                if (stat.regs > 0) graf.regs += stat.regs;
            });

            return gs[0].statAd;
        },
    },

    methods: {
        ...mapActions(["getGroups", "setOrder", "setSettings", "setPeriod", "setMonth"]),
    },

    mounted() {
        this.getGroups();
    },
};
</script>

<style>
.fade-enter-active {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.fade-enter-from {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}
</style>
