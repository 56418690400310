<template>
    <div class="w-full text-center mt-4">СРЕЗЫ</div>

    <div class="flex justify-between items-center h-20 gap-4">
        <div class="btn btn-grey w-48 cursor-pointer" @click="view = 'none'">ИТОГ</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'targetolog'">Таргетолог</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'client'">Клиент</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'channel'">Канал</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'template'">Креатив</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'group'">Группа</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'land'">Лендинг/бот</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'voronka'">Воронка/дожим</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'pay'">Оплата</div>
        <div class="btn btn-yellow w-48 cursor-pointer" @click="view = 'result'">Связки</div>
    </div>

    <hr class="my-4" />

    <div class="w-full text-center mt-4">ФИЛЬТР</div>

    <div class="grid grid-cols-10 justify-between items-center h-20 gap-4">
        <select v-model="currentUser" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <template v-for="u in users" :key="u">
                <option v-if="u.id == currentUser.id" selected :value="u">{{ u.name }}</option>
                <option v-else :value="u">{{ u.name }}</option>
            </template>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Клиент</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Креатив</option>
        </select>

        <select v-model="currentChannel" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <template v-for="ch in channels" :key="ch">
                <option v-if="ch.id == currentChannel.id" selected :value="ch">{{ ch.name }}</option>
                <option v-else :value="ch">{{ ch.name }}</option>
            </template>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Группа</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Лендинг</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Воронка</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Продукт</option>
        </select>

        <input type="date" class="input h-9 text-sm bg-white" v-model="s.start" />
        <input type="date" class="input h-9 text-sm bg-white" v-model="s.end" />
    </div>

    <hr class="my-4" />

    <template v-if="view == 'none'">
        <div class="w-full text-center mt-4">ИТОГОВЫЙ РЕЗУЛЬТАТ</div>

        <div class="grid grid-cols-4 gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">ЛИДЫ</span>
                <span class="num text-4xl">{{ parseInt(stat.regs).toLocaleString() }}</span>
                <div class="flex items-center gap-2">
                    <div class="text-pink-600"><IconDown size="6" /></div>
                    <div class="flex flex-col items-center">
                        <span class="title text-pink-600">МЕНЬШЕ НА</span>
                        <span class="text-pink-600 text-sm">{{ parseInt(1320).toLocaleString() }}</span>
                    </div>
                </div>
            </div>

            <div class="col-span-2 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">БЮДЖЕТ ОТКРУЧЕН</span>

                <span class="num text-6xl">{{ parseInt(stat.cost).toLocaleString() }} ₽</span>

                <div class="flex items-center gap-2">
                    <div class="text-teal-600"><IconUp size="6" /></div>
                    <div class="flex flex-col items-center">
                        <span class="title text-teal-600">БОЛЬШЕ НА</span>
                        <span class="text-teal-600 text-sm">{{ parseInt(2160000).toLocaleString() }} ₽</span>
                    </div>
                </div>

                <!-- <div class="w-full"><LineGraf /></div> -->
            </div>

            <!-- border border-pink-600 -->

            <div class="flex justify-center items-center gap-4 bg-white rounded p-8">
                <div class="text-pink-600"><IconWarning size="8" /></div>

                <div class="flex justify-center items-center flex-col">
                    <span class="title">СТОИМОСТЬ ЛИДА</span>
                    <span class="num text-pink-600 text-4xl">{{ parseInt(stat.cost / stat.regs).toLocaleString() }} ₽</span>
                    <span class="title text-pink-600">ВЫШЕ KPI НА 40 ₽</span>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-4 gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">КРЕАТИВЫ</span>
                <span class="num text-3xl">{{ 23 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">РАЗМЕЩЕНИЯ</span>
                <span class="num text-3xl">{{ 118 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ КЛИЕНТЫ</span>
                <span class="num text-3xl">{{ 6 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ ТАРГЕТОЛОГИ</span>
                <span class="num text-3xl">{{ 4 }}</span>

                <div class="flex justify-between w-full">
                    <template v-for="item in users" :key="item">
                        <div class="flex flex-col items-center cursor-pointer text-xs text-slate-400">
                            <img
                                v-if="item.picture"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                                alt=""
                                class="rounded-full w-8 h-8"
                            />
                            <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="w-full bg-white flex justify-center items-center rounded p-8">
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">ОХВАТ</span>
                    <span class="num text-xl">{{ parseInt(593610).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">ЛАЙКИ</span>
                    <span class="num text-xl">{{ parseInt(571).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">РЕПОСТЫ</span>
                    <span class="num text-xl">{{ parseInt(130).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">КОММЕНТЫ</span>
                    <span class="num text-xl">{{ parseInt(51).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">КЛИКИ</span>
                    <span class="num text-xl">{{ parseInt(592).toLocaleString() }}</span>
                </div>
            </div>
        </div>
    </template>

    <template v-if="view == 'targetolog'">
        <div class="w-full text-center mt-4">СРЕЗ: ТАРГЕТОЛОГИ</div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-3/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u6.jpg" class="w-10 h-10 rounded object-cover" />Александр
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">80 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">2</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">380</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u7.jpg" class="w-10 h-10 rounded object-cover" /> Юрий
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">63 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">4</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">98</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">8</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">320</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u9.jpg" class="w-10 h-10 rounded object-cover" /> Настя
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">700</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200 bg-slate-100">
                    <div class="col-span-2">ИТОГ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">153 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">7</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">208</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">19</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">354</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>
            </div>

            <div class="w-1/4 bg-white flex flex-col justify-center items-center rounded p-8">
                <span class="title">ГРАФИК</span>
                <PieChart />
            </div>
        </div>
    </template>

    <template v-if="view == 'channel'">
        <div class="w-full text-center mt-4">СРЕЗ: КАНАЛЫ</div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-3/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">ЯНДЕКС</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">80 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">2</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">380</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">VK API</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">63 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">4</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">98</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">8</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">320</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">МАРКЕТ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">700</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200 bg-slate-100">
                    <div class="col-span-2">ИТОГ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">243 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">7</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">208</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">19</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">354</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>
            </div>

            <div class="w-1/4 bg-white flex flex-col justify-center items-center rounded p-8">
                <span class="title">ГРАФИК</span>
                <PieChannel />
            </div>
        </div>
    </template>

    <template v-if="view == 'result'">
        <div class="w-full text-center mt-4">СВЯЗКИ</div>

        <div class="grid grid-cols-4 gap-4 mt-4">
            <div class="flex flex-col justify-center items-center bg-white rounded p-8">
                <span class="title mb-8">ТАРГЕТОЛОГ: СТОИМОСТЬ ЛИДА</span>
                <div class="flex justify-center items-center gap-20">
                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-teal-600 mb-4"> <IconGood size="4" />ЛУЧШИЙ</span>
                        <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 mb-4 rounded object-cover" />
                        <span class="num text-teal-600 text-3xl">{{ 285 }} ₽</span>
                    </div>

                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad size="4" />ХУДШИЙ</span>
                        <img src="https://api.timekraken.ru/uploads/users/u9.jpg" class="w-20 h-20 mb-4 rounded object-cover" />
                        <span class="num text-pink-600 text-3xl">{{ 690 }} ₽</span>
                    </div>
                </div>
            </div>

            <div class="col-span-2 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title mb-8">ТАРГЕТОЛОГ -> КЛИЕНТ -> КАНАЛ</span>

                <div class="grid grid-cols-5 justify-center items-center text-center gap-8 mb-4">
                    <span class="flex items-center gap-2 text-sm text-teal-600"> <IconGood size="4" />ЛУЧШИЙ</span>
                    <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 rounded object-cover" />
                    <span class="text-center text-teal-600 text-sm font-bold">3ДКлуб Моделинг - 1</span>
                    <span class="text-center text-teal-600 text-sm font-bold">МАКРЕТ ПЛАТФОРМА</span>
                    <span class="num text-teal-600 text-3xl">{{ 250 }} ₽</span>
                </div>

                <div class="grid grid-cols-5 justify-center items-center text-center gap-8">
                    <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad size="4" />ХУДШИЙ</span>
                    <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 rounded object-cover" />
                    <span class="text-center text-pink-600 text-sm font-bold">ХРИСТОСЕНКО СОЦСЕТИ - 1</span>
                    <span class="text-center text-pink-600 text-sm font-bold">VK API</span>
                    <span class="num text-pink-600 text-3xl">{{ 850 }} ₽</span>
                </div>
            </div>

            <div class="flex flex-col justify-center items-center bg-white rounded p-8">
                <span class="title mb-8">КЛИЕНТ: СТОИМОСТЬ ЛИДА</span>
                <div class="flex justify-center items-center gap-20">
                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-teal-600 mb-4"> <IconGood size="4" />ЛУЧШИЙ</span>

                        <span class="flex text-center text-teal-600 text-sm font-bold h-20 items-center">3ДКлуб Моделинг - 1</span>
                        <span class="num text-teal-600 text-3xl">{{ 130 }} ₽</span>
                    </div>

                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad size="4" />ХУДШИЙ</span>
                        <span class="flex text-center text-pink-600 text-sm font-bold h-20 items-center">ХРИСТОСЕНКО СОЦСЕТИ - 1</span>
                        <span class="num text-pink-600 text-3xl">{{ 1280 }} ₽</span>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "PrototypePage",

    data() {
        return {
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,

            stat: { cost: 22560000, regs: 66210 },
            users: [
                { id: 0, name: "Таргетолог" },
                { id: 6, name: "Александр" },
                { id: 7, name: "Юрий" },
                { id: 9, name: "Настя" },
            ],
            channels: [
                { id: 0, name: "Канал" },
                { id: 1, name: "Vk" },
                { id: 2, name: "Яндекс" },
                { id: 3, name: "YouTube" },
            ],

            view: "none",
            currentUser: { id: 0, name: "Таргетолог" },
            currentChannel: { id: 0, name: "Канал" },
        };
    },

    computed: {
        ...mapGetters(["s", "client", "campaign", "templates", "template"]),

        isArchive() {
            let arch = 0;
            if (this.templates && this.templates.filter((item) => item.status == 8).length > 0) arch = 1;
            return arch;
        },
    },

    methods: {
        ...mapActions([
            "getClients",
            "isCampaign",
            "pickClient",
            "setPopup",
            "closePopup",
            "createTemplate",
            "pickTemplate",
            "cloneTemplate",
            "archiveTemplate",
            "unarchiveTemplate",
        ]),
    },

    mounted() {},
};
</script>
