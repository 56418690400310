<template>
    <template v-if="moment(date).format('DD.MM.YYYY') == moment().format('DD.MM.YYYY')">Сегодня</template>
    <template v-else-if="moment(date).format('DD.MM.YYYY') == moment().subtract(1, 'day').format('DD.MM.YYYY')">Вчера</template>
    <template v-else-if="moment(date).format('DD.MM.YYYY') == moment().add(1, 'day').format('DD.MM.YYYY')">Завтра</template>
    <template v-else-if="moment(date).format('DD.MM.YYYY') == moment().add(2, 'day').format('DD.MM.YYYY')">Послезавтра</template>
    <template v-else>{{ moment(date).format("DD.MM.YYYY") }}</template>
</template>
<script>
import moment from "moment";
export default {
    name: "DayAd",

    data() {
        return {
            moment: moment,
        };
    },

    props: {
        date: {
            type: String,
        },
        // <span class="letter mr-1">С</span>
    },
};
</script>
